// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';

export interface ICallToActionLinkProps {
    primaryText: string;
    primaryIcon?: string;
    linkUrl?: string;
    linkText?: string;
    linkIcon?: string;
    ctaClass?: string;
    image?: string;
    imageString?: string;
}

interface State {}

export class CallToActionLink extends React.Component<ICallToActionLinkProps, State>{
    constructor(props) {
        super(props);
        this.state = {};
    }

    private renderCTALink() {
        const linkText = <div className="ctaLinkLinkText">{this.props.linkText}</div>
        const linkIcon = <i className={"ctaLinkIcon " + this.props.linkIcon}></i>
        const primaryIcon = <i className={"ctaPrimaryIcon " + this.props.primaryIcon}></i>

        if (!this.props.linkText && this.props.linkUrl) {
            return (
                <div className={"ctaLinkContainer cta-" + (this.props.ctaClass ? this.props.ctaClass : "blue")}>
                    {this.props.imageString ? 
                        <img className="ctaLinkImage" src={this.props.imageString} />
                        :
                    ""}
                    <a href={this.props.linkUrl["uri"] ? this.props.linkUrl["uri"] : this.props.linkUrl } className={"ctaLink" + (this.props.imageString ? " withImage" : "") }>
                                {this.props.primaryIcon ? primaryIcon : ''}

                            <div className={"ctaLinkPrimaryText" + (this.props.imageString ? " withImage" : "")}>{this.props.primaryText}</div>

                            <div className="ctaLinkLink">
                                {this.props.linkText ? linkText : ''}
                                {this.props.linkIcon ? linkIcon : ''}
                            </div>
                        </a>
                   
                   </div>
            )
        }

        else {
            return (
                <div className={"ctaLinkContainer cta-" + (this.props.ctaClass ? this.props.ctaClass : "blue")}>
                    {this.props.imageString ?
                        <img className="ctaLinkImage" src={this.props.imageString} />
                        : ""
                    }
                    <div className={"ctaLink" + (this.props.imageString ? " withImage" : "")}>

                        {this.props.primaryIcon ? primaryIcon : ''}
                        <div className={"ctaLinkPrimaryText" + (this.props.imageString ? " withImage" : "")}>{this.props.primaryText}</div>

                        {this.props.linkUrl ?
                            //create an <a> tag that will surround the Link text and icon, making it clickable
                            <a href={this.props.linkUrl["uri"] ? this.props.linkUrl["uri"] : this.props.linkUrl} className="ctaLinkLink">
                                {this.props.linkText ? linkText : ''}
                                {this.props.linkIcon ? linkIcon : ''}
                            </a>

                            //otherwise if there is not a link url, create a normal, non-clickable div around the link text and icon
                            :<div className="ctaLinkLink">
                                {this.props.linkText ? linkText : ''}
                                {this.props.linkIcon ? linkIcon : ''}
                            </div>
                        }
                    </div>  
                </div>
            )
        }     
    }

    public render() {
        return this.renderCTALink()
  
    } //end render function bracket
}

