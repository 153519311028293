// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import Moment from "moment";
import * as dateFormatter from '../../dateFormatter';

export interface IMailingList { listName: string, listTitle: string, listDescription: string, listCategory: string, subscriptionDate: Date, subscribed: boolean, subscribe: boolean, active: boolean; }

interface IMailingListItemProps { List: IMailingList, ListType: string, CategoryDescription: string, Index: number, ID: string, SubscribeList: (list: IMailingList) => void }

interface State { }

export default class MailingListItem extends React.Component<IMailingListItemProps, State>{

	constructor(props) {
		super(props);
		this.state = {};
	}

	public SubscribeList(event: React.FormEvent<HTMLInputElement>) {
		this.props.SubscribeList(this.props.List);
	}

	public FormatDate(date: Date) {
		var tempDate = Moment.utc(date, Moment.ISO_8601, true);
		return dateFormatter.DateFormatter.Format(tempDate, "MM/DD/YYYY");
	}

	public render() {
        return (
            <div className="col-12 col-md-6 d-inline-grid">
                <div className="panel panel-default mailingList-panel margin-top-5 margin-btm-10 d-inline-grid" style={{ 'borderRadius': '4px', 'border': '1px solid #c2c2c3' }}>
                    <div className="panel-heading h-100" role="tab" id={this.props.ID + "Header" + this.props.Index}>
                        <div className="leftside align-self-center">
                            <div className="d-flex">
                                {this.props.ListType && this.props.ListType == "Open" && (
                                    <input type="checkbox" id={this.props.List.listName} className="margin-top-5 form-check-input miso-checkbox margin-right-10 margin-top-0 mailingListItem" checked={this.props.List.subscribe} onChange={e => this.SubscribeList(e)} />
                                )}
                                <div>
                                    {this.props.CategoryDescription == null || (this.props.List.listDescription != null && this.props.CategoryDescription.trim() != this.props.List.listDescription.trim()) ?
                                        <a data-bs-toggle="collapse" href={"#" + this.props.ID + "Collapse" + this.props.Index} data-parent={"#" + this.props.ID} role="button" aria-expanded="false" aria-controls={this.props.ID + "Collapse" + this.props.Index} className="accordion-link collapsed form-check-label mailingList-title" style={{ 'fontWeight': 'bold' }}>
                                            <h4 className="margin-btm-0 black bold">{this.props.List.listTitle}</h4>
                                        </a>
                                        : <h4 className="margin-btm-0 black">{this.props.List.listTitle}</h4>
                                    }
                                    {this.props.List.subscribed && (
                                        <div className="subscription-date">
                                            <i>{"Subscription Date: " + this.FormatDate(this.props.List.subscriptionDate)}</i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="rightside">
                            {this.props.CategoryDescription == null || (this.props.List.listDescription != null && this.props.CategoryDescription.trim() != this.props.List.listDescription.trim()) ?
                                <div className="flex-grow-0 d-flex padding-left-0 padding-right-0">
                                    <a data-bs-toggle="collapse" href={"#" + this.props.ID + "Collapse" + this.props.Index} data-parent={"#" + this.props.ID} role="button" aria-expanded="false" aria-controls={this.props.ID + "Collapse" + this.props.Index} className="accordion-link collapsed">
                                        <i className="fa pull-right"></i>
                                    </a>
                                </div> : ""}
                        </div>
                    </div>
                    {this.props.CategoryDescription == null || (this.props.List.listDescription != null && this.props.CategoryDescription.trim() != this.props.List.listDescription.trim()) ?
                        <div id={this.props.ID + "Collapse" + this.props.Index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={this.props.ID + "Heading" + this.props.Index} aria-expanded="false">
                            <div className={"panel-body padding-btm-15" + (this.props.ListType == "Closed" ? " closed" : "") + (this.props.ListType && this.props.ListType == "Open" ? " margin-left-25" : "")}>
                                <span dangerouslySetInnerHTML={{ __html: this.props.List.listDescription }}></span>
                            </div>
                        </div>
                        : ""}
                </div>
            </div>
		)
	}
}