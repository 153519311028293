// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import * as DocumentsBase from '../Find/DocumentsBase';
import * as Follow from '../../follow';
import * as dateFormatter from '../../dateFormatter';
interface State { results: Array<string>, query: DocumentsBase.DocumentItem }

interface ICDNDocProps {
	cdnid: string, 
    disableFollowing: false, 
    overrideDescription: false, 
    description: string,
    findServiceUrl: string
}

export class CDNDocument extends React.Component<ICDNDocProps, State>{

    constructor(props){
        super(props);
        this.state = { results: [], query: null }
    }

    constructResultItem() : DocumentsBase.DocumentItem {
        return new DocumentsBase.DocumentItem();
    }

    componentDidMount(){
        // Get Document Data from EpiFind   
        this.GetDocumentMetadata().then((data: DocumentsBase.DocumentItem) => {
            this.setState({query: data});
        });
    }

    private GetDocumentMetadata() {    
        var deferred = $.Deferred();
        var xmlhttp = new XMLHttpRequest();
        
        xmlhttp.open("GET", this.props.findServiceUrl + "/" + this.props.cdnid, true);
        xmlhttp.onload = function(e) {
            var data = JSON.parse(xmlhttp.responseText);
            var parsedResult = this.parseResults(data);

            this.getFollowedStatus(parsedResult).then((subscriptionData) => {                
                if(subscriptionData.subscriptions.length > 0){
                    for(var i=0; i<subscriptionData.subscriptions.length; i++){
                        if(subscriptionData.subscriptions[i].contentId == parsedResult.DocumentGuid){
                            parsedResult.isFollowing = true;
                            break;
                        }
                    }
                }
                deferred.resolve(parsedResult);
            })
            
        }.bind(this);
        
        xmlhttp.send();

        return deferred.promise();
    }

    private parseResults(data: any) {
        var parsedSingleResult = new DocumentsBase.DocumentItem();
        parsedSingleResult.source = data.source || data._source || data.fields.source || data.fields;

        Object.getOwnPropertyNames(parsedSingleResult).forEach(prop => {
            if (prop != "source") {
                if (parsedSingleResult.source && parsedSingleResult.source[prop]) {
                    parsedSingleResult[prop] = parsedSingleResult.source[prop];
                }
                else if (parsedSingleResult.source && parsedSingleResult.source.properties && parsedSingleResult.source.properties[prop]) {
                    parsedSingleResult[prop] = parsedSingleResult.source.properties[prop];
                }
                else if (data && data.fields && data.fields[prop]) {
                    parsedSingleResult[prop] = data.fields[prop];
                }
            } 
            else if (parsedSingleResult[prop]["Properties"] && Object.keys(parsedSingleResult[prop]["Properties"]).length > 0) {
                var sourceProperties = parsedSingleResult[prop]["Properties"];
                Object.keys(sourceProperties).forEach(propertyKey => {
                    parsedSingleResult[propertyKey] = sourceProperties[propertyKey]
                });
            }
        });

        return parsedSingleResult;  
    }

    private getFollowedStatus(documentItem: DocumentsBase.DocumentItem){
        return $.ajax({
            url: "/api/documents/GetSubscribedDocuments",
            type: "POST",
            data: JSON.stringify([documentItem.DocumentGuid]),
            dataType: 'json',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            success: (data) => {
                return data;
            },
            error: (error) => {
                // An error happened
            }
        });
    }

    private renderFollow(){
        if(!this.props.disableFollowing){
            return (
                <div className="follow">
                    <h4 dangerouslySetInnerHTML={{ __html: Follow.Follow.Display(this.state.query.DocumentGuid, this.state.query.isFollowing, true) }} />
                </div>
            );
        }
    }

    private renderDescription(){
        if(this.props.overrideDescription){
            return (
                <div className="cta-Text" dangerouslySetInnerHTML={{ __html: this.props.description }}></div>
            );
        } else {
            return (
                <div className="cta-Text">
                    {this.state.query.Description}
                </div>
            );
        }
    }

    render() {
        if(this.state.query == null) return (<div />)   
        return(
            <div className="qbank-document">
                <div id={"document-" + this.state.query.DocumentGuid} className="cta-body">
                    <div className={!this.props.disableFollowing ? "qbank-title followable" : "qbank-title"}>
                        <h4 className="margin-btm-10">{this.state.query.Name}</h4>
                        {this.state.query.effectivedate ? <div className='gray bold margin-top-5'>Posted On - {dateFormatter.DateFormatter.Format(this.state.query.effectivedate, "MM-DD-YYYY")} </div>: <span></span>}
                    </div>
                    {this.renderDescription()}                    
                </div>
                {this.renderFollow()}                
                <div className="cta-button">
                    <a className="btn btn-primary margin-top-30" target="_blank" title={"Download " + this.state.query.Name} href={this.state.query.Files.Values[0]}>
                        Download {this.state.query.Name}<i className="fa fa-download margin-left-20"></i>
                    </a>
                </div>
            </div>                            
        )
    }
}