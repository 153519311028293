import React from 'react';
import { CallToActionLink } from "../CallToActionLink/CallToActionLink";

interface IUserRoleListProps {
    extranetRoles: Array<IFriendlyRoleMapItem>,
    gridUnityRoles: Array<IFriendlyRoleMapItem>,
    adminOnlyRoles: Array<IFriendlyRoleMapItem>
}

interface IFriendlyRoleMapItem {
    roleName: string,
    friendlyName: string,
    extranetRole: boolean,
    gridUnityRole: boolean,
    adminOnlyRole: boolean,
    referenceUrl: any
}

interface State {
    hasExtranetRoles: boolean,
    hasGridUnityRoles: boolean,
    hasAdminOnlyRoles: boolean
}

export class UserRoleList extends React.Component<IUserRoleListProps, State> {
    constructor(props) {
        super(props);
        this.state = {
            hasExtranetRoles: Array.isArray(props.extranetRoles) && props.extranetRoles.length > 0,
            hasGridUnityRoles: Array.isArray(props.gridUnityRoles) && props.gridUnityRoles.length > 0,
            hasAdminOnlyRoles: Array.isArray(props.adminOnlyRoles) && props.adminOnlyRoles.length > 0
        };
    }

    public render() {
        return (
            <div>
                <div className="padding-btm-20">
                    <h3 className="col-12 blue">Restricted Access</h3>
                </div>

                <div className="row">
                    <h4 className="margin-btm-15">Extranet Access</h4>
                    {this.state.hasExtranetRoles ?
                        this.props.extranetRoles.map((sub, i) => {
                            return (<div className="col-md-6 col-12 margin-btm-15" key={"extranet_" + i}><CallToActionLink ctaClass={"clearGB"} linkIcon={sub.referenceUrl ? "fa fa-external-link" : ""} linkUrl={sub.referenceUrl} primaryText={sub.friendlyName} /></div>);
                        })
                        : <div className="col-12 margin-btm-15">
                            <CallToActionLink ctaClass="grey" primaryIcon="fa fa-info-circle" primaryText="You do not currently have any Extranet Access" />
                        </div>
                    }
                </div>

                <div className="row">
                    <h4 className="margin-btm-15">Generator Interconnection Portal Access</h4>
                    {this.state.hasGridUnityRoles ?
                        this.props.gridUnityRoles.map((sub, i) => {
                            return (<div className="col-md-6 col-12 margin-btm-15" key={"gridUnity_" + i}><CallToActionLink ctaClass={"clearGB"} linkIcon={sub.referenceUrl ? "fa fa-external-link" : ""} linkUrl={sub.referenceUrl} primaryText={sub.friendlyName} /></div>);
                        })
                        : <div className="col-12 margin-btm-15">
                            <CallToActionLink ctaClass="grey col-12" primaryIcon="fa fa-info-circle" primaryText="You do not currently have any Generator Interconnection Portal Access" />
                        </div>
                    }
                </div>

                {this.state.hasAdminOnlyRoles ?
                    <div className="row">
                        <h4 className="margin-btm-15">Additional Access</h4>
                        {
                            this.props.adminOnlyRoles.map((sub, i) => {
                                return (<div className="col-md-6 col-12 margin-btm-15" key={"admin_" + i}><CallToActionLink ctaClass={"clearGB"} linkIcon={sub.referenceUrl ? "fa fa-external-link" : ""} linkUrl={sub.referenceUrl} primaryText={sub.friendlyName} /></div>);
                            })
                        }
                    </div>
                    : <div></div>
                }
            </div>
        );
    }
}