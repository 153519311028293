// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';

export interface ISimpleCallToActionBlockProps {
    imageString?: string;
    primaryText: string;
    linkText?: string;
    linkUrl?: string;
    simpleCTAClass?: string;
}

interface State { }

export class SimpleCallToActionBlock extends React.Component<ISimpleCallToActionBlockProps, State>{
    constructor(props) {
        super(props);
        this.state = {};
    }

    public render() {
        return (
            <div className={"simpleCtaBlock scta-" + (this.props.simpleCTAClass ? this.props.simpleCTAClass : "blue")}>
                <div data-epi-edit="SimpleCTAClass"></div>
                <div className={"sctaBody" + (this.props.imageString ? " twoThirds" : "")}>
                    <div className="linkTextDiv">
                        <h3 data-epi-edit="PrimaryText" className="sctaPrimaryText">{this.props.primaryText}</h3>
                            {this.props.linkText && this.props.linkUrl ?
                            <a href={this.props.linkUrl["uri"]} className="sctaLink">
                                <span data-epi-edit="LinkText">{this.props.linkText} </span><i data-epi-edit="LinkUrl" className="fa fa-chevron-right"></i>
                                </a>
                                : ""
                            }
                    </div>                
                </div>

                {this.props.imageString ?
                    <div data-epi-edit="Image" className="imgContainer">
                        <img className={"sctaImage"} src={this.props.imageString} />
                    </div> :
                ""}
            </div>
        )
    }
}