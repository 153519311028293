import React from 'react';

export interface IOutlookBox {
    box: Object,
}

interface State {
    snapshots: Array<any>
}

export class OutlookBox extends React.Component<IOutlookBox, State> {
    constructor(props) {
        super(props);
        this.state = { snapshots:[]};
    }

    //function to pick color based on class title
    private setColor(id) {
        switch (id) {
            case "0":
                return "forecast";

            case "1":
                return "current";

            case "2":
                return "lmp";

            case "3":
                return "nsi";

            default:
                return "not-defined";
        }
    }

    private getPicture(id) {
        switch (id) {
            case "0":
                return "f";

            case "1":
                return "c";

            case "2":
                return "m";

            case "3":
                return "n";

            default:
                return null;
        }
    }

    render() {
        const title = this.props.box["t"];
        const titleStr = String(" " + title);
        const val = this.props.box["v"];
        const id = this.props.box["id"];

        return (
            <div className="outlook-box col-6 col-md-3">
                <div className={this.setColor(id || "null") + " box"}>
                    <div className="box-title">
                        <div className={this.getPicture(id)}>
                        <div className="outlook-title">
                            <strong>{titleStr || "--"}</strong>
                        </div>
                    </div>
                    </div>
                    <div className="box-details">
                        <div className="outlook-details">{val || "--"}</div>
                    </div>
                </div>
            </div>
        );
    }
}
