// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import RegistrationButtons, {IRegistrationButtonsItem} from "./RegistrationButtons";
import RegistrationIcon, {IRegistrationIconItem} from "./RegistrationIcon";

interface IRegistrationStatusProps { target:string,eventStatus: string, disableRegistrations: boolean, form: any, view: string, linkUrl: string, loggedIn: boolean, hash?:string }

interface State {
    registrations: Array<registration>,
    showModal: boolean
}
interface registration {
    formIdentifier: any,
    registeredDateTime: string,
    submissionId: string,
    user:string
}
export class RegistrationStatus extends React.Component<IRegistrationStatusProps, any>{
    constructor(props) {
        super(props);
        this.state = { registrations: [], showModal: false};

    }
    public componentDidMount() {
        this.getEvents();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this.getEvents();        
        }
    }

    private getEvents() {
        if (this.props.form) {
            $.ajax({
                url: "/api/events/GetUserAllRegistrations",
                type: 'POST',
                data: JSON.stringify(this.props.form),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                success: function (data) {
                    this.setState({ registrations: data });
                }.bind(this)
            });
        } else {
            this.setState({ registrations: [] });
        }
    }

    public changeRegistration() {
        this.getEvents();
        if (this.state.registrations.length == 0) {
            this.hideModal();
        }
    }

    public displayModal() {
        this.setState({ showModal: true });
    }

    public hideModal() {
        this.setState({ showModal: false });
    }

    public render() {
        
        return (
            (this.props.view == "Buttons"
                ? <RegistrationButtons Form={this.props.form} target={ this.props.target} Registrations = {this.state.registrations } Status={this.props.eventStatus} LinkUrl={this.props.linkUrl} showModal={this.state.showModal} ChangeRegistration = {this.changeRegistration.bind(this) } HideModal={this.hideModal.bind(this) } DisplayModal = {this.displayModal.bind(this) } LoggedIn={this.props.loggedIn} Hash={this.props.hash}/>
            : <RegistrationIcon Form={this.props.form} target={this.props.target} Registrations={this.state.registrations} Status={this.props.eventStatus} LinkUrl={this.props.linkUrl} showModal={this.state.showModal} ChangeRegistration={this.changeRegistration.bind(this)} HideModal={this.hideModal.bind(this)} DisplayModal={this.displayModal.bind(this)} DisableRegistrations={false} />)
        )
    }
}
interface IRegistrationStatusCalculatedProps extends IRegistrationStatusProps {
    isCancelled: boolean;
    allowRegistration: boolean;
    registrationOpenDate: Date;
    registrationCloseDate: Date;

}

export const RegistrationStatusCalculated: React.SFC<IRegistrationStatusCalculatedProps> = (props) => {
    function calcEventStatus() {
        var rightNow = new Date();
        if (props.isCancelled) {
            return "cancelled";
        }
        else if (!props.allowRegistration || !props.form) {
            return "special";
        }
        else if (rightNow < props.registrationOpenDate) {
            return "preOpen";
        }
        else if (rightNow > props.registrationOpenDate && rightNow < props.registrationCloseDate) {
            return "open";
        }
        else if (rightNow > props.registrationOpenDate && rightNow > props.registrationCloseDate) {
            return "postOpen";
        }
        else {
            return "";
        }
    }
    if (props.eventStatus) {
        return (<RegistrationStatus target={props.target} eventStatus={props.eventStatus} form={props.form} view={props.view} linkUrl={props.linkUrl} disableRegistrations={props.disableRegistrations} loggedIn={props.loggedIn} />)
    }
    return (<RegistrationStatus target={props.target} eventStatus={calcEventStatus()} form={props.form} view={props.view} linkUrl={props.linkUrl} disableRegistrations={props.disableRegistrations} loggedIn={props.loggedIn} />)
}