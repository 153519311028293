import React from 'react';
import * as toast from '../../toast';

export interface IRegistrationButtonsItem { target: string, Form: any, Registrations: Array<any>, Status: string, LinkUrl: string, ChangeRegistration: Function, HideModal: Function, DisplayModal: Function, showModal: boolean, LoggedIn: boolean, Hash?: string }

interface State {
}
export default class RegistrationButtons extends React.Component<IRegistrationButtonsItem, any>{
    constructor(props) {
        super(props);
    }

    public getLinkUrl() {
        if (this.props.LinkUrl && this.props.Status == 'open') {
            var link = this.props.LinkUrl.replace(/\/+$/, '') + '/register' + (this.props.Hash ? this.props.Hash : '');
            return link;
        }
        else {
            return this.props.LinkUrl;
        }
    }
    open = () => {
        this.props.DisplayModal();
    }
    close = () => {
        this.props.HideModal();
    }
    public unregisterButton(submission, displayName) {
        var tempSubmission = submission.toString();
        $.ajax({
            url: "/api/events/Unregister",
            type: 'POST',
            data: JSON.stringify({ formIdentifier: this.props.Form, submission: tempSubmission }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            success: function (data) {
                toast.Toast.Display(displayName + data);
                this.props.ChangeRegistration();
                return data;

            }.bind(this)
        });
    }
    public renderButton() {
        if (this.props.Status == "cancelled") {
            return (<button type="button" className={"btn btn-primary-light-blue greyOut"}>Cancelled<i className="cancelled fa fa-user-times pull-right" aria-hidden="true"></i></button>);
        }
        else if (this.props.Registrations.length > 0) {
            return (<button type="button" onClick={() => { this.props.DisplayModal(); }} className={"btn btn-success unRegisterButton modalButton modalButton" + this.props.target} data-bs-toggle="modal" data-bs-target={"#unRegistrationModal" + this.props.target}>Unregister<i className="fa fa-user-times pull-right" aria-hidden="true"></i></button>);
        }
        else if (this.props.Status == "open") {
            return (<a href={this.getLinkUrl()} className="btn btn-primary-light-blue">Register <i className="fa fa-user-plus pull-right" aria-hidden="true"></i></a>);
        }
        else {
            return (<button type="button" className={"btn btn-primary-light-blue greyOut"}>Register<i className="cancelled fa fa-user-times pull-right" aria-hidden="true"></i></button>);
        }
    }
    public render() {
        return (
            <span className="padding-right-0 padding-left-0">
                {
                    this.props.Registrations.length > 0 || this.props.showModal ?
                        <div className="misoModal modal" id={"unRegistrationModal" + this.props.target} aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Who would you like to unregister from this event?</h5>
                                    </div>
                                    <div className="modal-body">
                                        <div className="modalBody">
                                            {
                                                this.props.Registrations.length == 0
                                                    ? <span> You have no submissions for this event</span>

                                                    : this.props.Registrations.map((reg, i) => {
                                                        var displayName = reg.GuestEmail ? reg.GuestEmail : reg.SYSTEMCOLUMN_SubmitUser;
                                                        return (
                                                            <div key={i} className="regItem"><span className="name pull-left">{displayName}</span> <button className='btn btn-success btn-sm pull-right' onClick={() => { this.unregisterButton(reg.id, displayName); }}> Unregister</button></div>
                                                        )
                                                    })
                                            }

                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn" data-bs-dismiss="modal" onClick={this.close} aria-label="Close">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div> :''

                }
                {this.renderButton() }
            </span>
        )
    }
    
}
