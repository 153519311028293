import React from 'react';
import * as DocumentsBase from '../Find/DocumentsBase';
import Moment from "moment";
import * as follow from '../../follow';
import * as dateFormatter from '../../dateFormatter';

class TariffDocumentItem extends DocumentsBase.DocumentItem {
	public entityname: string = null;
	public entityurl: string = null;
	public DocumentSortOrder: string = null;
	public year: number = null;
	public month: string = null;
}

export interface ITariffDocumentsProps extends DocumentsBase.IDocumentsProps<TariffDocumentItem> {
	tariffGroup:string;
}

export interface ITariffDocumentsState extends DocumentsBase.IDocumentsState<TariffDocumentItem> {
	query:TariffDocumentsQuery;
}

export class TariffDocumentsQuery {
	public partial_fields: {
		source: {
			exclude:Array<string>
		}
	} = {
		source: {
			exclude: ["documentText"]
		}
	};
	script_fields: {
		documentTextExcerpt: {
			params: { field: string, length: number },
			script: string
		}
	} = {
		documentTextExcerpt: {
			params: { field: "documentText", length: 150 },
			script: "ascropped"
		}
	};
	sort: Array<any> = 
		[ 
			{ "DocumentSortOrder": {"order":"asc"} },
			{ "Name":"asc" },  
			"_score" 
		];
	query: {
		filtered:{
			filter: { 
				and:Array<any>
			},
			query: {
                query_string: { query:string }
			}
		}
	} = {
		filtered: {
			filter: {
				and: [
					{ query: { term: { "Properties.legaldoctype": "Tariff" } } }
				]
		},
		query: {
            query_string: { query: "*"} 
		}
	}
   };

	public constructor(tariffGroup:string = null) { 
		if (tariffGroup && tariffGroup.trim().length > 0) {
			this.query.filtered.filter.and.push({query: { term: { "Properties.legaldocsubtype": tariffGroup }  } });
		}
	}

	public SetAdditionalQueryText(queryText:string) : void {
        if (queryText && queryText.trim().length > 0) {
            this.query.filtered.query.query_string.query = '"' + queryText + '"';
		}
        else {
			this.query.filtered.query.query_string.query = "*";
		}
	}

	public ClearAdditionalQueryText() : void {
		this.SetAdditionalQueryText(null);
	}
}

export class TariffDocuments extends DocumentsBase.DocumentsBase<TariffDocumentItem, ITariffDocumentsProps, ITariffDocumentsState> {
	constructor(props) {
		super(props);
		this.state = { results: [], totalAvailableResults: 0, query: null, loading: true };
		this.SetAdditionalQueryText = this.SetAdditionalQueryText.bind(this);
		this.ClearAdditionalQueryText = this.ClearAdditionalQueryText.bind(this);
		this.GenerateTariffDocumentQuery = this.GenerateTariffDocumentQuery.bind(this);
	}

	constructResultItem() : TariffDocumentItem {
		return new TariffDocumentItem();
	}

	componentDidMount(){  
		super.componentDidMount();
		
		if (this.props.tariffGroup) {
			this.setState({query: this.GenerateTariffDocumentQuery() });
		}
    }

	public SetAdditionalQueryText(queryText:string) : void {
		var query:TariffDocumentsQuery = (this.state.query as TariffDocumentsQuery) || this.GenerateTariffDocumentQuery();
		query.SetAdditionalQueryText(queryText);


		if (query != this.state.query) {
			this.setState({query: query});
		}
		else {
			this.executeSearch();
		}
	}

	public ClearAdditionalQueryText() : void {
		this.SetAdditionalQueryText(null);
	}

	public GenerateTariffDocumentQuery():TariffDocumentsQuery {
		var query = new TariffDocumentsQuery(this.props.tariffGroup);
		return query;
	}

	private getDisplayDate(date: Date){
		if(date != null){
			var mDate = Moment.utc(date, Moment.ISO_8601, true);

			return (
				<div className="tariffDocDate">{dateFormatter.DateFormatter.Format(mDate, "MM/DD/YYYY") }</div>							
			)
		}
	}

	public render() {
		if (!this.state.results || !this.state.results.length) {
			return (<div className="margin-btm-20">There are no Tariff Documents that match this criteria.</div>);
        }
        return (<div className="row">
			<div className="col-12 tariffDocList" >
            {
                this.state.results.map((resultItem:TariffDocumentItem, resultItemIndex:number) => {
					var responsibleCommittee = "";
					return(
                        <div key={resultItemIndex} className="tariffDocItem"> 
							<div className="tariffFollowing follow" id={"tariffDocFollow-" + resultItem.DocumentGuid} dangerouslySetInnerHTML={{ __html: follow.Follow.Display(resultItem.DocumentGuid, resultItem.isFollowing, true) }} { ... (this.props.disableFollowing === true) ? { style: { display: "none" } } : {} } ></div>
                            <div className="tariffDocTitle"><a href={ resultItem.GetDocumentUrl() } target="_blank" >{resultItem.DisplayTitle ? resultItem.DisplayTitle : resultItem.Name}</a> &nbsp; </div>
                            {this.getDisplayDate(resultItem.effectivedate) }<br/>
                        </div>							
                    );
                })
            }
        </div></div>);
    }
}
