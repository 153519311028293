import React from 'react';
import RelatedDocumentsBase, { RelatedDocumentItem, IRelatedDocumentsProps, IRelatedDocumentsState } from './RelatedDocumentsBase';
import RelatedDocumentsList from './RelatedDocumentsList'

export class RelatedPageDocuments extends RelatedDocumentsBase<RelatedDocumentItem, IRelatedDocumentsProps<RelatedDocumentItem>, IRelatedDocumentsState<RelatedDocumentItem>> {
    constructor(props) {
        super(props);
        this.state = { results: [], totalAvailableResults: 0,  query: null, loading: true };
    }
    
    constructResultItem(): RelatedDocumentItem {
        return new RelatedDocumentItem();
    } 

    public render() {
        return (
            <RelatedDocumentsList RelatedDocumentItems={this.state.results} disableFollowing={this.props.disableFollowing} allowCompressedDownload={true} {...this.props} loading={this.state.loading} />
        );
    }
}
