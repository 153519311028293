import {IModalButton} from "./modal";
import Miso from "./global";
import * as secured from './secured';
import * as toast from './toast';
import * as modal from './modal';

export class Follow {
    public static DisplayFromReact(itemID: string, displayLink: boolean) {
        var deferred = $.Deferred();
        let stringToReturn: string;
        let follow: JQueryPromise<void> = this.GetIsFollowing(itemID)
            .then(
                (following: boolean) => {
                    stringToReturn = this.Display(itemID, following, displayLink);
                    deferred.resolve(stringToReturn);
                }
                
        )
        return deferred.promise();
    }
    public static Display(itemID: string, following: boolean, displayLink: boolean, isButton?: boolean) {
        var renderHTML = '';
        var text = '';
        var cssClass = 'btn btn-small';
        var onClick = null;
        if (following) {      
            text = '<i class="fa fa-check margin-right-5" aria-hidden="true"></i> Following';
            cssClass += isButton ? ' follow-btn btn-default dark-blue' : ' follow-link btn-link green';                                  
        } else {              
            text = '<i class="fa fa-plus margin-right-5" aria-hidden="true"></i> Follow';
            cssClass += isButton ? ' follow-btn btn-primary' : ' follow-link btn-link dark-blue';
        }

        onClick = "Miso.Follow.Follow.Follow('" + itemID + "'," + displayLink + ")";

        if (!Miso.Secured.LoggedIn) {
            cssClass += ' logged-out secured-link';
        }

        renderHTML = '<button id="Follow' + itemID + '" class="' + cssClass + '" onclick=' + onClick + ' data-following="' + following + '">' + text + '</button>';
        
        return renderHTML;
    }

    public static Follow(id: string, displayLink: boolean) {
        var el = $("[id='Follow" + id + "']");
        var following = el.data("following");

        if (following) {
            let unfollowBtn: IModalButton = { name: "Unfollow", func: this.onUnfollow, params: { id, displayLink } };
            let cancelBtn: IModalButton = { name: "Cancel", func: null, params: null, btnClass: 'btn-default' };

            // Prompt to unfollow
            Miso.Modal.Display("Are you sure you want to unfollow?", [unfollowBtn, cancelBtn]);
        } else {
            // Call subscribe web service
            $.ajax({
			    url: '/api/contentalerting/subscribe',
                type: 'POST',
                data: JSON.stringify(id),
                dataType: 'json',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                success: function (data) {
                    // Now following
                    Miso.Toast.Display('You are now following ' + data.subscription.contentName, 'Congratulations');

                    // Change button status            
                    el.data("following", true);

                    el.html('<i class="fa fa-check margin-right-5" aria-hidden="true"></i> Following');
                    el.removeClass("dark-blue").addClass("green");
                    if (el.hasClass("btn-primary")) {
                        el.removeClass("btn-primary").addClass("btn-default");
                    }
                },
                error: function (err) {
                    Miso.Toast.Display('You are unable to follow this item at this time.', 'Whoops!', 'error');
                }
            });            
        }
    }

    public static GetIsFollowing(id: string) {
        var deferred = $.Deferred();

        // Call subscribe web service
        $.ajax({
            url: '/api/contentalerting/getissubscribed?contentId=' + id,
            type: 'GET',
            success: function (data) {
                deferred.resolve(data);
            },
            error: function (err) {
                deferred.resolve(false);
            }
        });

        return deferred.promise();
    }

    private static onUnfollow(params: any) {
        var el = $("[id='Follow" + params.id + "']")

        // Call subscribe web service
        $.ajax({
            url: '/api/contentalerting/unsubscribe',
            type: 'POST',
			data: JSON.stringify([params.id]),
			contentType: 'application/json',
			dataType: 'json',        
            success: function (data) {
                // Not following
                Miso.Toast.Display('You are no longer following this item.');

                // Change button status
                el.data("following", false);

                el.html('<i class="fa fa-plus margin-right-5" aria-hidden="true"></i> Follow');
                el.removeClass("green").addClass("dark-blue");
                if (el.hasClass("btn-default")) {
                    el.removeClass("btn-default").addClass("btn-primary");
                }
            },
            error: function (err) {
                console.log(err);
            }
        });
    }
}
