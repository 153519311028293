import React, { createRef } from 'react';

interface IMutliselectProps {
    items: Array<string>,
    keepOpen?: boolean,
    openByDefault?: boolean,
    sortColumnsAlphabetically?: boolean;
    roleDisplayLimit?: number,    
    updateSelectedItems: (selectedItems: Array<string>) => void,
}

interface IMultiselectState {
    inputText: string;
    selectedItems: string[];
    dropdownOpen: boolean;
}

export class Multiselect extends React.Component<IMutliselectProps, IMultiselectState>{
    dropdownRef: React.RefObject<HTMLDivElement>;
    inputRef: React.RefObject<HTMLInputElement>;

    constructor(props) {
        super(props);

        this.state = {
            inputText: '',
            selectedItems: [],
            dropdownOpen: this.props.keepOpen || this.props.openByDefault,
        };

        this.dropdownRef = createRef();
        this.inputRef = createRef();

        this.openDropdown = this.openDropdown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.updateInputText = this.updateInputText.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.removeAllItems = this.removeAllItems.bind(this);
        this.renderSelectedItemsInInput = this.renderSelectedItemsInInput.bind(this);
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    public componentDidUpdate() {
        // Check if selected items still exist in updated props list; remove them if not.
        for (let i = 0; i < this.state.selectedItems.length; i++)
        {
            let nextSelectedItem = this.state.selectedItems[i];
            if (!this.props.items.includes(nextSelectedItem)) {
                this.selectItem(nextSelectedItem);
            }
        }
    }

    private openDropdown() {
        this.setState({ dropdownOpen: true });
    }

    private handleClickOutside(event) {
        if (this.props.keepOpen) return;

        if (this.dropdownRef.current &&
            !this.dropdownRef.current.contains(event.target) &&
            !this.inputRef.current.contains(event.target)) {
            this.setState({ dropdownOpen: false });
        }
    }

    private updateInputText(text: string) {
        this.setState({ inputText: text });
    }

    private selectItem(item) {
        let indexOfSelectedItem = this.state.selectedItems.indexOf(item);
        let updatedSelectedItems = this.state.selectedItems.slice();
        if (indexOfSelectedItem > -1) {
            updatedSelectedItems.splice(indexOfSelectedItem, 1);
        } else {
            updatedSelectedItems.push(item);
        }

        this.props.updateSelectedItems(updatedSelectedItems);
        this.setState({
            ...this.state,
            selectedItems: updatedSelectedItems,
        });
    }

    private removeAllItems() {
        this.props.updateSelectedItems([]);
        this.setState({
            ...this.state,
            selectedItems: [],
        });
    }

    private renderSelectedItemsInInput() {
        if (this.props.roleDisplayLimit && this.state.selectedItems.length >= this.props.roleDisplayLimit) {
            return (
                <li className="multiselect-chip">
                    {this.state.selectedItems.length} roles selected&nbsp;&nbsp;
                    <i className="fa fa-remove" onClick={this.removeAllItems}></i>
                </li>
            );
        }

        return this.state.selectedItems.map((value, i) =>
            <li key={i} className="multiselect-chip">
                {value}&nbsp;&nbsp;
                <i className="fa fa-remove" onClick={() => this.selectItem(value)}></i>
            </li>
        );
    }

    private sortItemsByColumn(items: string[], numberOfColumns: number = 4): string[] {
        let alphabetizedItems = items.sort((a, b) => a.localeCompare(b));
        let numberOfRolesInEachColumn = Math.ceil(items.length / numberOfColumns);
        let sortedItems = [];
        let step = 0;
        let offset = 0;
        let columnizedIndex = 0;

        for (let i = 0; i < alphabetizedItems.length; i++) {
            if (i !== 0 && i % numberOfRolesInEachColumn === 0) {
                offset += columnizedIndex + numberOfColumns + step;
                step--;
            }

            columnizedIndex = i + i * numberOfColumns - offset;
            sortedItems[columnizedIndex] = alphabetizedItems[i];
        }

        return sortedItems;
    }

    private getFormattedItemsList() {
        let items = this.props.sortColumnsAlphabetically ? this.sortItemsByColumn(this.props.items) : this.props.items;

        return items.filter((item) => item.toLowerCase().includes(this.state.inputText.toLowerCase()))
            .map((item, i) => {
                return (
                    <li key={i}
                        className={`mutliselect-selection-item ${this.state.selectedItems.includes(item) ? "selected" : "not-selected"}`}
                        onClick={() => { this.selectItem(item); }}
                    >
                        {item}
                    </li>
                );
            });
    }

    public render() {
        return (
            <div className="multiselect">
                <div className="multiselect-input-section"
                    style={{
                        border: this.state.dropdownOpen ? "1px solid #0082ca" : "1px solid #ccc",
                        borderBottomLeftRadius: this.state.dropdownOpen ? "0" : "8px",
                        borderBottomRightRadius: this.state.dropdownOpen ? "0" : "8px",
                    }}
                >
                    <div className="multiselect-selected-items">
                        <ul>
                            {this.renderSelectedItemsInInput()}
                            <input type="text"
                                ref={this.inputRef}
                                autoFocus
                                placeholder="Search roles"
                                onFocus={this.openDropdown}
                                onChange={(event) => this.updateInputText(event.currentTarget.value)} />
                        </ul>
                    </div>
                </div>
                {this.state.dropdownOpen &&
                    <div className="multiselect-dropdown" ref={this.dropdownRef}>
                        {this.getFormattedItemsList()}
                    </div>
                }
            </div>
        )
    }
}