import React from 'react';

export class HeroButtonDefault {
    buttonText: string;
    buttonLink: string;
    buttonClass: string; 
    fAIconPosition: string; 
    fAIcon: string;
    secureLink: boolean;
    newWindow:boolean;
}


export class HeroButton extends React.Component<HeroButtonDefault> {
    public render() {
        return (
            <a href={this.props.buttonLink["Uri"] ? this.props.buttonLink["Uri"] :this.props.buttonLink} className={this.props.buttonClass + " " + (this.props.secureLink ? "secured-link" : "")}  target={ this.props.newWindow === true ? "_blank" : "_self" }>
                {this.props.fAIcon && this.props.fAIconPosition == "left" ? <i className={this.props.fAIcon + " margin-right-10"}></i> : ""}
                {this.props.buttonText}
                {this.props.fAIcon && this.props.fAIconPosition == "right" ? <i className={this.props.fAIcon + " margin-left-10"}></i> : ""}
            </a>
        )
	}
}