import React from 'react';
import {SearchNavigableDocumentItem} from './SearchNavigation';
import {IPagingProps} from '../Find/Paging';
import List from '../../hocs/List';
import ListPager from '../../hocs/ListPager';
import * as dateFormatter from '../../dateFormatter';
import * as awesome from '../../awesome';

interface ISearchDocumentListProps extends IPagingProps {
    Results: Array<SearchNavigableDocumentItem>;
    disableFollowing:boolean;
    Columns: Array<any>;
    UseDisplayTitle: boolean;
    TableStyle: string;
}

interface ISearchDocumentListItemProps {
    Result:SearchNavigableDocumentItem;
    Loading: boolean;
    UseDisplayTitle: boolean;
}

interface ISearchDocumentListItemState {
}

const SearchDocumentListItem: React.SFC<ISearchDocumentListItemProps> = (props) => {
    return (
        <div className="infoBox">
            <div className="info">
                <div className="infoItem">

                    <a href={props.Result.SearchHitUrl.replace(" ", "%20")} rel="noopener noreferrer" target='_blank'>
                        <span className="docnav-icon" dangerouslySetInnerHTML={{ __html: awesome.Awesome.getDocumentIcon(props.Result.SearchFileExtension, null, "") }} />
                        { props.UseDisplayTitle && props.Result.DisplayTitle ?
                            <span className="docnav-filename">{props.Result.DisplayTitle}</span>
                            : <span className="docnav-filename">{props.Result.SearchFilename || props.Result.FileName || props.Result.SearchTitle}</span>
                        }
                    </a>
                </div>
                <div className="inlineArea"> 
                    Updated {dateFormatter.DateFormatter.Format(props.Result.Updated, "M/D/YYYY") }

                </div>
            </div>
        </div>
    );
}

let listOptions = {
    className: "linkList",
    dataSetName: "Results",
    dataItemPropName: "Result"
};
const SearchDocumentList = ListPager<ISearchDocumentListProps>(List<ISearchDocumentListProps>(SearchDocumentListItem, listOptions), listOptions);

export default SearchDocumentList;