import React from 'react';
import { RegistrationStatusCalculated } from "../RegistrationStatus/RegistrationStatus";
import * as dateFormatter from '../../dateFormatter';

export interface IMeetingItem {
    id: string, name: string, startDate: Date, eventCanceled: boolean, isRegistered: boolean, linkURL: string, registrationOpenDate: Date, registrationCloseDate: Date, registrationStatus: string, registrationStatusMessage: string, allowRegistration:boolean, disableRegistrations: boolean, changeRegistration: Function, form:any }
//interface MeetingItemProps { Subject: string, PublishDate: Date  }

interface State {
}
export default class MeetingItem extends React.Component<IMeetingItem, any>{
    constructor(props) {
        super(props);
    }

    public getTime() {
        //var tempDate = new Date(this.props.StartDate);
        return dateFormatter.DateFormatter.Format(this.props.startDate, "MMMM D, h:mm a");
    }

    private getMeetingName() {
        if (this.props.eventCanceled) {
            return <span><span className='strikeThrough'>{this.props.name}</span> <span className='red nonStrikeThrough'> (cancelled) </span></span>;
        } else {
            return <span>{this.props.name}</span>;
        } 
    }
    private renderCalButton() {

        if (this.props.eventCanceled) {
            return (<span></span>);
        } else {
            return (<a className="calButton" href={this.getActionLink("/AddToICalendar")}><i className="fa fa-calendar" aria-hidden="true"></i>Add to Calendar</a>);
        }
        
    }
    private getActionLink(action) {
        var splitLink = this.props.linkURL.split("?");
        var globalLink = splitLink[0];
        return globalLink + action;
    }

    public render() {
        return (
            <div className='margin-top-5 margin-btm-10 infoBox'>
                <div className='info'>
                    <div className='wordBreak wb65'>
                        <a href={this.props.linkURL} className="meetingItemURL">
                            {this.getMeetingName() }
                        </a>
                        <div className="inlineArea">
                            <div className="meetingDate leftSide">{this.getTime() }</div>
                            <div className='follow rightSide'>
                                {this.renderCalButton()}
                                {<RegistrationStatusCalculated target={this.props.id} allowRegistration={this.props.allowRegistration} registrationOpenDate={this.props.registrationOpenDate} registrationCloseDate={this.props.registrationCloseDate} isCancelled={this.props.eventCanceled} eventStatus={this.props.registrationStatus} form={this.props.form} view={"Icons"} linkUrl={this.getActionLink("/")} disableRegistrations={this.props.disableRegistrations} loggedIn={true} />}
                                
                            </div>
                        </div>

                        
                    </div>
                

                </div>
            </div>
        )
    }
}
