// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import { InfoBoxWithThumbnail } from "../InfoBox/InfoBoxWithThumbnail";

interface ILeadershipProps { }

interface State {
    LeadershipPages: Array<any>;
    FilteredPages: Array<any>;
    FilterCategory: String;
    CategoryList: Array<string>;
    Loading: boolean;
}

export class LeadershipBlock extends React.Component<ILeadershipProps, any>{
    constructor(props) {
        super(props);
        this.state = { LeadershipPages: [], FilterCategory: "", CategoryList:[], FilteredPages:[], Loading:true};
    }

    public componentDidMount() {
        this.getLeadershipPages();
    }

    private getLeadershipPages() {

        return $.getJSON("/api/Leadership/GetLeadershipPages",
            function (data) {
                var listOfPages = [];
                for (var i = 0; i < data.leadershipPages.length; i++) {
                    if (data.leadershipPages[i].leadershipRole.indexOf(data.categoryList[0]) > -1) {
                        listOfPages.push(data.leadershipPages[i]);
                    }
                }
                if (data.categoryList[0] == "Executive Team") {
                    listOfPages.sort((a, b) => a.sortOrder - b.sortOrder)
                } else {
                    listOfPages.sort((a, b) => a.personName.split(' ').slice(-1)[0].localeCompare(b.personName.split(' ').slice(-1)[0]))
                }
                console.log(listOfPages)
                this.setState({ LeadershipPages: data.leadershipPages, CategoryList: data.categoryList, FilteredPages: listOfPages, FilterCategory: data.categoryList[0], Loading: false });
            }.bind(this));
    }

    public categoryFilter(category) {
        var listOfPages = [];

        for (var i = 0; i < this.state.LeadershipPages.length; i++) {
            if (this.state.LeadershipPages[i].leadershipRole.indexOf(category) > -1) {
                listOfPages.push(this.state.LeadershipPages[i]);
            }
        }
        if (category == "Executive Team") {
            listOfPages.sort((a, b) => a.sortOrder - b.sortOrder)
        } else {
            listOfPages.sort((a, b) => a.personName.split(' ').slice(-1)[0].localeCompare(b.personName.split(' ').slice(-1)[0]))
        }

        console.log(listOfPages)
        this.setState({ FilterCategory: category, FilteredPages: listOfPages });
    }

    public renderTabSelector() {

        return (
            <div className="margin-bottom-10 tab-content-block">
                <ul className="nav nav-tabs">
                     {  
                        this.state.CategoryList.map((category, i) => {
                            return (
                                <li key={i} className="nav-item">
                                    <a className={(this.state.FilterCategory == category ? "nav-link active" : "nav-link")} data-bs-toggle="tab" onClick={() => { this.categoryFilter(category); }}>{category}</a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        );
    }

    public render() {
        if (this.state.Loading) { 
            return (<h2><i className="fa fa-spinner text-center w-100 margin-top-10" aria-hidden="true"></i></h2>);
        }
        return (
            <div id="Leadership">
                <div className='notifications-filters margin-btm-10'>
                  {this.renderTabSelector() }
                </div>
                {this.state.FilteredPages.length == 0 ? <div></div>
                    :
                <div className="row aggregatorPageRow">
                    {
                        this.state.FilteredPages.map((leadershipPage, i) => {
                            {
                                return (
                                    <div key={i} className='col-12 col-sm-6 col-lg-4 aggregatorSection d-grid margin-btm-20'>
                                        <InfoBoxWithThumbnail linkUrl={leadershipPage.linkURL} imageString={leadershipPage.thumbnailUrl} headerText={leadershipPage.personName} description={leadershipPage.jobTitle} linkText={"Read Bio"} />                                    
                                    </div>
                                )
                            }
                        })
                    }
                    </div>
                }
            </div>
        )
    }
}
