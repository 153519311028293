import React from 'react';
import * as MarketReportNavigationConfiguration from './MarketReportNavigationConfiguration';
import {SearchDocumentNavigator, SearchDocumentNavigatorQuery} from './SearchDocumentNavigator';
import * as awesome from '../../awesome';

export class MarketReportSearchNavigator extends SearchDocumentNavigator {
    protected get placeholderTemplate() {
        return "In order to view Market Reports, continue using the navigation on the left";
    }

    public componentDidMount(){ 
        super.componentDidMount();

        let initialNavigationState = MarketReportNavigationConfiguration.GenerateMarketReportTopLevelNavigationItem(); 
        let currentNavigationPath = [initialNavigationState];
        let navTrail = this.state.navigationTrail || [];

        let initialState = this.getPartialStateFromHash();
        initialState.currentNavigationPath = currentNavigationPath;
        initialState.currentNavigationItem = initialNavigationState;
        initialState.navigationTrail = navTrail;

        if (location.hash && location.hash.indexOf("#") >= 0 && location.hash.indexOf("nt=") >= 0 && this.hashParams.nt) {
            initialState.forceLoading = true;
        }
        
        this.setState(initialState); 
    }

    protected postComposeFilters(queryFilters:Array<any>, resultFilters:Array<any>) {
        let kind = this.props.marketReportKind || "Active";

        if (queryFilters && queryFilters.length >= 2 && "Active" === kind) {
            let readersGuideQuery = new SearchDocumentNavigatorQuery();
            delete readersGuideQuery.facets;
            
            if (resultFilters && resultFilters.length) {
                readersGuideQuery.filter.and = resultFilters;
            } else {
                delete readersGuideQuery.filter;
            }

            let readersGuidFilters:Array<any> = [];                
            queryFilters.map((filter, i) => {
                if (filter.query && filter.query.term && filter.query.term.MarketReportName) {
                    readersGuidFilters.push({query: { term: {MarketReportName: MarketReportSearchNavigator.trimParentheticalValues(filter.query.term.MarketReportName).trim()}  } });
                } else {
                    readersGuidFilters.push(filter);
                }
            });
            readersGuideQuery.query.filtered.filter.and = readersGuidFilters;
            readersGuideQuery.query.filtered.filter.and.push( {query: { term: { "MarketReportKind":  "Readers" } } });
            readersGuideQuery.size = 1;

            this.initQueryExecution(readersGuideQuery, this.parseReadersGuidResult.bind(this));
        }

        if ("Active" === kind) {
            let kindOr = { or: [ {not: { exists: {field: "MarketReportKind"}}},{query: { term: { "MarketReportKind":  kind } }  }  ] };
            queryFilters.push(kindOr);
        } else {
            queryFilters.push( {query: { term: { "MarketReportKind":  kind } } });
        }
    }

    private static trimParentheticalValues(stringWithPotentialParentheticalValues:string):string {
        if (typeof stringWithPotentialParentheticalValues === 'string' && stringWithPotentialParentheticalValues.length) {
            return stringWithPotentialParentheticalValues.replace(/\([^()]*\)/g, "");
        } else {
            return stringWithPotentialParentheticalValues;
        }
    }

    private parseReadersGuidResult(data:any) {
        if (data && data.hits && data.hits.hits && data.hits.hits.length) {
            this.setState({readersGuide: this.parseHit(data.hits.hits[0]) })
        }
    }

    public renderReadersGuide() {
        if (this.state.readersGuide) {
            return (                
                <div className="col-12"> 
                    <div className="docnav-document">
                        <span className="docnav-icon" dangerouslySetInnerHTML={{ __html: awesome.Awesome.getDocumentIcon((this.state.readersGuide.SearchFilename || this.state.readersGuide.FileName) , null, "margin-right-10") }} />
                        <a className="docnav-link" href={this.state.readersGuide.SearchHitUrl} rel="noopener noreferrer" target='_blank'>
                            <span className="docnav-filename">{MarketReportSearchNavigator.trimParentheticalValues(this.state.currentNavigationItem.parentPropertyValue)} Readers' Guide</span>
                        </a>
                    </div>
                </div>					
            )
        }
    }
}
