// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import EventTypeColorKey from "./EventTypeColorKey";
import {IConfigurableChoice} from "../../ConfigurableChoice";

export interface IFilter { eventType: string, eventFormat: string, eventLocation: string, myEvents: boolean }
interface IEventFilterProps {EventTypes: Array<IConfigurableChoice>, EventFormats: Array<IConfigurableChoice>, EventLocations: Array<IConfigurableChoice>, UpdateFilter: (filter: IFilter) => void, Filter: IFilter}
interface State { filter: IFilter }

export default class EventFilters extends React.Component<IEventFilterProps, State>{
    constructor(props){
        super(props);        
        this.state = { filter: this.props.Filter };
    }

    private onFilter(choice, value){
        var filter = this.state.filter;        
        filter[choice] = value;        
        this.setState({filter: filter});
        this.props.UpdateFilter(filter);

    }

    private RenderConfigurableChoices(choices: Array<IConfigurableChoice>){
        if(choices != null){
            return(
                choices.map((choice, i) => {
                    return(
                        <option key={i} value={choice.value}>{choice.text}</option>
                    )
                })
            )
        }
    }

    render() {
        return(
            <div className="row">
                <div className="col-12 col-md-6">
                    <h3 className="blue">
                        Filter Calendar By
                    </h3>
                </div>
                <div className="col-12 col-md-6">
                    <EventTypeColorKey EventTypes={this.props.EventTypes} />
                </div>
                <div className="clearfix" />
                <div className="col-12 col-md-3">
                    <div className="form-group">                        
                        <label htmlFor="filterEventType">Event Type </label>&nbsp;
                        <select className="form-select" id="filterEventType" value={this.state.filter.eventType} onChange={(event: React.FormEvent<HTMLSelectElement>) => {this.onFilter('eventType', (event.target as HTMLSelectElement).value);} }>
                            <option value="All">All</option>
                            { this.RenderConfigurableChoices(this.props.EventTypes) }
                        </select>
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="form-group">                        
                        <label htmlFor="filterEventFormat">Event Format </label>&nbsp;
                        <select className="form-select" id="filterEventFormat" value={this.state.filter.eventFormat} onChange={(event: React.FormEvent<HTMLSelectElement>) => {this.onFilter('eventFormat', (event.target as HTMLSelectElement).value);}}>
                            <option value="All">All</option>
                            { this.RenderConfigurableChoices(this.props.EventFormats) }
                        </select>
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="form-group">                        
                        <label htmlFor="filterEventLocation">Location </label>&nbsp;
                        <select className="form-select" id="filterEventLocation" value={this.state.filter.eventLocation} onChange={(event: React.FormEvent<HTMLSelectElement>) => {this.onFilter('eventLocation', (event.target as HTMLSelectElement).value);}}>
                            <option value="All">All</option>
                            { this.RenderConfigurableChoices(this.props.EventLocations) }
                        </select>
                    </div>
                </div>
                <div className="col-12 col-md-3 flexContainer">
                    <div className="radio">
                        <label>
                            <input type="radio" name="myEvents" id="myEventsFalse" value="false" checked={this.state.filter.myEvents == false} onChange={(event: React.FormEvent<HTMLInputElement>) => {this.onFilter('myEvents', false)}} />
                            All Events
                        </label>                                        
                    </div>
                    <div className="radio">
                        <label>
                            <input type="radio" name="myEvents" id="myEventsTrue" value="true" checked={this.state.filter.myEvents == true} onChange={(event: React.FormEvent<HTMLInputElement>) => {this.onFilter('myEvents', true)}} />
                            My Events
                        </label>
                    </div>                    
                </div>
            </div>
        )
    }
}