// A '.tsx' file enables JSX support in the TypeScript compiler,
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import * as ReactDOM from "react-dom";
import Moment from "moment";
import EventDetails from "./EventDetails";
import EventFilters, {IFilter} from "./EventFilters";
import {IEvent} from "./Event";
import { IConfigurableChoice } from "../../ConfigurableChoice";
import * as dateFormatter from '../../dateFormatter';

declare var $: any;
declare var kendo: any;


interface IEventCalendarProps { 
    endpoint: string,
    myEvents?: boolean,
 }

interface State { 
    activeView: string, 
    activeDate: Date, 
    activeEvents: Array<IEvent>,
    activeEvent?: number, 
    eventTypes: Array<IConfigurableChoice>, 
    eventFormats: Array<IConfigurableChoice>, 
    eventLocations: Array<IConfigurableChoice>, 
    filter: IFilter,
    loaded: boolean,
    hashUrl:string
}

export class EventCalendar extends React.Component<IEventCalendarProps, State>{
    static State: State;
    constructor(props: IEventCalendarProps){
        super(props);
        this.state = { activeView: "month", activeDate: new Date(), activeEvents: new Array<IEvent>(), activeEvent: null, eventTypes: null, eventFormats: null, eventLocations: null, filter: { eventType: "All", eventFormat: "All", eventLocation: "All", myEvents: this.props.myEvents }, loaded: false, hashUrl: "" };      
        this.updateFilter = this.updateFilter.bind(this);

    }

    public componentDidMount(){
        this.getConfigurableChoices().then((result) => {
            this.setState({ eventTypes: result.eventTypes, eventFormats: result.eventFormats, eventLocations: result.eventLocations}, this.initKendoScheduler);            
        });
        if (window.location.hash.length == 0){
            this.generateHashUrl();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.activeView !== prevState.activeView || this.state.activeDate !== prevState.activeDate) {
            this.generateHashUrl();
        }
        if (prevState.loaded == false && this.state.loaded == true) {
            this.recievedHashUrl();
        }
    }
    private initKendoScheduler() {
        var scheduler = $(ReactDOM.findDOMNode(this)).find("#Scheduler");         

        scheduler.kendoScheduler({               
            date: new Date(),
            startTime: Moment(new Date()).startOf('day').toDate(),
            endTime: Moment(new Date()).endOf('day').toDate(),
            mobile: false,                        
            views: [
                {type: "day", dateHeaderTemplate: this.dayDateHeaderTemplate(), eventTemplate: this.dayEventTemplate(), allDayEventTemplate: this.dayEventTemplate(), allDaySlotTemplate: this.slotTemplate(), slotTemplate: this.slotTemplate(), startTime: Moment("1/1/1901 07:00", "M/D/Y hh:mm").toDate(), endTime: Moment("1/1/1901 18:00", "M/D/Y hh:mm").toDate()},
                {type: "week", dateHeaderTemplate: this.weekDateHeaderTemplate(), eventTemplate: this.weekEventTemplate(), allDayEventTemplate: this.weekAllDayEventTemplate(), allDaySlotTemplate: this.slotTemplate(), slotTemplate: this.slotTemplate(), startTime: Moment("1/1/1901 07:00", "M/D/Y hh:mm").toDate(), endTime: Moment("1/1/1901 18:00", "M/D/Y hh:mm").toDate()},
                {type: "workWeek", dateHeaderTemplate: this.weekDateHeaderTemplate(), eventTemplate: this.weekEventTemplate(), allDayEventTemplate: this.weekAllDayEventTemplate(), allDaySlotTemplate: this.slotTemplate(), slotTemplate: this.slotTemplate(), startTime: Moment("1/1/1901 07:00", "M/D/Y hh:mm").toDate(), endTime: Moment("1/1/1901 18:00", "M/D/Y hh:mm").toDate()},
                {type: "month", eventHeight: 37, workWeekStart: 1, workWeekEnd: 5, selected: true, eventTemplate: this.monthEventTemplate(), dayTemplate: this.dayTemplate()}
            ],
            editable: false,
            selectable: false,
            footer: false,
            change: (e) => {  
                e.preventDefault();                                 
            },
            navigate: (e) => {
                e.preventDefault();
            },
            resize: (e) => {
                //console.log('resize');
            },
            dataBound: () => {
                scheduler.unbind('focus');
                scheduler.find(".k-scheduler-monthview .k-scheduler-table th").each(function (i) { $(this).html($(this).html().substr(0, 3)) });                
                scheduler.find(".k-more-events").each(function (i, value) {
                    if ($(value).width() > 10) {
                        $(value).html("+ Show More")
                    }
                    else {
                        $(value).css('display', 'none');
                    }
                });                                           
                scheduler.find(".k-scheduler-monthview .k-scheduler-table td").unbind().on("click", (e) => {                    
                    var element = $(e.target);
                    //var date = dateFormatter.DateFormatter.Format(new Date($(e.target).find(".day").data('date')), "MM-DD-YYYY");
                    var elemDate = element.find(".day").data('date');
                    if (elemDate == undefined) {
                        elemDate = element.data('date');
                    }
                    var date = new Date(elemDate);           
                    this.changeDate(date);                    
                    
                    e.stopPropagation();
                });

                $(".k-scheduler-weekview .k-scheduler-table td").unbind().on("click", (e) => {
                    var date = new Date($(e.target).data('date'));
                    //var date = dateFormatter.DateFormatter.Format(new Date($(e.target).find(".day").data('date')), "MM-DD-YYYY");
                    this.changeDate(date);
                    
                    e.stopPropagation();
                });

                scheduler.find(".k-event").unbind().on("click", (e) => {

                    var ev = $(e.target);
                    var elemDate = ev.data('date');
                    if (elemDate == undefined) {
                        ev = ev.parents('.event');
                    }
                    var date = new Date(ev.data('date'));
                    if (this.state.activeView == "day") {
                        this.changeDate(this.state.activeDate, ev.data('id'));
                    } else {
                        this.changeDate(date, ev.data('id'));
                    }                    
                    
                    e.stopPropagation();
                });

                scheduler.find(".k-more-events").unbind().on("click", (e) => {
                    var element = $(ReactDOM.findDOMNode(this));
                    var scheduler = element.find("#Scheduler").data('kendoScheduler');
                    var slot = scheduler.slotByElement(e.currentTarget);
                    
                    this.changeDate(slot.startDate);
                    this.toggleCalendarView('day');

                    e.stopPropagation();
                })

                if(!this.state.loaded){                    
                    this.selectDate(this.state.activeDate);                    
                    this.getEvents(this.state.activeDate);                    
                    this.setState({loaded:true}, () => {
                        this.hideEmptyRows();
                    });                    
                    
                    var monthCal = scheduler.find('#MonthCalendar').kendoDatePicker({
                        value: this.state.activeDate,     
                        footer: "<div></div>",               
                        change: function(e){    
                            var date = e.sender.value();                            
                            this.changeDate(date);
                        }.bind(this)
                    });
                }                    
            },
            dataSource: {
                batch: true,
                transport: {
                    read: function(options) {                                            
                        // Run on first load or when we tell it to
                        if(!this.state.loaded || (typeof options.data.action != "undefined" && options.data.action == "update")){
                            $.ajax({
                                url: this.props.endpoint + "/geteventsformonth?month=" + (this.state.activeDate.getMonth() + 1) + "&year=" + this.state.activeDate.getFullYear(),
                                dataType: "json", // "jsonp" is required for cross-domain requests; use "json" for same-domain requests
                                success: (result) => {
                                    var allEvents = result.events.filter((ev) => {
                                        if (ev.IsCancelled) {
                                            return false;
                                        }

                                        ev.locations = this.getLocations(ev);

                                        if (this.state.filter.eventType != "All") {
                                            if (ev.eventType != this.state.filter.eventType) return false;
                                        }

                                        if (this.state.filter.eventFormat != "All") {
                                            if (ev.eventFormat != this.state.filter.eventFormat) return false;
                                        }

                                        if (this.state.filter.eventLocation != "All") {
                                            var matchedLocation = false;

                                            if (ev.hostLocation == this.state.filter.eventLocation) {
                                                matchedLocation = true;
                                            } else if (ev.eventLocations != null) {
                                                for (var i = 0; i < ev.EventLocations.length; i++) {
                                                    if (ev.eventLocations[i].Location == this.state.filter.eventLocation) {
                                                        matchedLocation = true;
                                                        break;
                                                    }
                                                }
                                            }

                                            if (!matchedLocation) return false;
                                        }
                                        return true;
                                        
                                    });
                                    var registrations = result.registrations;
 
                                    for (var i = 0; i < registrations.length; i++) {
                                        allEvents.filter((ev) => {
                                            return (ev.contentLink == registrations[i].formIdentifier.hostPageId);
                                        }).map((ev) => {
                                            ev.isRegistered = true;
                                            return ev;
                                        });
                                    }

                                    if (this.state.filter.myEvents) {
                                        allEvents = allEvents.filter((ev) => {
                                            return ev.isRegistered;
                                        });
                                    }
                                    // notify the data source that the request succeeded                                    
                                    options.success(allEvents);
                                },
                                error: function(result) {
                                    // notify the data source that the request failed
                                    options.error(result);
                                }
                            });
                        } else {
                            options.error("Not loaded");
                        }                       
                    }.bind(this),
                    parameterMap: function(options, operation){
                        if(operation !== "read" && options.models) {
                            return {models: JSON.stringify(options.models)};
                        }
                    }
                },
                schema: {
                    model: {
                        id: "ID",
                        fields: {
                            ID: { type: "number", field: "contentLink" },
                            title: { field: "name", defaultValue: "No title", validation: { required: true } },                            
                            link: { type: "string", field: "linkURL" },
                            start: { type: "date", field: "startDate" },
                            end: { type: "date", field: "endDate" },                            
                            eventType: { type: "string", field: "eventType" },
                            eventFormat: { type: "string", field: "eventFormat" },
                            form: { type: "object", field: "registrationFormIdentifier" },
                            hostLocation: { type: "string", field: "hostLocation"},
                            hostRoom: { type: "string", field: "hostRoom" },
                            locations: { type: "array", field: "locations" },
                            registrationOpenDate: { type: "date", field: "registrationOpenDate" },
                            registrationCloseDate: { type: "date", field: "registrationCloseDate" },
                            isCancelled: {type: "boolean", field: "eventCanceled" },
                            isRegistered: { type: "boolean", field: "isRegistered" },
                            allowRegistration: { type: "boolean", field: "allowRegistration" },
                            registrationStatus: { type: "string", field: "registrationStatus" },
                            details: { type: "string", field: "eventDetails" },
                            pageGuid: { type: "string", field: "contentGuid" },
                            entities: { type: "object", field:"entityReferenceList"}
                        }
                    }
                }
            },            
            resources: [
                {
                    field: "eventType",
                    dataTextField: "text",
                    dataValueField: "value",
                    dataColorField: "description",
                    dataSource: this.getEventTypesDS()
                },
                {
                    field: "eventFormat",
                    dataTextField: "text",
                    dataValueField: "value",
                    dataSource: this.getEventFormatsDS()
                }
            ]                        
        });
    }

    public updateFilter(filter: IFilter) {        
        this.setState({filter: filter}, () => {                        
            var element = $(ReactDOM.findDOMNode(this));
            var scheduler = element.find("#Scheduler").data('kendoScheduler');    
            scheduler.dataSource.read({action:'update'});
        });
    }

    private getEvents(date: Date){        
        var element = $(ReactDOM.findDOMNode(this));
        var scheduler = element.find("#Scheduler").data('kendoScheduler');

        var moment = Moment(date);
        var start = moment.startOf('day').toDate();
        var end = moment.add(1, 'day').startOf('day').toDate();

        var events = scheduler.occurrencesInRange(start, end);
        
        this.setState({activeEvents: events});        

        return events;
    }

    public changeDate(date, event?) {        
        var element = $(ReactDOM.findDOMNode(this));
        var scheduler = element.find("#Scheduler").data('kendoScheduler');
                    
        // Reload data when month has changed
        if(this.state.activeDate.getMonth() != date.getMonth()){
            this.setState({activeDate: new Date(date), activeEvent: null, activeEvents: []}, () => {
                // Fetch month data
                scheduler.dataSource.read({action:'update'}).then((results) => {            
                    scheduler.select({});
                    scheduler.date(date);                    

                    var events = this.getEvents(date);
                    this.selectDate(date);
                    this.hideEmptyRows();
                    // Set active event
                    if(typeof event != "undefined" && event != null){
                        this.setState({activeEvent: event});
                    } else if(events.length > 0){            
                        this.setState({activeEvent: events[0].id});            
                    }  else {
                        this.setState({activeEvent: null});
                    }    
                });
            });            
        } else {        
            this.setState({activeDate: new Date(date), activeEvent: null, activeEvents: []}, () => {
                scheduler.select({});
                scheduler.date(date);
                
                var events = this.getEvents(date);
                this.selectDate(date);
                this.hideEmptyRows();

                // Set active event
                if (typeof event != "undefined" && event != null) {
                    this.setState({activeEvent: event});
                } else if (events.length > 0) {            
                    this.setState({activeEvent: events[0].id});            
                } else {
                    this.setState({activeEvent: null});
                } 
            });                               
        }        
    }    

    public navMonth(nav: string){        
        var element = $(ReactDOM.findDOMNode(this));    
        var scheduler = element.find("#Scheduler").data('kendoScheduler');        
        var date = Moment(new Date(this.state.activeDate));
        
        switch(nav){
            case 'prev':
                var newDate = date.subtract(1, 'month').toDate();
                this.changeDate(newDate);                                
            break;
            case 'next':
                var newDate = date.add(1, 'month').toDate();                             
                this.changeDate(newDate);
            break;
        } 

        this.hideEmptyRows();
    }

    public navWeek(nav: string){
        var element = $(ReactDOM.findDOMNode(this));    
        var scheduler = element.find("#Scheduler").data('kendoScheduler');        
        var date = Moment(new Date(this.state.activeDate));
        
        switch(nav){
            case 'prev':
                var newDate = date.subtract(1, 'week').toDate();
                this.changeDate(newDate);                                
            break;
            case 'next':
                var newDate = date.add(1, 'week').toDate();                            
                this.changeDate(newDate);
            break;
        }
    }

    public navDay(nav: string){
        var element = $(ReactDOM.findDOMNode(this));    
        var scheduler = element.find("#Scheduler").data('kendoScheduler');        
        var date = Moment(new Date(this.state.activeDate));
        
        switch(nav){
            case 'prev':
                var newDate = date.subtract(1, 'day').toDate();
                this.changeDate(newDate);                                
            break;
            case 'next':
                var newDate = date.add(1, 'day').toDate();                            
                this.changeDate(newDate);
            break;
        }
    }

    private hideEmptyRows() {
        var element = $(ReactDOM.findDOMNode(this));
        var scheduler = element.find("#Scheduler").data('kendoScheduler');

        var rows = element.find(".k-scheduler-table tr[role='row']");
        var removedRows = 0;

        for (var i = 0; i < rows.length; i++) {
            var validDays = $(rows[i]).find('td').filter(function () {
                if (!$(this).hasClass('k-other-month')) {
                    return true;
                }
            });
            if (validDays.length == 0) {
                $(rows[i]).hide();
                removedRows++;
            }
        }

        var table = element.find('.k-scheduler-monthview .k-scheduler-content .k-scheduler-table');
        var minimumHeight = 640;
        var tableHeight = (table.height() - (60 * removedRows));
        tableHeight = (tableHeight > minimumHeight ? tableHeight : minimumHeight);
        table.height(tableHeight + "px");
        scheduler.refresh();
    }

    public pickDay() {
        var element = $(ReactDOM.findDOMNode(this));        
        var calendar = element.find('#MonthCalendar').data('kendoDatePicker');
        calendar.value(this.state.activeDate);        
        calendar.open();
    }

    private selectDate(date) {
        var element = $(ReactDOM.findDOMNode(this));
        element.find(".k-state-selected").removeClass('k-state-selected');
        element.find(".day[data-date='" + Moment(date).startOf('day').toISOString() + "']").parent().addClass("k-state-selected");
    }

    public toggleCalendarView(view: string): void{
        var element = $(ReactDOM.findDOMNode(this));
        var scheduler = element.find("#Scheduler").data('kendoScheduler');

        if (scheduler) {
            scheduler.view(view);
        }
        
        this.setState({activeView: view});

        if(view == "month"){            
            this.hideEmptyRows();
        }
    }

    public getLocations(ev) {
        var locations = new Array();

        if(ev.locations != null){
            for(var i=0; i<ev.locations.length; i++){
                locations.push(ev.locations[i].Location + " - " + ev.locations[i].Room);
            }        
        }

        return locations;   
    }

    // Get text for configurable choice value
    private mapConfigurableChoice(fieldType, value){
        var choices = this.state[fieldType]; //scheduler.resources;        

        for(var i=0; i<choices.length; i++){
            if(choices[i].Value == value){
                return choices[i].Text;
            }
        }
        
        // return orignal value if no matches found
        return value;
    }

    // Kendo Datasources
    private getConfigurableChoices(){
        return $.ajax({
            url: "/api/ConfigurableChoices/GetEventChoices/",
            dataType: "json"
        });
    }

    private getEventTypesDS() {
        return {
            data: this.state.eventTypes,
            schema: {
                model: {
                    id: "ID",
                    fields: {
                        Text: { type: "string", field: "Text" },
                        Value: { type: "string", field: "Value" },
                        Description: { type: "string", field: "Description" }
                    }
                }
            }
        };
    }

    private getEventFormatsDS() { 
        return {
            data: this.state.eventFormats,
            schema: {
                model: {
                    id: "ID",
                    fields: {
                        Text: { type: "string", field: "Text" },
                        Value: { type: "string", field: "Value" },
                        Description: { type: "string", field: "Description" }
                    }
                }
            }
        };
    }

    // Kendo Overrides
    //Override kendo function for deciding events width in day/week view
    //private overrideMultiDayView_arrangeColumns(){
    //    kendo.ui.MultiDayView.fn._arrangeColumns = function (element, top, height, slotRange) {
    //        var startSlot = slotRange.start;

    //        element = { element: element, slotIndex: startSlot.index, start: top, end: top + height };

    //        var columns,
    //            slotWidth = startSlot.clientWidth,
    //            eventRightOffset = 0,
    //            columnEvents,
    //            eventElements = slotRange.events(),
    //            slotEvents = kendo.ui.Scheduler.fn.view().collidingEvents(eventElements, element.start, element.end);

    //        slotRange.addEvent(element);

    //        slotEvents.push(element);

    //        columns = kendo.ui.Scheduler.fn.view().createColumns(slotEvents);

    //        var columnWidth = (slotWidth - eventRightOffset) / columns.length;

    //        for (var idx = 0, length = columns.length; idx < length; idx++) {
    //            columnEvents = columns[idx].events;

    //            for (var j = 0, eventLength = columnEvents.length; j < eventLength; j++) {
    //                columnEvents[j].element[0].style.width = columnWidth - 2 + "px";
    //                columnEvents[j].element[0].style.left = (this._isRtl ? this._scrollbarOffset(eventRightOffset) : 0) + startSlot.offsetLeft + idx * columnWidth + 2 + "px";
    //            }
    //        }
    //    };
    //}

    ////Override kendo function for positioning events in mobile to use desktop positioning
    //private overrideMonthView_positionMobileEvent(){
    //    kendo.ui.Scheduler.fn.view("Month")._positionMobileEvent = function(slotRange, element, group) {
    //        var eventHeight = this.options.eventHeight;
    //        var startSlot = slotRange.start;

    //        if (slotRange.start.offsetLeft > slotRange.end.offsetLeft) {
    //            startSlot = slotRange.end;
    //        }

    //        var startIndex = slotRange.start.index;
    //        var endIndex = slotRange.end.index;
    //        var eventCount = startSlot.eventCount;
    //        var events = kendo.SchedulerView.collidingEvents(slotRange.events(), startIndex, endIndex);
    //        var rightOffset = startIndex !== endIndex ? 5 : 4;

    //        events.push({element: element, start: startIndex, end: endIndex });

    //        var rows = kendo.ui.SchedulerView.createRows(events);

    //        for (var idx = 0, length = Math.min(rows.length, eventCount); idx < length; idx++) {
    //            var rowEvents = rows[idx].events;
    //            var eventTop = startSlot.offsetTop + startSlot.firstChildHeight + idx * eventHeight + 3 * idx + "px";

    //            for (var j = 0, eventLength = rowEvents.length; j < eventLength; j++) {
    //                rowEvents[j].element[0].style.top = eventTop;
    //            }
    //        }

    //        if (rows.length > eventCount) {
    //            for (var slotIndex = startIndex; slotIndex <= endIndex; slotIndex++) {
    //                var collection = slotRange.collection;

    //                var slot = collection.at(slotIndex);

    //                if (slot.more) {
    //                    return;
    //                }

    //                var MORE_BUTTON_TEMPLATE = kendo.template(
    //                    '<div style="width:#=width#px;left:#=left#px;top:#=top#px" class="k-more-events k-button"><span>...</span></div>'
    //                );

    //                slot.more = $(MORE_BUTTON_TEMPLATE({
    //                    ns: kendo.ns,
    //                    start: slotIndex,
    //                    end: slotIndex,
    //                    width: slot.clientWidth - 2,
    //                    left: slot.offsetLeft + 2,
    //                    top: slot.offsetTop + slot.firstChildHeight + eventCount * eventHeight + 3 * eventCount
    //                }));

    //                this.content[0].appendChild(slot.more[0]);
    //            }
    //        } else {
    //            slotRange.addEvent({element: element, start: startIndex, end: endIndex, groupIndex: startSlot.groupIndex });

    //            element[0].style.width = slotRange.innerWidth() - rightOffset + "px";
    //            element[0].style.left = startSlot.offsetLeft + 2 + "px";
    //            element[0].style.height = eventHeight + "px";

    //            group._continuousEvents.push({
    //                element: element,
    //                uid: element.attr(kendo.attr("uid")),
    //                start: slotRange.start,
    //                end: slotRange.end
    //            });

    //            element.appendTo(this.content);
    //        }
    //    }
    //}

    // Kendo Templates   
    private dayTemplate(){
        var dayTemplate = 
            `<span class='day' data-date="#:date.toISOString()#">#=kendo.toString(date, " d")#</span>`;
        
        return dayTemplate;
    }

    private monthEventTemplate() {
        var monthEventTemplate = 
        `#
            var multi = false;
            var color = "";
            var isStruck = "none";

            if(resources.length > 0) { color = resources[0].color }

            if(start.getDate() != end.getDate()){
                multi = true;
            }
        #

        #
            if(multi){
                # <div class="event multi-day" style="border-left: 5px solid #:color#;" data-id="#:ID#" data-date="#:start.toISOString()#"> #
            } else {
                # <div class="event" style="border-left: 5px solid #:color#;" data-id="#:ID#" data-date="#:start.toISOString()#"> #
            }
        #

        #
         if(isCancelled){
             #<span style="color:red; text-decoration-line:none !important;">(canceled)</span>#
             }
         
            if(isCancelled){
              isStruck = "line-through";
            } else{
              isStruck = "none";
            }
        #
         <div class="event-type" style="background-color: #:color#;" data-id="#:ID#" data-date="#:start.toISOString()#"></div> <span style="text-decoration-line:#:isStruck#;">#:title#</span>
            #
                if(multi){ # <div class="multi-day-bg" style="background-color: #:color#"> </div> # }
            #   

        </div>`;
    
    return monthEventTemplate;
    }
    
    private weekEventTemplate(){
        var weekEventTemplate = 
            `#
                var multi = false;
                var color = "";
                if(resources.length > 0) { color = resources[0].color;}  
                
                var isStruck = "none";
                var isDisplayed = "none";
                if(isCancelled){
                      isStruck = "line-through";
                       isDisplayed = "block";
                       }        
            #

            <div class="event" data-id="#:id#" data-date="#:start.toISOString()#" style="border-top: 6px solid #:color#">
                <div class="event-type" style="background-color: #:color#"></div>             
                <div class="event-title">
                    <span style="color:red; text-decoration-line:none !important; display: #:isDisplayed#; ">(canceled)</span>
                    <span style="text-decoration-line:#:isStruck#;">#:title#</span>
                </div>
            </div>`;
        
        return weekEventTemplate;
    }

    private weekAllDayEventTemplate(){
        var weekAllDayEventTemplate = 
            `#
                var color = "";
                if(resources.length > 0) { color = resources[0].color; }     
                
                var isStruck = "none";
                var isDisplayed = "none";
                if(isCancelled){
                      isStruck = "line-through";
                       isDisplayed = "block";
                       }
            #

            <div class="event" data-id="#:id#" data-date="#:start.toISOString()#" style="border-left: 6px solid #:color#">
                <div class="event-type" style="background-color: #:color#"></div> 
                <div class="event-title">
                    <span style="color:red; text-decoration-line:none !important; display: #:isDisplayed#; ">(canceled)</span>
                    <span style="text-decoration-line:#:isStruck#;">#:title#</span>
                </div>
            </div>`;
        
        return weekAllDayEventTemplate;
    }

    private dayEventTemplate(){
        var dayEventTemplate = 
            `#
                var color = "";
                if(resources.length > 0) { color = resources[0].color; }  
                
                var isStruck = "none";
                var isDisplayed = "none";
                if(isCancelled){
                      isStruck = "line-through";
                       isDisplayed = "block";
                       }
            #
            
            <div class="event" data-id="#:id#" data-date="#:start.toISOString()#">
                <div class="event-type" style="background-color: #:color#"></div> 
                <div class="day-event-bg" style="background-color: #:color#"> </div>                                    
                <div class="event-title">
                    <span style="margin-top: 6px; color:red; text-decoration-line:none !important; display: #:isDisplayed#; ">(canceled)</span>
                    <span style="text-decoration-line:#:isStruck#;">#:title#</span>
                </div>
             </div>`;

        return dayEventTemplate;
    }

    private weekDateHeaderTemplate(){
        var dateHeaderTemplate = 
            `#=kendo.toString(date, 'ddd')#
	         <strong class='noWrap'>#=kendo.toString(date, 'dd')#</strong>`;

        return dateHeaderTemplate;
    }

    private dayDateHeaderTemplate(){
        var dateHeaderTemplate = `&nbsp;`;

        return dateHeaderTemplate;
    }

    private slotTemplate(){
        var slotTemplate = `<div class="day-slot #=kendo.toString(date, 'ddd')#" data-date="#=date.toISOString()#">&nbsp;</div>`;
        
        return slotTemplate;        
    }  

    public recievedHashUrl() {

        var hashes = window.location.hash.split("/");

        for (var i = 0; i < hashes.length; i++) {
            if ((hashes[i] == "month" || hashes[i] == "week" || hashes[i] == "day") && hashes[i].length > 0) {
                this.toggleCalendarView(hashes[i]);
            }
            if (Moment(hashes[i], "MM-DD-YYYY", true).isValid() && hashes[i].length > 0) {
                this.changeDate(Moment(hashes[i], 'MM-DD-YYYY').toDate());
            }
        }
    }
    public today() {
        var today = Moment(new Date()).toDate();
        this.changeDate(today);
    }
    public generateHashUrl() {

        var url = "#/" + this.state.activeView + "/" + dateFormatter.DateFormatter.Format(this.state.activeDate, "MM-DD-YYYY");
        window.location.hash = url;

        this.setState({ hashUrl: url });
    }

    public render() {
        return (
            <div className={dateFormatter.DateFormatter.Format(this.state.activeDate, "ddd")}>                
                <div>

                    <div>
                        <EventFilters EventTypes={this.state.eventTypes} EventFormats={this.state.eventFormats} EventLocations={this.state.eventLocations} UpdateFilter={this.updateFilter} Filter={this.state.filter}/>
                    </div>
                </div>
                <div>                                 
                    <div className="head margin-top-10">
                        <div className="tab-content-block">
                            <ul className="nav nav-tabs">                                
                                <li className="nav-item">
                                    <a className={this.state.activeView == "day" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="javascript:void(0)" onClick={() => this.toggleCalendarView("day")}>Day</a>
                                </li>
                                {/*<li className="nav-item">*/}
                                {/*    <a className={this.state.activeView == "week" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="javascript:void(0)" onClick={() => this.toggleCalendarView("week")}>7 Day Week</a>*/}
                                {/*</li>   */}
                                <li className="nav-item">
                                    <a className={this.state.activeView == "workWeek" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="javascript:void(0)" onClick={() => this.toggleCalendarView("workWeek")}>Week</a>
                                </li>
                                <li className="nav-item">
                                    <a className={this.state.activeView == "month" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="javascript:void(0)" onClick={() => this.toggleCalendarView("month")}>Month</a>
                                </li>
                            </ul>                               
                        </div>                                  
                    </div>                                            
                    <div className="calendar-container row">
                        <div className="col-12 col-md-7">
                            <div id="Scheduler">
                                <div className='padding-top-30 visibility-hidden month-calendar'><span id="MonthCalendar"></span></div>
                                <div className="head">
                                    <div className={this.state.activeView == "month" ? "month-view" : "hidden"}> 
                                        <div className="date noPadding">
                                            <button className="monthAndYearText btn btn-link" onClick={() => { this.pickDay(); }}>{this.state.activeDate.getMonth() == 4 ? dateFormatter.DateFormatter.Format(this.state.activeDate, 'MMM yyyy') : dateFormatter.DateFormatter.Format(this.state.activeDate, 'MMMM yyyy')}</button> 
                                            <span className="prevMonth text-left" onClick={() => { this.navMonth('prev'); }}><button className="blue btn btn-link" ><i className="fa fa-chevron-left"></i></button></span>
                                            <span className="nextMonth text-right" onClick={() => { this.navMonth('next'); }}> <button className="blue btn btn-link"><i className="fa fa-chevron-right"></i></button></span>                                       
                                            <button className="btn btn-default todayButton" onClick={() => { this.today(); }}>Today</button>
                                        </div>
                                    </div>

                                    <div className={(this.state.activeView == "week" || this.state.activeView == "workWeek") ? "week-view" : "hidden"}>                                
                                        <div className="date noPadding">

                                            <button className="monthAndYearText btn btn-link" onClick={() => { this.pickDay(); }}>
                                                {dateFormatter.DateFormatter.Format(Moment(this.state.activeDate).startOf('week').toISOString(), "M") == "5" ? dateFormatter.DateFormatter.Format(Moment(this.state.activeDate).startOf('week').toISOString(), "MMM D, Y") : dateFormatter.DateFormatter.Format(Moment(this.state.activeDate).startOf('week').toISOString(), "MMMM D, Y")}
                                                &nbsp;-&nbsp;
                                                {dateFormatter.DateFormatter.Format(Moment(this.state.activeDate).endOf('week').toISOString(), "M") == "5" ? dateFormatter.DateFormatter.Format(Moment(this.state.activeDate).endOf('week').toISOString(), "MMM D, Y") : dateFormatter.DateFormatter.Format(Moment(this.state.activeDate).endOf('week').toISOString(), "MMMM D, Y")}
                                            </button>
                                            <span className="prevMonth text-left" onClick={() => { this.navWeek('prev'); }}><button className="blue btn btn-link" ><i className="fa fa-chevron-left"></i></button></span>
                                            <span className="nextMonth text-right" onClick={() => { this.navWeek('next'); }}> <button className="blue btn btn-link"><i className="fa fa-chevron-right"></i></button></span>

                                            <button className="btn btn-default todayButton" onClick={() => { this.today(); }}>Today</button>
                                        </div>                                
                                    </div>

                                    <div className={this.state.activeView == "day" ? "day-view" : "hidden"}>                                
                                        <div className="date noPadding">     
                                            
                                            <button className="monthAndYearText btn btn-link" onClick={() => {this.pickDay();}}>
                                                {this.state.activeDate.getMonth() == 4 ? dateFormatter.DateFormatter.Format(Moment(this.state.activeDate).toISOString(), "ddd., MMM D, Y") : dateFormatter.DateFormatter.Format(Moment(this.state.activeDate).toISOString(), "ddd., MMM. D, Y")}                                            
                                            </button>

                                            <span className="prevDay"><button className="blue btn btn-link" onClick={() => { this.navDay('prev'); }}><i className="fa fa-chevron-left"></i></button></span>
                                            <span className="nextDay"><button className="blue btn btn-link" onClick={() => { this.navDay('next'); }}><i className="fa fa-chevron-right"></i></button></span>
                                            <button className="btn btn-default todayButton" onClick={() => { this.today(); }}>Today</button>
                                        </div>                                
                                    </div>
                                    
                                </div>
                            </div>                        
                        </div>

                        <div className="col-12 col-md-5 event-details-container">
                            <EventDetails date={this.state.activeDate} events={this.state.activeEvents} activeEvent={this.state.activeEvent} hash={this.state.hashUrl}/>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }    
}