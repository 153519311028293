// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import NotificationTopic from "./NotificationTopic";
import {INotificationTopic} from "./NotificationTopic";
interface INotification { notificationTopic: string, subject: string, body: string, publishDate: string, permanentLinkUrl: string }
interface INotificationGroup { topic: string, notifications: Array<INotification> }
interface INotificationListProps { topic: string, siteURL: string, overviewAdditionalText: string, callToActionText: string }

interface State {
	topics: Array<INotificationTopic>,
    groupedNotifications: Array<INotificationGroup>,
    topic: string,
    take: number,
    loading: boolean
}

export class NotificationList extends React.Component<INotificationListProps, State>{
    constructor(props){
        super(props);
        this.state = { topics: [], groupedNotifications: [], topic: "", take: 0, loading: true};
    }

    public componentDidMount() {
	    this.getTopicChoices();
        if(this.props.topic != "All"){
            this.setState({topic: this.props.topic}, function(){this.getNotifications()});
        } else { 
            this.getNotifications();
        }
    }

	private getTopicChoices() {
		return $.getJSON("/api/topicnotifications/GetTopicChoices",
			function (data) {
				this.setState({ topics: data, topic:data[0].value });
			}.bind(this));
    }

    private getNotifications(){
        return $.getJSON("/api/topicnotifications/GetGroupedNotifications?topic=" + this.state.topic + "&take=" + this.state.take, 
            function (data) {
                for (var i = 0; i < data.length; i++) {
                    for (var j = 0; j < data[i].notifications.length; j++) {
                        data[i].notifications[j].body = this.formatBody(data[i].notifications[j].body, data[i].notifications[j].permanentLinkUrl);
                    }
                }
                this.setState({ groupedNotifications: data, loading: false });
            }.bind(this));
    }
    public formatBody(body, pageLink) {
        if (body) {
            body = body + '</br>';
            var bodyText = body + '<a target="_blank" class="fs14" href="' + pageLink + '"> Go to Page Details</a>';
            return bodyText;
        } else {
            return '<a target="_blank" class="fs14" href="' + pageLink + '"> Go to Page Details</a>';
        }
            
        //return bodyText;
    }
    private getNotificationsForTopic(topic): Array<INotification> {
        for (var i = 0; i < this.state.groupedNotifications.length; i++) {
            if (this.state.groupedNotifications[i].topic == topic) {
                return this.state.groupedNotifications[i].notifications; 
            }
        }
    }

    private updateNotifications(topic: string, take: number): void{
        var currentState = this.state;
         
        var temploading = true;
        var temptopic = this.state.topic;
        if(topic != currentState.topic){
            temptopic = topic;
        }
        var temptake = this.state.take;
        if(take != currentState.take){
            temptake = take;
        }

        this.setState({ loading: temploading, topic: temptopic, take: temptake }, function () {
            this.getNotifications();
        })             

    }
    private updateTopic(topic: string): void {
        this.setState({ topic: topic })
    }

    public render() {
        if (this.state.loading) {
            return (<div className="processingIndicator row margin-top-30"><i className="fa fa-spin fa-refresh"></i></div>);
        }

        else {
            let topicNotifications = this.state.groupedNotifications.findIndex(obj => {
                return obj.topic === this.state.topic;
            });
            let topicData = this.state.topics.find(obj => {
                return obj.value === this.state.topic;
            });
            return (
                <div>
                    <div className="tab-content-block margin-bottom-20">
                        <ul className="nav nav-tabs">
                            {
                                this.state.topics.map((topic, i) => {
                                    return (
                                        <li key={i} className="nav-item">
                                            <a className={(this.state.topic == topic.value ? "nav-link active" : "nav-link")} data-bs-toggle="tab" onClick={() => { this.updateTopic(topic.value); }}>{topic.text}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="notifications-categories">
                        <NotificationTopic topic={topicData} notifications={this.state.groupedNotifications[topicNotifications].notifications} siteURL={this.props.siteURL} overviewAdditionalText={this.props.overviewAdditionalText} callToActionText={this.props.callToActionText } />
                    </div>
                </div>
            )
        }
    }
}