export class Awesome {

    public static getDocumentIcon(doc?: string, contentType?: string, style?: string) {
        var faClass = "fa ";
        var fileType = "";
        
        if(fileType == "" && contentType != null){
            fileType = this.getFileType(contentType);
        }

        if (fileType == "" && doc != null && doc.indexOf(".") != -1) {
            fileType = this.getDocType(doc);
        } else if (fileType == "" && doc != null) {
            fileType = this.getDocType("." + doc);
        }

        switch (fileType) {
             case "pdf":
                faClass += " fa-file-pdf-o";
                break;
            case "code":            
                faClass += " fa-file-code-o";
                break;
            case "excel":
                faClass += " fa-file-excel-o";
                break;
            case "word":
                faClass += " fa-file-word-o";
                break;           
            case "powerpoint":            
                faClass += " fa-file-powerpoint-o";
                break;
            case "archive":
                faClass += " fa-file-archive-o";
                break;
            case "image":
                faClass += " fa-file-image-o";
                break;
            case "video":
                faClass += " fa-file-video-o";
                break;
            case "text":
                faClass += " fa-file-text-o";
                break;                        
            default:
                faClass += "fa-file-o";
                break;
        }

        if (style != null) {
            faClass += " " + style;
        }

        return `<i class="` + faClass + `" />`;
    }
    public static getUnformattedDocumentIcon(doc?: string, contentType?: string, style?: string) {
        var faClass = "fa ";
        var fileType = "";

        if (fileType == "" && contentType != null) {
            fileType = this.getFileType(contentType);
        }

        if (fileType == "" && doc != null && doc.indexOf(".") != -1) {
            fileType = this.getDocType(doc);
        } else if (fileType == "" && doc != null) {
            fileType = this.getDocType("." + doc);
        }

        switch (fileType) {
            case "pdf":
                faClass += " fa-file-pdf-o";
                break;
            case "code":
                faClass += " fa-file-code-o";
                break;
            case "excel":
                faClass += " fa-file-excel-o";
                break;
            case "word":
                faClass += " fa-file-word-o";
                break;
            case "powerpoint":
                faClass += " fa-file-powerpoint-o";
                break;
            case "archive":
                faClass += " fa-file-archive-o";
                break;
            case "image":
                faClass += " fa-file-image-o";
                break;
            case "video":
                faClass += " fa-file-video-o";
                break;
            case "text":
                faClass += " fa-file-text-o";
                break;
            default:
                faClass += "fa-file-o";
                break;
        }

        if (style != null) {
            faClass += " " + style;
        }

        return faClass;
    }

    private static getFileType(contentType: string) {
        
        switch(contentType){
            case "application/pdf":
                return "pdf";
            case "application/xml":            
                return "code";            
            case "text/csv":
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            case "application/vnd.ms-excel.sheet.macroEnabled.12":
            case "application/vnd.ms-excel":
                return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            case "application/vnd.ms-word":
                return "word";            
            case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            case "application/vnd.ms-powerpoint":
                return "powerpoint";
            case "application/zip":
                return "archive";
            case "image/png":
            case "image/jpeg":
            case "image/gif":
                return "image";            
            case "video/mpeg":
            case "video/mp4":
            case "video/avi":
                return "video";
            case "text/plain":
                return "text";
            default: 
                return "";
        }
    }

    private static getDocType(doc: string){
        var ext = doc.substr(doc.lastIndexOf("."));

        switch (ext) {
            case ".pdf":
                return "pdf";
            case ".xml":
                return "code";
            case ".xls":
            case ".xlsx":
            case ".csv":
                return "excel";
            case ".doc":
            case ".docx":
                return "word";
            case ".ppt":
            case ".pptx":
                return "powerpoint";
            case ".rar":
            case ".zip":
            case ".7z":
                return "archive";
            case ".png":
            case ".jpg":
            case ".gif":
                return "image";
            case ".avi":
            case ".mov":
            case ".mp4":
                return "video";
            case ".txt":
                return "text";
            default:
                return "";
        }
    }
}
