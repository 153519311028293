import React from 'react';
import SearchResultPane from './SearchResultPane';
import {SearchResultItem} from './SearchResultPaneItem';
import * as FindResultsBase from '../Find/FindResultsBase';
import SearchFacetMenu from './SearchFacetMenu';
import { ISearchFacetItem, SearchFacet } from './SearchFacetMenuSection';
import SearchFacetItemTags from './SearchFacetItemTags';
import $ from 'jquery';
import * as dateFormatter from '../../dateFormatter';
declare var ga: any;

interface ISearchProps extends FindResultsBase.IResultsProps<SearchResultItem> {
	query: string;
}

interface ISearchState extends FindResultsBase.IResultsState<SearchResultItem> {
    facets: Array<SearchFacet>;
    pageSize: number;
    page: number;
    sort: string;
    sortDirection: string;
    filters: string; 
    prevQuery: string;
    prevFilters: string;
}

const AMPERSAND_PLACEHOLDER = "{AND}";
const defaultPageSize = 10;

export class Search extends FindResultsBase.FindResultsBase<SearchResultItem, ISearchProps, ISearchState>{
    //private SearchItemPanel:SearchItem;
    private queryTextBox:HTMLInputElement;

    private static hashToStateMap = [
        { hashParam: "q", stateParam: "query"},
        { hashParam: "t", stateParam: "pageSize"},
        { hashParam: "p", stateParam: "page"},
        { hashParam: "s", stateParam: "sort"},
        { hashParam: "sd", stateParam: "sortDirection"},
        { hashParam: "f", stateParam: "filters"},
    ];

    constructor(props){
        super(props);
        if (typeof window !== "undefined") {
            window.addEventListener("hashchange", this.handlePop.bind(this));
        }
        this.state = { pageSize: defaultPageSize, page: 0, facets: [], results: [], filters: null, prevQuery: null, prevFilters: null, totalAvailableResults: 0, query: "", sort: null, sortDirection:null, loading: true};        
    }

    constructResultItem() : SearchResultItem {
		return new SearchResultItem();
	}

    //componentWillMount() {
    //    addEventListener("hashchange", this.handlePop.bind(this));
    //  }
    
    //  componentWillUnmount() {
    //    removeEventListener("hashchange", this.handlePop.bind(this));
    //  }

      private _hashParams:any;
      get hashParams():any {
          if (!this._hashParams && location.hash && location.hash.length > 1) {
            this._hashParams = this.parseParams(location.hash.substr(1));
          } else if (!this._hashParams && this.props.query) {
            this._hashParams = {q : this.props.query };
          }

          return this._hashParams;
      }

      private oldURL:string;
      public handlePop(event:HashChangeEvent) {
        let oldURL = event.oldURL || this.oldURL;
        let newUrl = event.newURL || location.href;
        this.oldURL = location.href;

        if (oldURL != newUrl) {

            let gaParams = this.parseParams(location.hash.substring(1));

            if (gaParams) {

                if (gaParams['f']) {
                    gaParams['f'] = gaParams['f'].replace(/&/g, ' ');
                }

                let gaUrl = location.pathname + '?q=' + gaParams['q'] + '&f=' + gaParams['f'];

                ga('send', 'pageview', {
                    'page': gaUrl
                });           
            }
          
            this._hashParams = null;
        }
      }

    public componentDidMount() {
        super.componentDidMount();
        window.addEventListener("resize", this.updateView.bind(this));
        this.setStateFromHash(() => {
            if (!this.state.query && !this.props.query && this.state.loading) {
                this.setState({loading: false});
            }
        }); 
    }

    public componentDidUpdate(prevProps, prevState) {
        if ((this.state.query) && (this.state.query != prevState.query || this.state.page != prevState.page || this.state.pageSize != prevState.pageSize || this.state.sort != prevState.sort || this.state.sortDirection != prevState.sortDirection || this.state.filters != prevState.filters )) {
            this.ensureUpdatedHash();
            this.executeSearch();
		}
    }

    private resetSearchState(query:any = "") {
        if (query && this.state.query != query) {
            this.setState({query: query, page: 0, pageSize: defaultPageSize, sort: null, sortDirection: null, facets: [], results: [], filters: null });
        }
    }

    private ensureUpdatedHash() {
        let somethingChanged = false;

        if (!this.hashParams) {
            this._hashParams = {};
        }

        Search.hashToStateMap.map((hashToState, i) => {
            if (typeof(this.state[hashToState.stateParam]) !== 'undefined' && this.hashParams[hashToState.hashParam] !== this.state[hashToState.stateParam]) {
                this.hashParams[hashToState.hashParam] = this.state[hashToState.stateParam];
                somethingChanged = true;
            }
        });

        if (somethingChanged) {
            window.location.hash = $.param(this.hashParams);
        }
    }

    private setStateFromHash(callback:Function=null) {
        let somethingChanged = false;
        let stateSetting = {};

        if (this.hashParams) {
            Search.hashToStateMap.map((hashToState, i) => {
                if (typeof(this.hashParams[hashToState.hashParam]) !== 'undefined' && this.hashParams[hashToState.hashParam] !== this.state[hashToState.stateParam]) {
                    stateSetting[hashToState.stateParam] = this.hashParams[hashToState.hashParam];
                    somethingChanged = true;
                }
            });
        }

        if (somethingChanged) {
           this.setState(stateSetting, () => { 
               this.queryTextBox.value = this.state.query;
               if (callback) {
                   callback();
               }
           });
        } else if (callback) {
            callback();
        }
    }

    private parseParams(str) {
        if (str) {
            return str.split('&').reduce(function (params, param) {
                var paramSplit = param.split('=').map(function (value) {
                    return decodeURIComponent(value.replace('+', ' ')
                                                   .replace(AMPERSAND_PLACEHOLDER, '&'));
                });
                params[paramSplit[0]] = paramSplit[1];
                return params;
            }, {});
        } else {
            return null;
        }
    }

    private sanitizeParamsString(str) {
        let returnString = '';
        let insideBackets = false;
        for (let i = 0; i < str.length; i++)
        {
            if (str[i] === '[') { insideBackets = true; }
            if (str[i] === ']') { insideBackets = false; }
            returnString += (str[i] === '&' && insideBackets) ? AMPERSAND_PLACEHOLDER : str[i];
        }
        return returnString;
    }

    public executeSearch(query:any = this.state.query) {
        if (this.state.loading !== true) {  
            this.setState({ loading: true }, () => this.executeSearch(query));
        } else {
            const rootUrl = location.pathname.lastIndexOf("/") === (location.pathname.length-1) ? location.pathname : location.pathname + "/";
            const withFacets = (query != this.state.prevQuery || this.state.filters != this.state.prevFilters || !this.state.facets || !this.state.facets.length ) ? "WithFacets" : "";
            $.ajax({
                url: `${rootUrl}GetResults${withFacets}?take=${this.state.pageSize}&skip=${this.state.pageSize * this.state.page}&sort=${ this.state.sort }&sortDirection=${ this.state.sortDirection }&q=${ (query ) }&f=${encodeURIComponent(this.state.filters)}`,
                type: 'POST',
                //data: JSON.stringify(body),
                dataType: 'json',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                success: function (data) {
                    if (data.facets) {
                        this.setParsedFacets(data);
                    }

                    if (data.hits) {
                        this.setParsedResults(data);
                    }
                }.bind(this),
                error: function (data) {
                    console.error(arguments);
                }.bind(this)
            });
        }
    }

    private setParsedFacets(data: any) {
        if (data.facets) {
            var facets:Array<SearchFacet> = [];
            var parsedFilters = this.parseFilterString(this.state.filters);

            Object.getOwnPropertyNames(data.facets).forEach(facetName => {
                if (facetName === "BaseType") {
                    return;
                }
                let facet = new SearchFacet();

                facet.name = facetName;
                facet.title = facetName;
                facet.facetType = data.facets[facetName]["_type"];
                facet.facetItems = data.facets[facetName].terms || data.facets[facetName].entries;

                if (facetName === "Type" && data.facets["BaseType"] && data.facets["BaseType"].terms) {
                    // Hack to psuedo-combine these two facets into one, so we can group all page types, but split by mime type for documents
                    for (let i = 0; i < data.facets["BaseType"].terms.length; i++) {
                        if (data.facets["BaseType"].terms[i].term === "Page") {
                            facet.facetItems.push(data.facets["BaseType"].terms[i]);
                            break;
                        }
                    }
                }

                if (facet.facetItems && facet.facetItems.length) {
                    facet.facetItems.map((facetItem, facetItemIndex) => {
                        if (!facetItem.facet) {
                            facetItem.facet = facet; 
                        }
        
                        if (!facetItem.itemValue) {
                            if (facetItem.time) {
                                if (typeof (facetItem.time) === 'number') {
                                    facetItem.time += 18000000;
                                }
                                if (facet.name.lastIndexOf("Year", facet.name.length - "Year".length) !== -1) {
                                    facetItem.itemValue = dateFormatter.DateFormatter.Format(facetItem.time, "YYYY");
                                } else if (facet.name.lastIndexOf("Month", facet.name.length - "Month".length) !== -1) {
                                    facetItem.itemValue = dateFormatter.DateFormatter.Format(facetItem.time, "MMMM YYYY");
                                } else {
                                    facetItem.itemValue = dateFormatter.DateFormatter.Format(facetItem.time, "M/D/YYYY");
                                }
                            } else if (facetItem.term) {
                                facetItem.itemValue = facetItem.term;
                            }
                        }

                        if (parsedFilters && parsedFilters[facet.name] && parsedFilters[facet.name].length && parsedFilters[facet.name].indexOf(facetItem.itemValue) > -1) {
                            facetItem.selected = true;
                        }
                    });
                }

                facets.push(facet);
            });

            this.setState( { facets: facets, prevQuery: this.state.query, prevFilters: this.state.filters });
        }
    }
    
    public updateView() {
        if (window.innerWidth > 767) {
            $('.docnav-menu').css('display', 'block');
            $('.docnav-view').css('display', ' block');
        }
        else {
            this.hideFacetMenu();
        }
    }
    public showFacetMenu() {
        $('.docnav-menu').css('display', 'block');
        $('.docnav-view').css('display', 'none');
        $('.filterButton').css('display', 'none');
        $('.closeButton').css('display', 'block');
    }
    public hideFacetMenu() {
        $('.docnav-menu').css('display', 'none');
        $('.docnav-view').css('display', 'block');
        $('.filterButton').css('display', 'block');
        $('.closeButton').css('display', 'none');
    }
    public toggleFacet(toggledFacetItem:ISearchFacetItem) {        
        toggledFacetItem.selected = !toggledFacetItem.selected;   
        let filters:{[key:string]: Array<string>} = {};

        this.state.facets.map((facet, facetIndex) => {
            facet.facetItems.map((facetItem, facetItemIndex) => {
                if (facetItem.selected) {
                    if (!filters[facetItem.facet.name]) {
                        filters[facetItem.facet.name] = [];
                    }

                    filters[facetItem.facet.name].push(facetItem.itemValue);
                }
            });
        });

        let filterString = "";
        let concatenator = "";
        Object.getOwnPropertyNames(filters).map((filterName, i) => {
            filterString += concatenator + filterName + "=" + JSON.stringify(filters[filterName]);

            if (!concatenator) {
                concatenator = "&";
            }
        });
 
        this.setState({ facets: this.state.facets, filters: filterString, page: 0 });//, () => facet.selected = !facet.selected);
    }

    public parseFilterString(filterString:string):{[key:string]: Array<string>}
    {
        if (filterString) {
            let sanitizedParamsString = this.sanitizeParamsString(filterString);
            let splitFilters = this.parseParams(sanitizedParamsString);
            let parsedFilters:{[key:string]: Array<string>} = {};

            Object.getOwnPropertyNames(splitFilters).map((filterName, i) => {
                parsedFilters[filterName] = JSON.parse(splitFilters[filterName]);
            });

            return parsedFilters;
        } else {
            return {}
        }
    }

    public render() {
        //if (!this.state.currentNavigationPath || !this.state.currentNavigationPath.length || !this.state.currentNavigationItem)
        //    return (<div></div>);
        return ( 
            <div className="search-navigator">
                <div className="row"> 
                    <div className='col-12 toggleButtons margin-btm-10'>
                        <button className="btn btn-primary filterButton" onClick={ () => { this.showFacetMenu(); } }>Filter Results</button>
                        <button className="btn btn-primary closeButton" onClick={ () => { this.hideFacetMenu(); } }>Close</button>
                    </div>
                    <div className="col-12 col-md-4 docnav-menu">
                        <SearchFacetMenu Facets={this.state.facets} OnFacetItemClicked={this.toggleFacet.bind(this) } Loading={ this.state.loading } />
                    </div>
                    <div className="col-12 col-md-8 docnav-view">
                        <div className="row">
                            <div className={ "col-12 searchPageRow input-group" + ( this.state.loading ? " disabledElement" : ""  ) }>            
                                <input id="srchTxt" className="form-control border border-end-0 rounded-start form-control-search" type="text" ref={(input) => this.queryTextBox = input} onKeyUp={ (e) => { if (e.keyCode === 13) { this.resetSearchState(this.queryTextBox.value);  }  } } />
                                <button type="submit" className="btn-search input-group-text bg-white rounded-end" value="Search" onClick={() => this.resetSearchState(this.queryTextBox.value)}><i className="fa fa-search"></i></button>
                            </div>
                            <div className="col-12">
                                <SearchFacetItemTags Facets={this.state.facets } OnFacetItemClicked={this.toggleFacet.bind(this) } Loading={ this.state.loading } />
                            </div>
                            <div className="col-12">
                                <div className="docnav-navitem">
                                    <SearchResultPane Results={this.state.results} Page={this.state.page} PageSize={this.state.pageSize} TotalAvailableResults={this.state.totalAvailableResults} Sort={this.state.sort} SortDirection={this.state.sortDirection} Loading={ this.state.loading }
                                     OnPageSizeChange={(pageSize) => this.setState({ page: 0, pageSize: pageSize })} OnPageChange={(pageIndex) => this.setState( { page: pageIndex } )} OnSortChange={(sort, sortDirection) => this.setState({sort: sort, sortDirection: sortDirection})} />
                                </div>
                            </div>
                        </div>
                    </div>  
                    <div className='col-12 toggleButtons'>
                        <button className="btn btn-primary filterButton" onClick={ () => { this.showFacetMenu(); } }>Filter Results</button>
                        <button className="btn btn-primary closeButton" onClick={ () => { this.hideFacetMenu(); } }>Close</button>
                    </div>
                </div>

            </div>
        );
    }
}