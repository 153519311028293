import React from 'react';
import SearchResultPaneItem, {SearchResultItem} from './SearchResultPaneItem';
import Paging from '../Find/Paging';

interface ISearchResultPaneProps {
    Results: Array<SearchResultItem>;
    PageSize: number;
    Page: number;
    TotalAvailableResults: number;   
    Sort: string;
    SortDirection: string;
    Loading: boolean;
    OnPageSizeChange: (pageSize:number) => void;
    OnPageChange: (page:number) => void; 
    OnSortChange: (sort:string, sortDirection:string) => void;
}

interface ISearchResultPaneState {
}

export default class SearchResultPane extends React.Component<ISearchResultPaneProps, ISearchResultPaneState>{
    constructor(props){
        super(props);
        this.state = { };
    }

    public componentDidMount() {
    }

    public renderResultsBody() {
        if(this.props.Loading){
            return (<div className="processingIndicator row"><i className="fa fa-spin fa-refresh"></i></div>)
        } else if (!this.props.Results || !this.props.Results.length) {
            return (
                <div>
                    Sorry, no results were found that match your query.
                </div>
            )
        } else {
            return (
                <div>                  
                    <div className="docnav-documents">
                    {
                        this.props.Results.map((result, i) => {
                            return (
                                <SearchResultPaneItem key={i} Result={result} Loading={this.props.Loading} />
                            )
                        })
                    }
                    </div>
                    <div className="resultsRow d-flex">
                        <Paging ResultSetCount={this.props.Results.length} PageSize={this.props.PageSize} Page={this.props.Page} TotalAvailableResults={this.props.TotalAvailableResults} Loading={this.props.Loading} OnPageSizeChange={this.props.OnPageSizeChange} OnPageChange={this.props.OnPageChange} HideFullEntriesText={true} />
                    </div>
                </div>
            )
        }
    }

    public renderSorting() {
        if (!this.props.Results || !this.props.Results.length) {
            return (
                <div></div>
            )
        } else {
            return (
                <div className='pull-right sort-buttons'>
                    <button className={'btn btn-link searchsort' + (this.props.Sort === "SearchPublishDate" ? "" : " selected-sort")} onClick={() => this.props.OnSortChange(null, null)}>Relevance</button>
                    <button className={'btn btn-link padding-right-20 searchsort sortableth ' + (this.props.Sort === "SearchPublishDate" ? " selected-sort sortableth" + (this.props.SortDirection == "ASC" ? "--asc" : "--desc") : "--desc")} onClick={() => this.props.OnSortChange("SearchPublishDate", this.props.SortDirection !== "DESC" ? "DESC" : "ASC")}>Date</button>
                </div>
            )
        }
    }

    public render() {
        return (
            <div>
                <div className={ "resultsRow d-flex justify-content-between align-items-center flex-wrap " + ( this.props.Loading ? " disabledElement" : ""  ) } style={ this.props.Results && this.props.Results.length ? null : { "display": "none" }  }>
                    <Paging ResultSetCount={this.props.Results.length} PageSize={this.props.PageSize} Page={this.props.Page} TotalAvailableResults={this.props.TotalAvailableResults} Loading={this.props.Loading} OnPageSizeChange={this.props.OnPageSizeChange} OnPageChange={this.props.OnPageChange} HideFullEntriesText={true} />
                    { this.renderSorting() }
                </div>            
                <h2 className="margin-top-0"></h2>     
                { this.renderResultsBody() }              
            </div>
        )
    }
}