// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import {IConfigurableChoice} from "../../ConfigurableChoice";

interface IEventTypeColorKeyProps {EventTypes: Array<IConfigurableChoice>}
interface state {}
export default class EventFilters extends React.Component<IEventTypeColorKeyProps, state>{
    constructor(props) {
        super(props);
        this.state ={};
    }

    render() {
        return (
            <span className="legend pull-right color-key">           
                <a className="pwRequire legend-popover" data-bs-toggle="popover" data-bs-sanitize="false" data-bs-placement="left" id="statusDefinitionPopover"><i className="fa fa-info-circle padding-right-5"></i>Event Type Color Key</a>
                <span id="popover-content" className="d-none">
                    <h3 className="margin-top-0">Event Type Color Key</h3>
                    {
                        
                        this.props.EventTypes ?
                            this.props.EventTypes.map((eventType, i) => {
                                var eventStyle = {
                                    backgroundColor: "transparent",
                                    borderColor: eventType.description
                                }
                                
                                var eventBorderStyle = {
                                    //borderLeft: "5px solid " + eventType.description
                                }

                                var eventTypeStyle = {
                                    backgroundColor: eventType.description
                                }

                                return (
                                    <div key={i} style={eventStyle} className="k-event k-event-inverse">
                                        <div className="event" style={eventBorderStyle}>
                                            <div className="event-type" style={eventTypeStyle}></div>
                                            <div className="event-title">
                                                <span>{eventType.text}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }):''
                    }                                                            
                </span>             
            </span>
        )
    }
}