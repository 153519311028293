import React from 'react';
import * as ReactDOM from "react-dom";

export class ReactHelper {

    public static unmount(id: string) { 
        ReactDOM.unmountComponentAtNode(document.getElementById(id));
    }

}





