import React from 'react';
import { PanelItem} from "./Accordion";
import Moment from "moment";
import * as dateFormatter from '../../dateFormatter';


export interface IAccordionPanelItem { panel: PanelItem, openFirstPanel: boolean, accordionId: string, contentOverride?:string, titleOverride?:string, secondaryTitle?:string }

interface State {
}
export default class AccordionPanel extends React.Component<IAccordionPanelItem, State>{
    constructor(props) {
        super(props);
    }
    public cleanTitle(title) {
        if (typeof title === 'undefined') {
            return title;
        }
        if (Moment(title, Moment.ISO_8601, true).isValid()) {
            return dateFormatter.DateFormatter.Format(title, "MM/DD/YYYY");
        }
        return title.toString();
    }

    public render() {
        var title = this.props.titleOverride ? this.cleanTitle(this.props.panel[this.props.titleOverride]) : this.props.panel.title;
        var content = this.props.contentOverride ? this.props.panel[this.props.contentOverride] : this.props.panel.content;
        var secondaryTitle = this.props.secondaryTitle ? this.cleanTitle(this.props.panel[this.props.secondaryTitle]) : null;

        return (
            <div className="panel panel-default">
                <div className="panel-heading" role="tab" id={"" + this.props.accordionId + "Header" + this.props.panel.keyInt}>
                    <div className="leftside">
                        <h4 className="panel-title blue">
                            <a data-bs-toggle="collapse" href={"#" + this.props.accordionId + "Collapse" + this.props.panel.keyInt} data-bs-target={"#" + this.props.accordionId + "Collapse" + this.props.panel.keyInt} data-bs-parent={"#" + this.props.accordionId} role="button" aria-expanded={this.props.openFirstPanel && this.props.panel.keyInt == 0 ? true : false} aria-controls={"" + this.props.accordionId + "Collapse" + this.props.panel.keyInt} className={"blue accordion-link " + (this.props.openFirstPanel && this.props.panel.keyInt == 0 ? "" : "collapsed")}>
                               <span dangerouslySetInnerHTML={{ __html: title }} ></span>
                            </a>
                        </h4>
                        {secondaryTitle ?
                            <h3 className="subTitle">{secondaryTitle}</h3> : ""}
                    </div>
                    <div className="rightside">
                        <a data-bs-toggle="collapse" href={"#" + this.props.accordionId + "Collapse" + this.props.panel.keyInt} data-bs-target={"#" + this.props.accordionId + "Collapse" + this.props.panel.keyInt} data-bs-parent={"#" + this.props.accordionId} role="button" aria-expanded={this.props.openFirstPanel && this.props.panel.keyInt == 0 ? true : false} aria-controls={"" + this.props.accordionId + "Collapse" + this.props.panel.keyInt} className={"blue accordion-link " + (this.props.openFirstPanel && this.props.panel.keyInt == 0 ? "" : "collapsed")}>
                            <i className="fa fa-minus pull-right"></i> 
                        </a>
                    </div>
                </div>
                <div id={"" + this.props.accordionId + "Collapse" + this.props.panel.keyInt} className={"panel-collapse collapse " + (this.props.openFirstPanel && this.props.panel.keyInt == 0 ? "show" : "")} role="tabpanel" data-bs-parent={"#" + this.props.accordionId} aria-labelledby={"" + this.props.accordionId + "Heading" + this.props.panel.keyInt} aria-expanded={this.props.openFirstPanel && this.props.panel.keyInt == 0 ? true : false}>
                    <div className="panel-body">
                        {
                            this.props.panel.image && this.props.panel.imageType == "icon" ?
                                <div className="row">
                                    <div className="col-4 col-md-2 cta-img">
                                        <img src={this.props.panel.image} className="img-responsive" />
                                    </div>
                                    <div className="col-8 col-md-10">
                                        <div dangerouslySetInnerHTML={{ __html: content }} ></div>
                                    </div>
                                </div>
                                : this.props.panel.image && this.props.panel.imageType == "image" ?
                                    <div className="row">
                                        <div className="col-12 col-md-6 cta-img pull-right">
                                            <img src={this.props.panel.image} className="img-responsive" />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div dangerouslySetInnerHTML={{ __html: content }} ></div>
                                        </div>
                                    </div>
                                    :
                                    <div dangerouslySetInnerHTML={{ __html: content }} ></div>

                        }
                    </div>
                </div>
            </div>

        )
    }
}
