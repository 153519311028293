import React from 'react';

export interface IListOptions  {
    className:string;
    dataSetName:string;
    dataItemPropName: string;
}

export interface IGridProps {
    Columns:Array<any>;
    OnSortClick?:(columnName:string) => void; 
    ResultSortField?:string;
    ResultSortDirection?:string;
}

interface IListState {
}

export default function List<TOriginalItemProps extends IGridProps>(
        ItemComponent: (React.ComponentClass | React.StatelessComponent), 
        options:IListOptions) { 
    return class List extends React.Component<TOriginalItemProps, IListState> {
        constructor(props) {
            super(props);
            this.state = { };
        } 

        public render() {
            var tableStyles = " table table-bordered docnav-metadata dataTable no-footer";
            if (this.props["TableStyle"] && this.props["TableStyle"].length) {
                tableStyles = this.props["TableStyle"] + tableStyles;
            }
            let itemOverriddenProps = {};
            if (!this.props[options.dataSetName] || !this.props[options.dataSetName].length) {
                return (<div></div>)
            }
            return (
                <div className={ options.className } >
                    <table className={tableStyles}>
                            <thead>
                                <tr className="col-header-row" role="row">
                                    {
                                        this.props.Columns.map((col, colindex) => {
                                            if (this.props.OnSortClick) {
                                                return (
                                                    <th key={colindex} onClick={() => this.props.OnSortClick(col.propertyName)} id={col.propertyName} className={(col.propertyName === this.props.ResultSortField ? "sortableth sortableth--" + (this.props.ResultSortDirection || "asc") : "colheader-sortable colheader-unsorted") + (col.Alignment ? " text-" + col.Alignment : "")  } >
                                                        {col.title || col.propertyName}
                                                    </th>
                                                )
                                            } else {
                                                return (
                                                    <th key={colindex} className={"colheader-unsortable" + col.Alignment ? " text-" + col.Alignment : "" }>
                                                        {col.title || col.propertyName}
                                                    </th>
                                                )
                                            }
                                        })
                                    }
                                </tr>
                                <tr className="col-header-row mobile-row">
                                    <th>{this.props.Columns[0].title || this.props.Columns[0].propertyName }</th>
                                </tr>
                            </thead>
                            {
                                this.props[options.dataSetName].map((dataItem, dataItemIndex: number) => {
                                    let itemOverriddenProps = {};
                                    itemOverriddenProps[options.dataItemPropName] = dataItem;
                                    return (
                                        <ItemComponent key={dataItemIndex} {...this.props} {...itemOverriddenProps} />
                                    );
                                })
                            }                      
                        </table>
                    <div></div>
                </div>);
        }
    }
}