import React from 'react';
import * as FindResultItemBase from "./FindResultItemBase";

export interface IResultsProps<T extends FindResultItemBase.FindResultItem> { 
    findServiceUrl: string;
    initialResults?: Array<T>; 
    query?: any;
    disableFollowing?:boolean;
}

export interface IResultsState<T extends FindResultItemBase.FindResultItem> {  
    results: Array<T>;
    query: any;    
    totalAvailableResults:number;
    loading: boolean;
    forceLoading?: boolean;
}   

export abstract class FindResultsBase<TResultItem extends FindResultItemBase.FindResultItem, TResultsProps extends IResultsProps<TResultItem>, TResultsState extends IResultsState<TResultItem> > extends React.Component<TResultsProps, TResultsState> {
    constructor(props) {
        super(props);
    }

    public componentDidMount(){
        if (this.props.initialResults) {
            this.setState({ results: this.props.initialResults });
        }
        if (this.props.query) {
            this.setState({query: this.props.query} );
        }
    }
    
    public componentDidUpdate(prevprops, prevstate) {
        if ((this.state.query) && this.state.query != prevstate.query ) {
			this.executeSearch();
		}
    }
    
    public initQueryExecution(query:any, successCallback:(data:any) => void) {
        var querybody = JSON.stringify(query);
        if (querybody) {
            var xmlhttp = new XMLHttpRequest();
            xmlhttp.open("POST", this.props.findServiceUrl + "/_search", true);
            xmlhttp.onload = function(e) {
                var data = JSON.parse(xmlhttp.responseText);
                successCallback(data);
            }.bind(this);

            xmlhttp.send(querybody);
        } 
    }
    public initSecondQueryExecution(query2: any, successCallback: (data: any) => void) {
        query2.from = this.state.results.length;
        var querybody = JSON.stringify(query2);

        if (querybody) {
            var xmlhttp = new XMLHttpRequest();
            xmlhttp.open("POST", this.props.findServiceUrl + "/_search", true);
            xmlhttp.onload = function (e) {
                var data = JSON.parse(xmlhttp.responseText);
                successCallback(data);
            }.bind(this);
            xmlhttp.send(querybody);
        }
    }

    public executeSearch(query:any = null) {
        if (this.state.loading !== true) {
            this.setState({ loading: true }, () => this.executeSearch(query));
        } else {
            query = query || this.state.query || this.props.query;

            if(typeof query.size == "undefined" || query.size > 1000){
                query.size = 1000;
            }     

            this.initQueryExecution(query, (data) => this.setParsedResults(data));
        }       
    }

    abstract constructResultItem():TResultItem; 

    private getParsedPropertyName(propertyName: string) {

        let index = propertyName.indexOf("$$");
        return index !== -1 ? propertyName.slice(0, index) : propertyName;

    }

    protected parseHit(hit:any):TResultItem {
        let parsedSingleResult = this.constructResultItem();
        parsedSingleResult.source = hit.source || hit.fields?.source || hit.fields || hit._source;
        Object.getOwnPropertyNames(parsedSingleResult).forEach(prop => {
            if (prop != "source") {
                if (parsedSingleResult.source) {
                    var sourceProp = Object.getOwnPropertyNames(parsedSingleResult.source).filter(x => {
                        if (prop.length > x.length) {
                            return false;
                        } else {
                            var parsedProp = this.getParsedPropertyName(x);
                            return parsedProp === prop;
                        }
                    });
                    if (sourceProp && sourceProp.length > 0) {
                        parsedSingleResult[prop] = parsedSingleResult.source[sourceProp[0]];
                    }
                }
                else if (hit && hit.fields) {
                    var fieldsAsArray = Object.keys(hit.fields);
                    var parsedField = fieldsAsArray.filter(p => this.getParsedPropertyName(p) === prop);

                    if (parsedField) {
                        parsedSingleResult[prop] = hit.fields[parsedField.toString()];
                    }

                }
                if (parsedSingleResult[prop] && parsedSingleResult[prop]["$type"] === "EPiServer.Core.XhtmlString, EPiServer" && parsedSingleResult[prop]["AsViewedByAnonymous$$string"]) {
                    parsedSingleResult[prop] = parsedSingleResult[prop]["AsViewedByAnonymous$$string"];
                }
            }
            else if (parsedSingleResult[prop]["Properties"] && Object.keys(parsedSingleResult[prop]["Properties"]).length > 0) {
                var sourceProperties = parsedSingleResult[prop]["Properties"];
                Object.keys(sourceProperties).forEach(propertyKey => {
                    parsedSingleResult[propertyKey] = sourceProperties[propertyKey]
                });
            }
        });
        if (!parsedSingleResult.description && parsedSingleResult.source.descriptiong) {
            parsedSingleResult.description = parsedSingleResult.source.descriptiong;
        }

        return parsedSingleResult;
    }

    private setParsedResults(data: any) {
        if (data && data.hits && data.hits.hits && data.hits.hits.length) {
            var resultData:Array<TResultItem> = new Array<TResultItem>();

            let numberOfPagesInHits = 0;
            data.hits.hits.forEach((hit) => {
                let parsedHit = this.parseHit(hit);
                if (parsedHit.SearchTypeName === 'Page') { // Shift page type results to top of results.
                    resultData.splice(numberOfPagesInHits++, 0, parsedHit);
                } else {
                    resultData.push(this.parseHit(hit));
                }
            });
            
            let additionalState = this.performAdditionnalSearchResultParsing(resultData, data);
        
            this.getFollowedStatus(resultData).then((subscriptionData) => {
                var subscriptions = subscriptionData.subscriptions;

                if(subscriptions.length > 0){
                    for(var i=0; i<resultData.length; i++){
                        for(var j=0; j<subscriptions.length; j++){
                            if(resultData[i].DocumentGuid == subscriptions[j].contentId){
                                // Add subscription status to document
                                resultData[i].isFollowing = true;
                                break;
                            }
                        }
                    }
                }

                this.setResultState(resultData, data.hits.total, additionalState);
                this.onResultsUpdated(resultData);
            });
        }
        else {
            let additionalState = this.performAdditionnalSearchResultParsing(null, data);
            this.setResultState([], data.hits ? data.hits.total : 0, additionalState);
        }
    }
    private setAdditionalParsedResults(data: any) {
        if (data && data.hits && data.hits.hits && data.hits.hits.length) {

            var resultData: Array<TResultItem> = new Array<TResultItem>(data.hits.hits.length);

            data.hits.hits.forEach((hit, index) => {
                resultData[index] = this.parseHit(hit);
            });

            let additionalState = this.performAdditionnalSearchResultParsing(resultData, data);

            this.getFollowedStatus(resultData).then((subscriptionData) => {
                var subscriptions = subscriptionData.subscriptions;

                if (subscriptions.length > 0) {
                    for (var i = 0; i < resultData.length; i++) {
                        for (var j = 0; j < subscriptions.length; j++) {
                            if (resultData[i].DocumentGuid == subscriptions[j].contentId) {
                                // Add subscription status to document
                                resultData[i].isFollowing = true;
                                break;
                            }
                        }
                    }
                }

                var combinedResultData: Array<TResultItem> = new Array<TResultItem>();
                combinedResultData = this.state.results.concat(resultData);

                this.setResultState(combinedResultData, data.hits.total, additionalState);
                this.onResultsUpdated(combinedResultData);
            });
        }
    }

    public performAdditionnalSearchResultParsing(results: Array<TResultItem>, data:any) : any {
        // Placeholder for base classes to implement
        return null;
    }

    public onResultsUpdated(resultData: Array<TResultItem>) {
        if (resultData.length < this.state.totalAvailableResults && this.state.query['size'] == 1000) {
            this.initSecondQueryExecution(this.state.query, (data) => this.setAdditionalParsedResults(data));
        }
        // TODO:  If Necessary, override in derived class to handle results changing
    }


    private getFollowedStatus(resultsData: Array<TResultItem>):JQueryPromise<any> {
        if (this.props.disableFollowing) {
            return $.Deferred().resolve({subscriptions:[]}).promise();
        }

        var documentIds = resultsData.map((resultItem: any, index) => {
            return resultItem.DocumentGuid || resultItem.source.DocumentGuid || resultItem.ContentId || resultItem.source.ContentId;
        }).filter(resultItem => resultItem);

        if (!documentIds || !documentIds.length) {
            return $.Deferred().resolve({subscriptions:[]}).promise();
        }

        return $.ajax({
            url: "/api/documents/GetSubscribedDocuments",
            type: "POST",
            data: JSON.stringify(documentIds),
            dataType: 'json',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            success: (data) => {
                return data;
            },
            error: (error) => {
                // An error happened
            }
        })
    }

    protected setResultState(newResults:Array<TResultItem>, totalAvailableResults:number = 0, additionalState:any = null) {
        let partialState = { results: newResults, totalAvailableResults:totalAvailableResults, loading: (false || (this.state.forceLoading || false)) };
        if (additionalState) {
            Object.getOwnPropertyNames(additionalState).map((prop, i) => {
                partialState[prop] = additionalState[prop];
            });
        }
        
        this.setState( partialState);
    }
}