import React from 'react';
import {IContentAlertListProps} from './ContentAlertList'

export interface IContentAlertSubscription { id: number, contentId: string, contentName: string, contentUrl: string, contentTypeId: string, source: string, creationDate: Date  }
export interface IContentAlert { id: number, subscription: IContentAlertSubscription, creationDate: Date }
export interface IContentAlertResult { alertId: number, subscriberId: number, wasClicked: boolean, wasProcessed: boolean, processedDate: Date }

export interface IContentAlertItem { alert: IContentAlert, result: IContentAlertResult }
export interface IContentAlertItemProps { alert: IContentAlert, result: IContentAlertResult, index: number, parentProps: IContentAlertListProps, contentType: string }

interface State {
	deleted: boolean
}

export default class ContentAlertItem extends React.Component<IContentAlertItemProps, State> {
	constructor(props) {
		super(props);
		this.state = { deleted: false };
	}

	private padTimeElement(str) {
  str = str.toString();
  return str.length < 2 ? "0" + str : str;
}

	private formatDateTime(date: Date) {
		var datestring = ("0" + (date.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + date.getDate()).slice(-2) +
			"/" +
			date.getFullYear();
			

		if (this.props.parentProps.mode === 0) {
			var hour = date.getHours();
			var ampm = (hour < 12) ? "AM" : "PM";
			if (hour > 12) {
				hour = hour - 12;
			}
			if (hour === 0) {
				hour = 12;
			}

			datestring += ' ' +
				hour +
				":" +
				this.padTimeElement(date.getMinutes()) +
				' ' +
				ampm;
		}

		return datestring;
	}

	private alertClicked() {
		$.ajax({
			url: this.props.parentProps.endpoint + "UserAlertClicked?alertId=" + this.props.alert.id,
			type: 'POST',
   //   dataType: 'json',
			//headers: {
			//	'Accept': 'application/json',
			//	'Content-Type': 'application/json'
			//}
		});
	}

	private alertDeleted() {
		$.ajax({
			url: this.props.parentProps.endpoint + "DeleteUserAlert?alertId=" + this.props.alert.id,
			type: 'POST',
   //   dataType: 'json',
			//headers: {
			//	'Accept': 'application/json',
			//	'Content-Type': 'application/json'
			//},
			success: function () { this.setState({ deleted: true }); }.bind(this),
			error: function (data) { console.log('an error occured when attempting to delete'); }.bind(this)
		});
	}

	private navigateToAlertSubscriptionsource() {
		this.alertClicked();
		window.location.href = this.props.alert.subscription.contentUrl;
	}

	public render() {
		return (
			<div className="info">
				<button className="content-alert" onClick={() => this.navigateToAlertSubscriptionsource()} {... (this.state.deleted === true) ? { style: { display: "none" } } : {}}>
					<div className="content-alert-link">{this.props.alert.subscription.contentName}</div>
					<div className="content-alert-info">
						<div>Updated on {this.formatDateTime(new Date(this.props.alert.creationDate))}</div>
						<div>{this.props.contentType}</div>
					</div>
				</button>
			</div>
		)
	}
}