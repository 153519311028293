import React from 'react';
import transformTagToReadableString from './TagTransformer';

export class SearchFacet {
    name: string;
    title: string;
    facetType: string;
    facetItems: Array<ISearchFacetItem>;
}

export interface ISearchFacetItem {
    facet: SearchFacet;
    itemValue: string;
    time?: any;
    term?: any;
    selected: boolean;
    count:number;
    filter:string;
}

interface ISearchFacetMenuSectionProps 
{ 
    Facet:SearchFacet, 
    OnFacetItemClicked:Function, 
    Loading:boolean
}

interface ISearchFacetMenuSectionState 
{ 
    maxItems:number
}

const defaultMaxItems = 5;

export default class SearchFacetMenuSection extends React.Component<ISearchFacetMenuSectionProps, ISearchFacetMenuSectionState>{
    constructor(props) {
        super(props);
        this.state = { maxItems: defaultMaxItems };
        this.toggleMaxItems = this.toggleMaxItems.bind(this);
    }

    private toggleMaxItems() {
        if (this.state.maxItems > 0) {
            this.setState({maxItems: -1 });
        } else {
            this.setState({maxItems: defaultMaxItems });
        }
    }

    public render() {
        let moreOrLess = this.state.maxItems <= 0 
            ? <span>View Less</span>
            : <span>View More</span>;

        let maxItemsToggler = <div className="docnav-menuitem active text-xs-center moreOrLess"><button className="btn white w-100 justify-content-center" onClick={() => { this.toggleMaxItems() }}>{moreOrLess}</button></div>

        return (
            <div className={'margin-btm-20 hocblockbase withBorder' + ( this.props.Loading ? " disabledElement" : ""  ) }>
                <h4 className="integratedHeader blue-bg margin-btm-0">{this.props.Facet.title}</h4>
                <div className="docnav-menuitems">
                    {
                        this.props.Facet.facetItems.map((facetItem, i) => {
                            let isMaxedOut = (this.state.maxItems > 0 && (i + 1) > this.state.maxItems);
                            if (!isMaxedOut && facetItem.itemValue) {
                                return ( 
                                    <div className="docnav-menuitem active" key={i}>
                                        <button onClick={() => { this.props.OnFacetItemClicked(facetItem); }} className={"btn btn-link " + (facetItem.selected === true ? 'bold' : '')}><input disabled={this.props.Loading} type="checkbox" checked={facetItem.selected === true} className="margin-right-10" />{transformTagToReadableString(facetItem.itemValue)} ({facetItem.count})</button>
                                    </div>
                                )
                            }
                        })
                    }
                    { (this.props.Facet.facetItems.length > this.state.maxItems) && maxItemsToggler }
                </div>
            </div>
        );
    }
}