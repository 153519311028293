// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';

interface ITariffTabSearchProps { Search: (query:string) => void }
interface State { query: string }

export default class TariffTabSearch extends React.Component<ITariffTabSearchProps, State>{

    constructor(props){
        super(props);
        this.state = { query: "" }
    }

    componentDidMount(){
    }

    private clearSearch(){
        this.setState({query: ""}, ()=>{this.search()});
    }

    private updateQuery(event: React.FormEvent<HTMLInputElement>){
        var value = (event.target as HTMLInputElement).value;    
        this.setState({query: value});
    }

    private onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {      
        if(event.key == "Enter"){
            this.search();
        }
    }

    private search(){        
        this.props.Search(this.state.query);
    }
    
    render() {

        return(
            <div className="tariff-search margin-top-20">
                <span className="legend float-end fs14 black">
                    <i className="fa fa-info-circle"></i> &nbsp;
                    <a className="pwRequire legend-popover black" data-bs-toggle="popover" data-bs-sanitize="false" data-bs-placement="bottom" id="statusDefinitionPopover">Help With Search</a>
                    <span id="popover-content" className="d-none">
                        <p><strong>You can search for tariff documents by title or text within the document.</strong></p>
                        <p>
                            To improve your search results, consider these options:<br />
                            Use quotation marks to search a specific phrase.<br />
                            Use AND to search for documents with both "this phrase" and "that phrase."<br />
                            Use OR to search for documents with either "this phrase" or "that phrase."<br />
                        </p>
                    </span>
                </span> 
                <div className="">
                    <h3 className="blue"><label htmlFor="searchQuery">Search By </label></h3>
                    <div className="d-flex flex-column flex-md-row justify-content-md-between">
                        <input type="text" id="searchQuery" className="form-control me-0 me-md-3" placeholder="Title or document content" value={this.state.query} onChange={this.updateQuery.bind(this)} onKeyPress={this.onKeyPress.bind(this)} />
                        <div className="d-flex mt-2 mt-md-0">
                            <button type="button" className="btn btn-small fs16 btn-default" value="Clear" onClick={() => { this.clearSearch() }}>
                                Clear <i className="fa fa-arrow-circle-right margin-left-20" />
                            </button>
                            <button type="button" className="btn btn-small fs16 btn-primary ms-3" onClick={() => { this.search() }}>
                                Search <i className="fa fa-arrow-circle-right margin-left-20" />
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}