import React from 'react';
import LegendItemList, {ILegendItemList} from "./LegendItemList";
import {IChoiceItem} from "../../ConfigurableChoice";

interface ILegendProps { legendLabel: string, legendListItems: Array<ILegendItemList>, choiceItems: Array<IChoiceItem>, legendListDataRetriever: Function, placement?:string }

interface State {
	legendListItems: Array<ILegendItemList>
}

export class Legend extends React.Component<ILegendProps, any>{
	private legendListDataRetriever: Function;

	 constructor(props) {
		super(props);
		this.state = { legendListItems: [] };
	 }

	public componentDidMount() {
		this.GetLegendLists();
	}

	private GetLegendLists() {
		if (!this.legendListDataRetriever && this.props.legendListDataRetriever) {
			if (typeof this.props.legendListDataRetriever === 'string') {
				this.legendListDataRetriever = eval(this.props.legendListDataRetriever);
			} else if (typeof this.props.legendListDataRetriever === 'function') {
				this.legendListDataRetriever = this.props.legendListDataRetriever;
			}
		}

		if ((this.props.legendListItems && this.props.legendListItems.length && this.props.legendListItems.length > 0)
			|| !this.legendListDataRetriever) {
			this.setState({ legendListItems: this.props.legendListItems });
			return $.Deferred().resolve().promise();
		}
		this.legendListDataRetriever(
			this.props.choiceItems,
			function (data) {
				if(this.props.choiceItems != null){
					this.setState({ 
						legendListItems: data.map((legendItem,index) => {
							for(var i=0; i<this.props.choiceItems.length; i++){
								var choiceItem = this.props.choiceItems[i];

								if(choiceItem.configurableChoice == legendItem.name){
									return {
										index: i,
										name: legendItem.name,
										displayName: legendItem.displayName,
										showValue: choiceItem.showValue,
										isColorKey: choiceItem.isColorKey,
										items: legendItem.items
									} as ILegendItemList;
								}
							}
						}), showLabel: data.length == 1 ? false : true 
					});
				} else {
					this.setState({ legendListItems: data.legendItems, showLabel: data.length == 1 ? false : true });
				}							
		}.bind(this));
	}

	public render() {
        return (
            <span className="legend">
				
				<a className="pwRequire legend-popover" data-bs-toggle="popover" data-bs-placement={ this.props.placement ? this.props.placement : "bottom"} data-bs-offset="70,0" role="button" id="statusDefinitionPopover"><i className="fa fa-question-circle"></i>{this.props.legendLabel}</a> 
				<span id="popover-content" className="d-none">
					{
						this.state.legendListItems.map((legendList, i) => {
							return (
								<LegendItemList key={i} index={i} name={legendList.name} showLabel={this.state.showLabel} displayName={legendList.displayName} showValue={legendList.showValue} isColorKey={legendList.isColorKey} items={legendList.items} />
							)
						})
					}
				</span>
            </span>
        )
    }
}