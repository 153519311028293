import React from 'react';

export interface IListOptions {
    className: string;
    dataSetName: string;
    dataItemPropName: string;
}

interface IListState {
    showAll: boolean;
}

const SHOW_MORE_ITEMS_MAX_THRESHOLD = 5;

export default function List<TOriginalItemProps extends {}>(
    ItemComponent: (React.ComponentClass | React.StatelessComponent),
    options: IListOptions) {
    return class List extends React.Component<TOriginalItemProps, IListState> {
        constructor(props) {
            super(props);
            this.state = { showAll: false };
        }
        
        ShowAll = () => {
            this.setState({
                showAll: true
            });
        }

        ShowLess = () => {
            this.setState({
                showAll: false
            });
        }
        public render() {
            if (this.props["loading"]) {
                return (<div className="pageLoader"><i className="fa fa-spin fa-refresh"></i></div>)
            }
            if (!this.props[options.dataSetName] || !this.props[options.dataSetName].length) {
                return (<div className="linkList"><div className='emptyList'>{this.props["noResultsText"] || "There are currently no documents that match this criteria."}</div></div>)
            }
            return (

                <div className={options.className} >
                    <div></div>
                    {
                        this.state.showAll || options.dataSetName != "RelatedDocumentItems" ?
                            this.props[options.dataSetName].map((dataItem, dataItemIndex: number) => {
                                let itemOverriddenProps = {};
                                itemOverriddenProps[options.dataItemPropName] = dataItem;
                                return (
                                    <ItemComponent key={dataItemIndex} {...this.props} {...itemOverriddenProps} />
                                );
                            })
                            :
                            this.props[options.dataSetName].slice(0, SHOW_MORE_ITEMS_MAX_THRESHOLD).map((dataItem, dataItemIndex: number) => {
                                let itemOverriddenProps = {};
                                itemOverriddenProps[options.dataItemPropName] = dataItem;
                                return (
                                    <ItemComponent key={dataItemIndex} {...this.props} {...itemOverriddenProps} />
                                );
                            })

                    }
                    {
                        options.dataSetName == "RelatedDocumentItems" ?
                            (this.props[options.dataSetName].length > SHOW_MORE_ITEMS_MAX_THRESHOLD && !this.state.showAll ?
                                <a className="relatedLink text-center showMoreLink" onClick={this.ShowAll}><h4>Show More</h4></a> :
                                (
                                    this.state.showAll ?
                                        <a className="relatedLink text-center showMoreLink" onClick={this.ShowLess}><h4>Show Less</h4></a> : ''
                                )) : ''
                    }
                </div>

            );
        }
    }
}