// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import Event, {IEvent} from "./Event";
import * as dateFormatter from '../../dateFormatter';
export interface IEventDetailsProps { date: Date, events: Array<IEvent>, activeEvent?: number, hash: string}
interface state {}
export default class EventDetails extends React.Component<IEventDetailsProps, state>{
    constructor(props) {
        super(props);
    }   
    private isActiveEvent(index) {
        if(this.props.activeEvent != null){
            if(this.props.events[index].id == this.props.activeEvent){
                return true;
            } else {
                return false;
            }
        } else { 
            if(index == 0){
                return true;
            } else {
                return false;
            }
        }        
    }

    private compareEventTimes(eventA: IEvent, eventB: IEvent): number {
        return eventA.start.getTime() - eventB.start.getTime();
    }

    public render() {
        return (
            <div className="event-details hocblockbase withBorder">            
                <h4 className="integratedHeader blue margin-top-0">Event details</h4>

                <div className="insetPadding padding-left-10 padding-right-10">
                    <h4 className="blue event-day">
                        {this.props.date.getMonth() == 4 ? dateFormatter.DateFormatter.Format(this.props.date, "ddd., MMM D, Y")
                            : dateFormatter.DateFormatter.Format(this.props.date, "ddd., MMM. D, Y")
                        }
                    </h4>
                    <div className="block accordionblock  ">    				
                        <div className="accordion-content">
                            <div id="eventsAccordion" className="panel-group" role="tablist" aria-multiselectable="true">
                                {this.props.events.length > 0 ?                                 
                                    this.props.events.sort(this.compareEventTimes).map((event, i) => {
                                        return(
                                            <Event key={i} index={i} event={event} activeEvent={this.isActiveEvent(i) } hash={this.props.hash}/>
                                        )
                                    })                                
                                    :                                 
                                    <div>There no events scheduled for this day.</div>
                                }
                            </div>
                        </div>					      			
                    </div>
                </div>
            </div>
        )
    }
}