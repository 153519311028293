import React from 'react';
import * as FormResultItemBase from "./FormResultItemBase";
import * as FormResultsBase from "./FormResultsBase";
import * as Moment from 'moment';
import { Accordion, AccordionItem, PanelItem } from "../Accordion/Accordion";
import * as dateFormatter from '../../dateFormatter';
//import * as MisoGlobal from "../../global";
//declare var Miso: MisoGlobal.MisoGlobal;

class IFeedbackResultsDownload {
    address: string;
    approvalDate: string;
    approved: boolean;
    approver: string;
    byUser: string;
    committee: string;
    company: string;
    dueDate: string;
    email: string;
    feedback: string;
    finalized: boolean;
    firstName: string;
    isPublic: boolean;
    lastChanged: Date;
    lastName: string;
    postedDate: Date;
    responseTo: string;
    sector: string;
    smeEmail: string;
    submissionID: string;
    submittedFrom: string;
    time: string;
}

interface IStakeholderFeedbackFormResultsProps {
    feedbackSubmissionItems: Array<IFeedbackResultsDownload>,
/*    formId: string,*/
    hostPageId:string
}
interface IStakeholderFeedbackFormResultsState { }

export class FeedbackResultsDownload extends React.Component<IStakeholderFeedbackFormResultsProps, IStakeholderFeedbackFormResultsState>{
    constructor(props) {
        super(props);

        this.state = { }
    }

    componentDidMount() {
    }

    componentDidUpdate() {

    }

    public render() {
        console.log(this.props);

        return (

            this.props.feedbackSubmissionItems.length == 0 ? <div className="editBar2 margin-btm-20"><h3>Download Form Submissions</h3>There are no results to download</div> :

            <div className="editBar2 margin-btm-20" >
                <h3>Download Form Submissions</h3>
                <h3>This does not work currently and is a seperate feature</h3>
                {/*<button className="btn btn-default btn-small margin-right-20" onClick={() => { this.downloadButton("csv"); }}><span className="buttonText">CSV <i className="fa fa-file-text-o margin-left-10"></i></span></button>
                <button className="btn btn-default btn-small margin-right-20" onClick={() => { this.downloadButton("json"); }}><span className="buttonText">JSON <i className="fa fa-file-text-o margin-left-10"></i></span></button>
                <button className="btn btn-default btn-small margin-right-20" onClick={() => { this.downloadButton("xml"); }}><span className="buttonText">XML  <i className="fa fa-file-code-o margin-left-10"></i></span></button>*/}
                <a className="btn btn-default btn-small margin-right-20" href={"/api/forms/DownloadFile?HostPageId=" + this.props.hostPageId + "&dataset=All"}><span className="buttonText">PDF - All <i className="fa fa-file-code-o margin-left-10"></i></span></a>
                <a className="btn btn-default btn-small margin-right-20" href={"/api/forms/DownloadFile?HostPageId=" + this.props.hostPageId + "&dataset=Public"}><span className="buttonText">PDF - Public <i className="fa fa-file-code-o margin-left-10"></i></span></a>
                <a className="btn btn-default btn-small margin-right-20" href={"/api/forms/DownloadFile?HostPageId=" + this.props.hostPageId + "&dataset=PublicAndApproved"}><span className="buttonText">PDF - Public & Approved<i className="fa fa-file-code-o margin-left-10"></i></span></a>
            </div >
        )
    }
}