import React from 'react';

export interface IPagingProps {
    ResultSetCount: number;
    PageSize: number;
    Page: number;
    TotalAvailableResults: number;   
    Loading: boolean;
    NoResultsText?: string; 
    IncludePagingDescription?: boolean;
    OnPageSizeChange: (pageSize:number) => void;
    OnPageChange: (page:number) => void;     
    OnSortClick?:(columnName:string) => void;  
    ResultSortField?:string;
    ResultSortDirection?: string;
    HideFullEntriesText?: boolean;
}

interface IPagingState {

}

export default class Paging extends React.Component<IPagingProps, IPagingState>{
    constructor(props){
        super(props);
        this.state = { };
    }

    private onPageSizeChanged (event) {
        if (event.target.value != this.props.PageSize) {
            this.props.OnPageSizeChange(event.target.value);
        }
    }

    private selectResultsPage (pageIndex:number) {
        var numPages = Math.ceil(this.props.TotalAvailableResults / this.props.PageSize);

        if (pageIndex >= 0 && pageIndex < numPages && pageIndex != this.props.Page) {
            this.props.OnPageChange(pageIndex);
        }
    }

    public renderPagingDescription() {
        if (this.props.PageSize > 0 && this.props.ResultSetCount < this.props.TotalAvailableResults) {
            return (<div className="dataTables_info">Showing <span className="bold">{(this.props.Page * this.props.PageSize) + 1}-{Math.min((this.props.Page + 1) * this.props.PageSize, this.props.TotalAvailableResults)}</span> of <span className="bold">{ this.props.TotalAvailableResults }</span></div>)
        } else {
            return (<div className="dataTables_info">Showing <span className="bold">{this.props.ResultSetCount}</span> documents</div>)
        }
        
    }

    public render() {
        var pageSizeValues = [5, 10, 25, 50, 100, 500];    // Set possible values for page size selector
        
        if (this.props.ResultSetCount > 0 && this.props.TotalAvailableResults > pageSizeValues[0]) {
            var numPages = Math.ceil(this.props.TotalAvailableResults / this.props.PageSize);
                var pageIndexes = [];
                
                var pageSizeOptions = [];

                var first = (this.props.Page < 2 ? 0 : this.props.Page - 2);
                var last = (this.props.Page < 2 ? 4 : this.props.Page + 2);

                if(last > numPages - 1) {
                    last = numPages - 1; 
                    first = last - 4 < 0 ? 0 : last -4;
                }

                for (var pageIndex = first; pageIndex <= last; pageIndex++){
                    pageIndexes.push({index: pageIndex, label: pageIndex + 1, disabled: false});
                }

                for (var pageSizeIndex = 0; pageSizeIndex < pageSizeValues.length; pageSizeIndex++){
                    pageSizeOptions.push({ pageSizeValue: pageSizeValues[pageSizeIndex], pageSizeText: pageSizeValues[pageSizeIndex] + "" });
                }

                //if(this.props.TotalAvailableResults < 1000){
                //    pageSizeOptions.push({ pageSizeValue: -1, pageSizeText: "All Results" });
                //    console.log(pageSizeOptions);
                //}
            return (
                <div className="pagination-controls">
                    <div className="dataTables_length">
                        {this.props.HideFullEntriesText ? 
                            <>
                                <select className="pager" onChange={this.onPageSizeChanged.bind(this)} value={this.props.PageSize}>
                                    {
                                        pageSizeOptions.map((pageSizeOption, pageSizeIndex) => {
                                            return (
                                                <option key={pageSizeIndex} value={pageSizeOption.pageSizeValue}>{pageSizeOption.pageSizeText} per page </option>
                                            )
                                        })
                                    }
                                </select>
                            </> :
                            <>
                                Show
                                <select className="pager" onChange={this.onPageSizeChanged.bind(this)} value={this.props.PageSize}>
                                    {
                                        pageSizeOptions.map((pageSizeOption, pageSizeIndex) => {
                                            return (
                                                <option key={pageSizeIndex} value={pageSizeOption.pageSizeValue}>{pageSizeOption.pageSizeText}</option>
                                            )
                                        })
                                    }
                                </select>
                                Entries
                            </>
                            
                        }
                    </div>
                    <div className="rightSide">
                        {this.props.IncludePagingDescription && this.renderPagingDescription()}
                        <div className="dataTables_paginate" style={ pageIndexes.length < 2 ? { display: "none" } : null }>
                            {/*<button className={"btn btn-link " + (this.props.Page <= 0 ? "disabled paginate_button" : "paginate_button")} onClick={() => this.selectResultsPage(0)} aria-label="First">*/}
                            {/*    <span aria-hidden="true"><i className="fa fa-angle-double-left" /></span>*/}
                            {/*    <span className="sr-only">First</span>*/}
                            {/*</button>*/}
                            <button className={"btn btn-link " + (this.props.Page <= 0 ? "disabled paginate_button first" : "paginate_button first")} onClick={() => this.selectResultsPage(0)} aria-label="first">
                                <span aria-hidden="true"><i className="fa fa-angle-double-left" /></span>
                                <span className="sr-only">First</span>
                            </button>
                            <button className={"btn btn-link " + (this.props.Page <= 0 ? "disabled paginate_button previous" : "paginate_button previous")} onClick={() => this.selectResultsPage(this.props.Page - 1)} aria-label="Previous">
                                <span aria-hidden="true"><i className="fa fa-angle-left" /></span>
                                <span className="sr-only">Previous</span>
                            </button>
                            {
                                pageIndexes.map((pageIndex, i) => {
                                    return (
                                        <button key={pageIndex.index} className={"btn btn-link " + (pageIndex.disabled ? "disabled paginate_button" : this.props.Page == pageIndex.index ? "current paginate_button" : "paginate_button")} onClick={() => this.selectResultsPage(pageIndex.index)}>
                                                {pageIndex.label}
                                        </button>)
                                })
                            }
                            <button className={"btn btn-link " + (this.props.Page >= (numPages - 1) ? "disabled paginate_button next" : "paginate_button next")} onClick={() => this.selectResultsPage(this.props.Page + 1)} aria-label="Next">
                                <span aria-hidden="true"><i className="fa fa-angle-right" /></span>
                                <span className="sr-only">Next</span>
                            </button>
                            <button className={"btn btn-link " + (this.props.Page >= (numPages - 1) ? "disabled paginate_button last" : "paginate_button last")} onClick={() => this.selectResultsPage(numPages - 1)} aria-label="last">
                                <span aria-hidden="true"><i className="fa fa-angle-double-right" /></span>
                                <span className="sr-only">Last</span>
                            </button>
                            {/*<button className={"btn btn-link " + (this.props.Page >= (numPages - 1) ? "disabled paginate_button next" : "paginate_button next")} onClick={() => this.selectResultsPage(numPages - 1)} aria-label="Last">*/}
                            {/*    <span aria-hidden="true"><i className="fa fa-angle-double-right" /></span>*/}
                            {/*    <span className="sr-only">Last</span>*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}