import React from 'react';
import MeetingItem, {IMeetingItem} from "./MeetingItem";
import BlockBase from '../../hocs/BlockBase';
import {IBlockBaseProps} from '../../hocs/BlockBase';
import * as dateFormatter from '../../dateFormatter';

interface IMeetingListProps extends IBlockBaseProps { disableRegistrations: boolean, committeeReference: string }

interface State {
    Meetings: Array<IMeetingItem>,
    registrations: Array<any>,
    yearList: Array<any>,
    filterYear: string
}

export class MeetingListBlock extends React.Component<IMeetingListProps, any>{
    constructor(props) {
        super(props);
        this.state = { Meetings: [], registrations: [], yearList: [], filterYear: '' };

    }

    public componentDidMount() {
        this.getMeetings();
    }

    public shouldComponentUpdated() {
        this.getMeetings();
    }

    private getMeetings() {
        if (this.props.disableRegistrations) {
            $.getJSON('/api/events/GetMeetings?committee=' + this.props.committeeReference,
                function (data) {
                    this.setState({ Meetings: data["events"] });
                }.bind(this));
        } else {
            $.getJSON('/api/events/GetMeetingsWithRegistrations?committee='+this.props.committeeReference,
                function (data) {
                    var newMeetingState = data["events"];
                    var newRegistrationState = data["registrations"];
                    
                    for (var i = 0; i < newMeetingState.length; i++) {
                        for (var j = 0; j < newRegistrationState.length; j++) {
                            if (newRegistrationState[j].FormIdentifier.HostPageId == newMeetingState[i].Id) {
                                newMeetingState[i].IsRegistered = true;
                                newMeetingState[i].Form = newRegistrationState[j].FormIdentifier;
                            }
                        }
                        var tempDate = new Date(newMeetingState[i].startDate);

                        if (tempYearList.indexOf(dateFormatter.DateFormatter.Format(tempDate, "Y")) < 0) {

                            tempYearList.push(dateFormatter.DateFormatter.Format(tempDate, "Y").toString());
                        }

                    }

                    this.setState({ Meetings: newMeetingState, registrations: newRegistrationState, yearList: tempYearList.sort() });
                    var d = new Date();
                    var n = d.getFullYear();

                    if (this.state.yearList.indexOf(n.toString()) != -1) {
                        this.yearFilter(n);
                    } else {
                        this.yearFilter(this.state.yearList[this.state.yearList.length -1]);
                    }
                }.bind(this));
        }
        var tempYearList = [];

    }

    private changeRegistration(e) {
        var tempMeetingState = this.state.Meetings;
        for (var i = 0; i < tempMeetingState.length; i++) {
            if (tempMeetingState[i].Id == e) {
                tempMeetingState[i].IsRegistered = false;
            }

        }

        this.setState({ Meetings: tempMeetingState });

    }

    public yearFilter(year) {
        this.setState({filterYear:year});
    }

    public render() {
        //  1/3/18: Because this block is typically nested inside an accordian, it does not render an accordain
        // at mobile viewports like other tabbed components 
        return (
            <div className="eventBlock">
                {this.state.yearList.length > 0 ?
                    <div className="tab-content-block">
                        <ul className="nav nav-tabs">
                        {
                            
                                this.state.yearList.map((year, i) => {
                                    return (<li key={i} className="nav-item"><a className={year == this.state.filterYear ? "nav-link active" : "nav-link"} data-bs-toggle="tab"  onClick={ () => { this.yearFilter(year); } }>{year}</a></li>)
                                })
 
                            }

                        </ul>
                    </div> : ""}
                    {
                        this.state.Meetings.map((meeting, i) => {
                            var meetingDate = new Date(meeting.startDate);
                            if (this.state.filterYear == meetingDate.getFullYear() ||this.state.filterYear == '') {
                                return (
                                    <MeetingItem key={i} id={meeting.contentLink} name={meeting.name} startDate={meeting.startDate} eventCanceled={meeting.eventCanceled} isRegistered={meeting.isRegistered} linkURL={meeting.linkURL} registrationStatus={meeting.registrationStatus} registrationStatusMessage={meeting.registrationStatusMessage} disableRegistrations={this.props.disableRegistrations} changeRegistration={this.changeRegistration.bind(this)} registrationOpenDate={new Date(meeting.registrationOpenDate)} registrationCloseDate={new Date(meeting.registrationCloseDate)} allowRegistration={meeting.allowRegistration} form={meeting.registrationFormIdentifier}/>
                                )
                            }
                        })
                    }
                    {
                        (this.state.Meetings.length > 0 ?
                            <span></span> :
                            <div className='emptyListText'>There are no items to display for this selection.</div>)
                    }

                </div>
        )
    }
}

export class MeetingList extends React.Component<IMeetingListProps>  {

    public render() {
        return (
            <div className="meetinglistblock">
                <MeetingListHoc {...this.props} />
            </div>
            
        )
	}
}

interface IMeetingListHoc {}

export const MeetingListHoc = BlockBase<IMeetingListHoc>(MeetingListBlock,{});