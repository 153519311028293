import Paging, { IPagingProps } from '../components/Find/Paging';
import React from 'react';
import {IListOptions} from './List';

interface IListState {
}

export default function ListPager<TOriginalItemProps extends IPagingProps>(
        WrappedList: (React.ComponentClass), 
        options:IListOptions) { 
    return class Pager extends React.Component<TOriginalItemProps, IListState> {
        constructor(props) {
            super(props);
            this.state = { };
        } 

        public renderResultsBody() {
            if(this.props.Loading){
                return (<div className="processingIndicator row"><i className="fa fa-spin fa-refresh"></i></div>)
            } else if (!this.props[options.dataSetName] || !this.props[options.dataSetName].length) {
                return ( 
                    <div>
                        { this.props.NoResultsText || "Sorry, no results were found that match your query." }
                    </div>
                )
            } else {
                return (
                    <div>                  
                        <WrappedList {...this.props} {... {Loading: false}} />
                        { !this.props.Loading ? 
                        <div className="resultsRow">
                               <Paging { ...this.props} />
                        </div> : <div /> }
                    </div>
                )
            }
        }

        public render() {
            return (
                <div className="docnav-documents">                
                     {/*<div className={ "resultsRow " + ( this.props.Loading ? " disabledElement" : ""  ) } style={ this.props[options.dataSetName] && this.props[options.dataSetName].length ? null : { "display": "none" }  }>
                        <Paging { ...this.props} />
                    </div>            
                    <h2 className="margin-top-0 clear"></h2> */}
                    {this.renderResultsBody()}
                </div>);
        }

        
    }
}