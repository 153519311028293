import React from 'react';
import AccordionPanel from "./AccordionPanel";
import BlockBase from '../../hocs/BlockBase';
import { IPagingProps } from '../Find/Paging';
import {IBlockBaseProps} from '../../hocs/BlockBase';
import Moment from "moment";
import * as dateFormatter from '../../dateFormatter';
import ListPager from '../../hocs/ListPager'
import List from '../../hocs/List';

export interface AccordionPagingProps extends IPagingProps, IAccordionProps{}

export interface PanelItem {
    title?:string;
    content?:string;
    image?:string;
    imageType?: string;
    contentElement?: HTMLElement;
    keyInt?: number;
    filteredOut?: boolean;
};
interface IAccordionProps {
    accordionId: string;  
    useSinglePanelStyle: boolean;
    displayAccordion:boolean;
    openFirstPanel:boolean;
    panels: Array<PanelItem>;
    titleOverride?: string;
    contentOverride?: string;
    paging?: boolean;
    defaultPageSize?: number;
    secondaryTitle?: string;
    search?: boolean;
}
interface state {
    pageSize: number;
    page: number;
    totalAvailableResults: number;
    allpanels: Array<PanelItem>;
    panels: Array<PanelItem>;
    resultSetCount: number;
    searchText: string;
    nonFilteredPanels: Array<PanelItem>;
}
export class AccordionItem extends React.Component<AccordionPagingProps, state> {

    constructor(props) {
        super(props);
        this.state = { pageSize: 5, page: 0, totalAvailableResults: 0, allpanels:[], panels: [], resultSetCount:0, searchText: "", nonFilteredPanels: [] };

    }
    componentDidMount() {
        var allPanels = this.addKeyToPanels();
        var pageSize = this.props.defaultPageSize ? this.props.defaultPageSize : 5;
        this.setState({ totalAvailableResults: this.props.panels.length, allpanels: allPanels, nonFilteredPanels: allPanels, panels: allPanels.slice(0, pageSize), pageSize: pageSize, resultSetCount: pageSize });
    }
    componentDidUpdate(prevProps) {
        if (this.props != prevProps) {
            var allPanels = this.addKeyToPanels();
            this.setState({ totalAvailableResults: this.props.panels.length, allpanels: allPanels, nonFilteredPanels:allPanels, panels: allPanels.slice(0, this.state.pageSize), page: 0, resultSetCount: this.state.pageSize, searchText: "" });
            this.collapseAllPanels();
        }
    }

    private collapseAllPanels() {
        $('.show').removeClass('show');
        $('.accordion-link').addClass('collapsed');
    }

    private addKeyToPanels() {
        var allPanels = [];
        for (var i = 0; i < this.props.panels.length; i++) {
            var tempPanel = this.props.panels[i];
            if (!tempPanel.keyInt) {
                tempPanel.keyInt = i;
            }
            allPanels.push(tempPanel);
        }
        return allPanels;
    }
    public cleanTitle(title) {
        if (Moment(title, Moment.ISO_8601, true).isValid()) {
            return dateFormatter.DateFormatter.Format(title, "M/D/YYYY");
        }
        return title;
    }
    public OnPageChange(page) {
        var from = Math.ceil(page * this.state.pageSize);
        var to = Math.ceil(from + this.state.pageSize);
        this.setState({ panels: this.state.allpanels.slice(from, to), page:page })
    }

    public OnPageSizeChange(size) {
        var sizeParse = parseInt(size);
        var from = 0;
        var to = Math.ceil(from + sizeParse);
        var tempCount = sizeParse > this.state.totalAvailableResults ? this.state.totalAvailableResults : sizeParse
        this.setState({ panels: this.state.allpanels.slice(from, to), page: 0, resultSetCount: tempCount, pageSize: sizeParse })
    }


    public selectResultsPage(pageIndex) {
        var numPages = Math.ceil(this.state.totalAvailableResults / this.state.pageSize);

        if (pageIndex >= 0 && pageIndex < numPages && pageIndex != this.state.page) {
            this.setState({ page: pageIndex });
        }
    }

    private search(query: string) {
        this.setState({searchText: query})
        this.collapseAllPanels();
        if (query == '' || query == null) {
            // empty search, return all results
            var resultCount = this.state.nonFilteredPanels.length > this.state.pageSize ? this.state.pageSize : this.state.nonFilteredPanels.length
            this.setState({ panels: this.state.nonFilteredPanels.slice(0, this.state.pageSize), allpanels: this.state.nonFilteredPanels, totalAvailableResults: this.state.nonFilteredPanels.length, resultSetCount: resultCount, page: 0 })
        } else {
            // searching for a string
            var filteredPanels = this.state.nonFilteredPanels.filter(x => x[this.props.titleOverride] != undefined && x[this.props.titleOverride].toLowerCase().includes(query.toLowerCase()));
            var resultCount = filteredPanels.length > this.state.pageSize ? this.state.pageSize : filteredPanels.length
            this.setState({ panels: filteredPanels.slice(0, this.state.pageSize), allpanels: filteredPanels, totalAvailableResults: filteredPanels.length, resultSetCount: resultCount, page: 0})
        }
    }

    public render() {
        return (
            <div>
                {this.props.search &&
                    <div>
                        <div className="row" style={{ width: '50%', marginBottom: 5 }}>
                            <div className={ "col-12 searchPageRow input-group" }>            
                                <input id="accordionSrchTxt" className="form-control border rounded-start form-control-search" placeholder={"Search Notifications"} type="text" value={this.state.searchText} onChange={(e) => this.search(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                }
                <div className={"accordion-content" + (!this.props.displayAccordion ? " hidden-md hidden-lg" : "")}>
                    <div id={this.props.accordionId} className="panel-group accordian" role="tablist" aria-multiselectable="true">
                        {this.props.paging ?
                            <AccordionPanelList {...this.props} panels={this.state.panels} ResultSetCount={this.state.resultSetCount} TotalAvailableResults={this.state.totalAvailableResults} IncludePagingDescription={true} Page={this.state.page} PageSize={this.state.pageSize} OnPageChange={this.OnPageChange.bind(this)} OnPageSizeChange={this.OnPageSizeChange.bind(this)} />
                            : this.state.panels.map((panel, i) => {
                                return (
                                    <AccordionPanel key={i} panel={panel} openFirstPanel={this.props.openFirstPanel} accordionId={this.props.accordionId} contentOverride={this.props.contentOverride} titleOverride={this.props.titleOverride} secondaryTitle={this.props.secondaryTitle} />
                                )
                            })
                        }

                    </div>
                </div>
                {!this.props.displayAccordion ?
                    <div id={"" + this.props.accordionId + "Tabs"} className="tab-content-block visible-md visible-lg">
                        <ul className="nav nav-tabs">
                            {
                                this.state.panels.map((panel, i) => {
                                    var title = this.props.titleOverride ? this.cleanTitle(panel[this.props.titleOverride]) : panel.title;
                                    return (
                                        <li key={i} className="nav-item">
                                            <a className={(i == 0 ? "active" : "")} data-bs-toggle="tab" href={"#" + this.props.accordionId + "Tab" + i}> {title}</a>
                                        </li>)
                                })
                            }
                        </ul>
                        <div className="tab-content">
                            {
                                this.state.panels.map((panel, i) => {
                                    var content = this.props.contentOverride ? panel[this.props.contentOverride] : panel.content;
                                        
                                    return (
                                        <div key={i} id={"" + this.props.accordionId + "Tab" + i} className={"tab-pane fade " + (i == 0 ? "in active" : "")}>
                                            {
                                                panel.image && panel.imageType == "icon" ?
                                                    <div className="row">
                                                        <div className="col-4 col-md-2 cta-img">
                                                            <img src={panel.image} className="img-responsive" />
                                                        </div>
                                                        <div className="col-8 col-md-10">
                                                            {content}
                                                        </div>
                                                    </div>
                                                    : panel.image && panel.imageType == "image" ?
                                                        <div className="row">
                                                            <div className="col-12 col-md-6 cta-img pull-right">
                                                                <img src={panel.image} className="img-responsive" />
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                {content}
                                                            </div>
                                                        </div>

                                                        :
                                                        <div className="col-12">{content}</div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>         
                :<span></span>
                }
            </div>
        )
	}
}


export class Accordion extends React.Component<IAccordionProps, IBlockBaseProps> {
    public render() {
        return (
            <AccordionBlock {...this.props} />
        )
	}
}

interface IAccordionBlockProps {}

export const AccordionBlock = BlockBase<IAccordionBlockProps>(AccordionItem, {});


let listOptions = {
    className: "accordion-content",
    dataSetName: "panels",
    dataItemPropName: "panel",
};
const AccordionPanelList = ListPager<AccordionPagingProps>(List<PanelItem>(AccordionPanel, listOptions), listOptions);
export default AccordionPanelList;