import React from 'react';
import BlockBase from '../../hocs/BlockBase';
import {IBlockBaseProps} from '../../hocs/BlockBase';

interface LinkItem {
    url:string;
    text:string;
    subHeader?:string;
    icon?: string;
    openInNewTab: boolean;
}
export interface IRelatedPagesProps extends IBlockBaseProps {
    relatedPages: Array<LinkItem>
}

interface state {}
export class RelatedPagesItem extends React.Component<IRelatedPagesProps, state> {
    constructor(props) {
        super(props);
        this.state={};
    }
    public render() { 
        return (
            <div className="linkList list-group list-group-flush">
                {this.props.relatedPages ?
                    this.props.relatedPages.map((page, i) => {
                        return (
                            <a className="relatedLink list-group-item" key={i} target={page.openInNewTab ? "_blank" : "_self"} href={page.url}>
                                <span className="primaryText">{page.text} {page.icon ? <i className={"fa " + page.icon + " margin-left-5"}> </i> : ""}</span>
                            {page.subHeader ? 
                                <span className="linkSubHeader">{page.subHeader}</span>
                            : ""}
                        </a>
                    )
                    
                })
                : <div>There are no Related Pages for this page.</div>
                }
            </div>
        )
	}
}


export class RelatedPages extends React.Component<IRelatedPagesProps, IBlockBaseProps> {
    public render() {
        return (
            <div className="relatedpagesblock">
                {this.props["relatedPages"] ?
                    <RelatedPagesBlock {...this.props} />
                :""}
            </div>
            
        )
	}
}

interface IRelatedPagesBlockProps {}

export const RelatedPagesBlock = BlockBase<IRelatedPagesBlockProps>(RelatedPagesItem,{});