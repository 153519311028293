import React from 'react';

interface ActionLinks {
    actionString:string;
    actionLink:string;
    actionIcon:string;
}

export interface IInfoBoxProps {
    headerTitleString?: string,
    titleString: string,
    titleLink?: string,
    titleIcon?: string,
    subTitleString:string,
    subTitleLink?:string,
    subTitleIcon?:string,
    actionLinkList?: Array<ActionLinks>,
    dangerousActionLinkList?: Array<string>
}


export class InfoBox extends React.Component<IInfoBoxProps> {
    public render() {
        return (
            <div className="infoBox">
                <div className="info">
                { this.props.headerTitleString ? 
                    <h5>{this.props.headerTitleString}</h5>
                : ""}
                    <div className="infoItem">
                        {this.props.titleLink ?
                            <a target="_blank" href={this.props.titleLink}>{this.props.titleIcon ? <i className={this.props.titleIcon}></i> : ''} <span dangerouslySetInnerHTML={{ __html: this.props.titleString }}></span></a>
                            : this.props.titleIcon ? <i className={this.props.titleIcon}></i> : '' + this.props.titleString }
                    </div>
                    <div className="inlineArea align-items-center">
                        <div className="leftSide">
                            { this.props.subTitleLink ?
                                <a href={this.props.subTitleLink} >
                                    
                                    <span dangerouslySetInnerHTML={{ __html: this.props.subTitleString }}>
                                    </span>
                                </a>
                                : <span dangerouslySetInnerHTML={{ __html: this.props.subTitleString }}>
                                </span> }
                        </div>
                        <div className="rightSide">
                            {this.props.actionLinkList ? this.props.actionLinkList.map((actionLink, i) => {
                                return (
                                    <a key={i} href={actionLink.actionLink} className="leftIcon" >
                                        {actionLink.actionIcon ? <i className={"fa " + actionLink.actionIcon}></i> : ""}
                                        {actionLink.actionString}
                                    </a>)
                            }) : ""}
                            {this.props.dangerousActionLinkList ? this.props.dangerousActionLinkList.map((dangerLink, i) => {
                                return (
                                    <span key={i} className="rightIcon" dangerouslySetInnerHTML={{ __html: dangerLink }}>
                                    </span>)
                            }) :""}
                                
                        </div>
                    </div>
                </div>
            </div>
        )
	}
}

