export interface IModalButton { name: string, func: (any?) => void, params: any, btnClass?: string }
export interface IModalInput { label: string, inputId?: string, inputType?: string }

export class Modal {

    private static GenerateInput(input?: IModalInput) {
        if (input === undefined || input === null) {
            return '';
        }

        if (input.inputId == null || input.inputId === undefined) input.inputId = 'modalInput';
        if (input.inputType == null || input.inputType === undefined) input.inputType = 'text';

        return `
            <br />
            <div style="width: 100%;">
                <label class="bold padding-btm-5" for="${input.inputId}">${input.label}</label>
                <input class="textInput" style="width: 100%" type="${input.inputType}" id="${input.inputId}">
            </div>
        `;
    }

    public static Display(message: string, options: Array<IModalButton>, input?: IModalInput) {
        var modal = $(
            `<div class="modal fade" id="myModal" role="dialog">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header"><button type="button" class="btn-close" id="closeModal" data-bs-dismiss="modal" aria-label="Close"></button></div>
                        <div class="modal-body message row">
                            <div class="col-12">
                                <div class="message-text"><span>${message}</span></div>
                                ${this.GenerateInput(input)}
                            </div>
                        </div>
                        <div class="modal-footer"></div>
                    </div>
                </div>
            </div>`
        );

        // Apply defaults to any options not provided
        if (typeof options != "undefined") {
            var buttonCount = (options.length > 2 ? 2 : options.length);
            for (var i = 0; i < buttonCount; i++) {
                var option = options[i];

                if (option.btnClass == null) {
                    option.btnClass = "btn-primary";
                }

                var btn = $('<button type="button" class="btn btn-small ' + option.btnClass + ' modal-button" data-bs-dismiss="modal" data-button-id=' + i + '>' + option.name + '</button>');
                btn.on('click', function () {
                    var i = $(this).data("buttonId");
                    if (typeof options[i].func == "function") {
                        options[i].func(options[i].params);
                    }
                });

                modal.find(".modal-footer").append(btn);
            }
        } else {
            var btn = $('<button type="button" class="btn btn-small btn-primary" data-bs-dismiss="modal">Close</button>');
            modal.find(".modal-footer").append(btn);
        };

        $(modal).modal('show');
    }
}


