import React from 'react';
import * as ReactDOM from "react-dom";
import ContentAlertItem from './ContentAlertItem';
import {IContentAlertItem} from './ContentAlertItem';

export enum ContentAlertListMode {
	Full = 0,
	Dropdown = 10
}

export interface IContentAlertListProps { endpoint: string; mode: ContentAlertListMode }
interface ISourceNameMapping { sourceName: string, contentTypeId: string, displayTitle: string }
interface IContentAlertGrouping { displayTitle: string; alerts: Array<IContentAlertItem> }

class ContentAlertGrouping implements IContentAlertGrouping {
	 displayTitle: string; alerts: Array<IContentAlertItem>;
	 constructor(displayTitle: string, alerts?: Array<IContentAlertItem>) { this.displayTitle = displayTitle; this.alerts = alerts || new Array<IContentAlertItem>(); }
}

interface State {
	alerts: Array<IContentAlertItem>;
	numberOfUnseenAlerts: number;
	fullAlertsListUrl: string;
	sourceNameMappings: Array<ISourceNameMapping>;
	groupedAlerts: Array<IContentAlertGrouping>;

}

export class ContentAlertList extends React.Component<IContentAlertListProps, State> {
	constructor(props) {
		super(props);
		this.state = { alerts: [], numberOfUnseenAlerts: 0, fullAlertsListUrl: '', sourceNameMappings: [], groupedAlerts: [] };
	}

	 public componentDidMount() {
		 if (this.props.mode != ContentAlertListMode.Full) {
			 $(ReactDOM.findDOMNode(this)).parent().css("display", "inline-block");
		 }
		 this.getActiveContentAlertData();
	}

	 private getActiveContentAlertData() {
		 var maxitems: string = "";
		 if (this.props.mode != ContentAlertListMode.Full) {
			 maxitems = "?maxItems=5";
		 }
		 return $.getJSON(this.props.endpoint + "GetActiveContentAlertData" + maxitems,
			function (data) {
				this.setState(data);
				this.setState({ groupedAlerts: this.groupAlerts() });
				if (this.props.mode == ContentAlertListMode.Full) {
					this.userCheckedAlerts();
				}
			}.bind(this));
	 }

	 private userCheckedAlerts() {
		 $.ajax({
			 url: this.props.endpoint + "UserCheckedAlerts",
			 type: 'POST',
			 //dataType: 'json',
			// headers: {
			//	 'Accept': 'application/json',
			//	 'Content-Type': 'application/json'
			// },
			 success: function (data) { this.setState({ numberOfUnseenAlerts: 0 }) }.bind(this),
			 error: function (jqxhr, status, err) { console.log('an error occured when attempting to mark alerts as seen' + err); }.bind(this)
		 });
	 }

	 private groupAlerts() {
		 var groupedAlerts = new Array<IContentAlertGrouping>();
		 var contentTypeMaps:Array<ISourceNameMapping> = this.state.sourceNameMappings.filter(function (mapping: ISourceNameMapping) { return mapping.contentTypeId; });
		 var sourcesWithContentTypeMaps: { [SourceName: string]: Array<string> } = {};
		 var mapping: ISourceNameMapping;

		 for (var i = 0; i < this.state.sourceNameMappings.length; i++) {
			 mapping = this.state.sourceNameMappings[i];
			 if (mapping.contentTypeId) {
				 if (sourcesWithContentTypeMaps[mapping.sourceName]) {
					 sourcesWithContentTypeMaps[mapping.sourceName].push(mapping.contentTypeId.toLowerCase());
				 }
				 else
				 {
					 sourcesWithContentTypeMaps[mapping.sourceName] = [ mapping.contentTypeId.toLowerCase() ];
				 }}
			 }

		 for(var i = 0; i < this.state.sourceNameMappings.length; i++) {
			 mapping = this.state.sourceNameMappings[i];
			 groupedAlerts.push(new ContentAlertGrouping(mapping.displayTitle, this.state.alerts.filter(function (alert: IContentAlertItem) {
				 return (mapping.contentTypeId && alert.alert.subscription.contentTypeId && alert.alert.subscription.source
						&& alert.alert.subscription.contentTypeId.toLowerCase() === mapping.contentTypeId.toLowerCase() && alert.alert.subscription.source.toLowerCase() === mapping.sourceName.toLowerCase())
					 || (!(sourcesWithContentTypeMaps[mapping.sourceName] && alert.alert.subscription.contentTypeId && sourcesWithContentTypeMaps[mapping.sourceName].indexOf(alert.alert.subscription.contentTypeId) >= 0)
					 && !(mapping.contentTypeId) && alert.alert.subscription.source && alert.alert.subscription.source.toLowerCase() === mapping.sourceName.toLowerCase());
			 })));
		 }

		 return groupedAlerts;
	 }

	private renderAlerts(name: string, alerts: Array<IContentAlertItem>) {
		if (!alerts || alerts.length === 0) {
			return (<div>There are no recent alerts for your followed {name}.</div>);
		}
		if (!this.state.groupedAlerts || this.state.groupedAlerts.length === 0) {
			return (<div>There are no recent alerts for your followed {name}.</div>);
		}
		return (
			<div className="content-alertlist">
				{this.props.mode === ContentAlertListMode.Dropdown && (
					<h5><i className="fa fa-bell-o margin-right-5 dark-blue"></i>Followed Content Alerts</h5>
				)}
				{
					alerts.map((subscriberAlert: IContentAlertItem, i) => {
						var contentType = '';
						this.state.groupedAlerts.forEach((group) => {
							if (group.alerts.find(x => x.alert.subscription.contentId === subscriberAlert.alert.subscription.contentId)) {
								contentType = group.displayTitle.substring(0, group.displayTitle.length - 1);
							}
						});
						return (<ContentAlertItem key={i} parentProps={this.props} alert={subscriberAlert.alert} result={subscriberAlert.result} index={i} contentType={contentType} />);
					})
				}
			</div>
		);
	 }

     public render() {
		 if (this.props.mode == ContentAlertListMode.Full && this.state.groupedAlerts && this.state.groupedAlerts.length > 0) {
			return (<div>
				{
				this.state.groupedAlerts.map((alertGroup, i) => {
					return (
						<div className="content-alertgroup" key={i}>
							<h3>{alertGroup.displayTitle}</h3>
							{ this.renderAlerts(alertGroup.displayTitle, alertGroup.alerts) }
						</div>
					);
					})
				} </div>
			);
		} else {
			return (
				<div className="dropdown">
					<a className="dropdown-toggle navbar-secondary-link" type="button" data-bs-toggle="dropdown" data-bs-offset="-250, 12" aria-expanded="false" onClick={() => this.userCheckedAlerts()}>
						<span className="fa fa-bell-o"></span>
						<span className="badge" {... !this.state.numberOfUnseenAlerts ? { style: { display: "none" } } : null}>{this.state.numberOfUnseenAlerts}</span>
					</a>
					<div className="dropdown-menu alerticon-dropdown" role="menu">
					{ this.renderAlerts("Content", this.state.alerts) }
						<p><a href={this.state.fullAlertsListUrl} className="content-alert-link bold">View All <i className="fa fa-angle-right"></i></a></p>
					</div>
				</div>
			);
		}
	}
}