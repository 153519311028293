import React from 'react';
import TariffPanel, {ITariffPanel} from "./TariffPanel";

interface ITariffTabProps {findServiceUrl:string, panels: Array<ITariffPanel>, tariffDocTypeChoices: Array<ITariffDocTypeConfiguration>, disableFollowing:boolean  }
interface ITariffDocTypeConfiguration { value: string, text: string, description: string }
interface State { activeTab: number; componentId: string }

export class TariffTabs extends React.Component<ITariffTabProps, State>{

    constructor(props){
        super(props);
        //this.state = { activeTab: 0, componentId: this.newComponentId() } 
        this.state = { activeTab: 0, componentId: "" } 
        this.updateTab = this.updateTab.bind(this);
    }

    private docTypeConfigs: { [id:string] : ITariffDocTypeConfiguration; };
    private getSafeDocTypeConfig(panelDocType:string): ITariffDocTypeConfiguration {
        if (!this.docTypeConfigs) {
            this.docTypeConfigs = {};
            if (this.props.tariffDocTypeChoices && this.props.tariffDocTypeChoices.length) {
                this.props.tariffDocTypeChoices.forEach(config => {
                    this.docTypeConfigs[config.value] = config;
                });
            }   
        }
        return this.docTypeConfigs[panelDocType] 
            || { value: panelDocType, text: panelDocType, description: "Unknown Tariff Document Type" };
    }

    //private newComponentId() {
    //    return 'xxxxxxxx_xxxx_4xxx_yxxx_xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    //        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
    //        return v.toString(16);
    //    });
    //}

    private updateTab(tabId: number){
        window.setTimeout(()=>{this.setState({activeTab: tabId});}, 250);
    }

    private renderTabs() {
        return (            
            <div className="tab-content-block tariff-tabs">
                <ul className="nav nav-tabs d-none d-lg-flex">
                    {
                        this.props.panels.map((panel, i) => {
                            var docTypeConfig = this.getSafeDocTypeConfig(panel.tariffDocumentType);
                            return (
                                <li key={i} className="nav-item">
                                    <a className={i == this.state.activeTab ? "nav-link active" : "nav-link"} data-bs-toggle="collapse" data-bs-target={"Accordion" + "Collapse" + this.state.activeTab } onClick={() => {this.updateTab(i)}}>{docTypeConfig.text}</a>
                                </li>
                            )
                        })
                    }
                </ul>
                
                <div className="tab-content accordion-content">
                        <div className="panel-group" id={"Accordion"} role="tablist" aria-multiselectable="true">
                        {
                            this.props.panels.map((panel,i) => {
                                var docTypeConfig = this.getSafeDocTypeConfig(panel.tariffDocumentType);
                                return (
                                    <TariffPanel key={i} activeTab={this.state.activeTab} findServiceUrl={this.props.findServiceUrl} tariffTabId={this.state.componentId} tariffDocTypeName={docTypeConfig.value} tariffDocTypeTitle={docTypeConfig.text} tariffDocTypeDescription={docTypeConfig.description} tabId={i} updateTab={this.updateTab} disableFollowing={this.props.disableFollowing} />                                    
                                )
                            })
                        }
                    </div>
                </div>                
            </div> 
        )               
    }

    render() {
        return(this.renderTabs())
    }
}