import React from 'react';
import ReactDOM from "react-dom";
import StakeholderFeedbackItem from "./StakeholderFeedbackItem";
import * as dateFormatter from '../../dateFormatter';
declare var kendo: any;

export interface IStakeholderFeedbackListProps {list:string}
interface State {
    feedback: Array<SRFeedbackPages>,
    entity: string,
    topic: string,
    fromDate: Date,
    toDate: Date,
    active: string,
    entityList: Array<any>,
    topicList: Array<any>,
    filterItems: Array<any>,
    yearList: Array<any>,
    activeYear: string

}
interface SRFeedbackPages {
    committeeReferences: Array<string>,
    dueDate: Date,
    topics: Array<string>,
    name: string,
    description: string,
    linkURL: string,
    contentLink:string
}

const LOCAL_STORAGE_FILTER_PREFIX = 'stakeholder-feedback-';

export class StakeholderFeedbackList extends React.Component<IStakeholderFeedbackListProps, any>{
    private startDateRef: React.RefObject<HTMLInputElement>;
    private endDateRef: React.RefObject<HTMLInputElement>;
    private startDatePicker: any;
    private endDatePicker: any;

    constructor(props) {
        super(props);
        this.state = { feedbacks: [], entity: 'All', topic: 'All', fromDate: '', toDate: '', active: 'Active', entityList: [], topicList: [], filterItems: [], yearList: [], activeYear: '', loading: true };

        this.startDateRef = React.createRef();
        this.endDateRef = React.createRef();

        this.findLocallyStoredFilterValue = this.findLocallyStoredFilterValue.bind(this);
        this.findPreviousFilters = this.findPreviousFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.clearDatePickers = this.clearDatePickers.bind(this);
        this.getKendoDateValue = this.getKendoDateValue.bind(this);
        this.handleKendoFromDateChange = this.handleKendoFromDateChange.bind(this);
        this.handleKendoToDateChange = this.handleKendoToDateChange.bind(this);
    }

    public componentDidMount() {
        this.initKendo();
        this.findPreviousFilters();
        this.getFeedback();
    }

    private findLocallyStoredFilterValue(filterKey) {
        return localStorage.getItem(`${LOCAL_STORAGE_FILTER_PREFIX}${filterKey}`);
    }
    private findPreviousFilters() {
        let entityFilter: string = this.findLocallyStoredFilterValue('entity');
        let topicFilter: string = this.findLocallyStoredFilterValue('topic');
        let fromDateFilter: string = this.findLocallyStoredFilterValue('fromDate');
        let toDateFilter: string = this.findLocallyStoredFilterValue('toDate');
        let activeLevelFilter: string = this.findLocallyStoredFilterValue('active');
        let selectedYearFilter: string = this.findLocallyStoredFilterValue('activeYear');

        this.setState({
            entity: entityFilter ?? 'All',
            topic: topicFilter ?? 'All',
            fromDate: fromDateFilter ?? '',
            toDate: toDateFilter ?? '',
            active: activeLevelFilter ?? 'Active',
            activeYear: selectedYearFilter ?? new Date().getFullYear().toString()
        });
    }

    private preserveFilter(filterKey, filterValue) {    
        localStorage.setItem(`${LOCAL_STORAGE_FILTER_PREFIX}${filterKey}`, filterValue);
    }
    private removeAllFiltersFromLocalStorage() {
        localStorage.removeItem(`${LOCAL_STORAGE_FILTER_PREFIX}entity`);
        localStorage.removeItem(`${LOCAL_STORAGE_FILTER_PREFIX}topic`);
        localStorage.removeItem(`${LOCAL_STORAGE_FILTER_PREFIX}fromDate`);
        localStorage.removeItem(`${LOCAL_STORAGE_FILTER_PREFIX}toDate`);
        localStorage.removeItem(`${LOCAL_STORAGE_FILTER_PREFIX}active`);
        localStorage.removeItem(`${LOCAL_STORAGE_FILTER_PREFIX}activeYear`);
    }

    private getFeedback() {
        var url = '/api/ClosedCommitteeFeedback/GetClosedCommitteeFeedback';
        if (this.props.list == "Stakeholder Feedback") {
            url = '/api/Feedback/GetStakeHolderFeedback';
        } 

        $.getJSON(url,
            function (data) {
                var pages = data.feedbackPages;
                var tempEntities = [];
                var tempTopics = [];
                var nowDate = new Date();
                var tempYearList = [];
                var activeYear = dateFormatter.DateFormatter.Format(Date.now(), "YYYY");
                for (var i = 0; i < pages.length; i++) {
                    if (pages[i].committeeNames) {
                        for (var j = 0; j < pages[i].committeeNames.length; j++) {
                            if (tempEntities.indexOf(pages[i].committeeNames[j]) == -1) {
                                tempEntities.push(pages[i].committeeNames[j]);
                            }
                        }
                    } else {
                        pages[i].committeeNames = [];
                    }

                    var expireDate = new Date(pages[i].dueDate);

                    pages[i].active = nowDate > expireDate ? "Expired" : "Active";
                    if (pages[i].topics) {

                        for (var j = 0; j < pages[i].topics.length; j++) {
                            if (tempTopics.indexOf(pages[i].topics[j]) == -1) {
                                tempTopics.push(pages[i].topics[j]);
                            }
                        }
                    } else {
                        pages[i].topics = [];
                    }
                    var tempYear = dateFormatter.DateFormatter.Format(pages[i].dueDate, "YYYY");
                    if (tempYearList.indexOf(tempYear) == -1) {
                        tempYearList.push(tempYear);
                    }
                }
                tempYearList.sort();
                tempEntities.sort();
                tempTopics.sort();

                if (tempYearList.indexOf(activeYear) == -1) {
                    activeYear = tempYearList.slice(-1).toString();
                }
                pages = pages.sort(function (a, b) {
                    a = new Date(a.dueDate);
                    b = new Date(b.dueDate);
                    return a > b ? 1 : a < b ? -1 : 0;
                });
                this.setState({ feedbacks: pages, entityList: tempEntities, topicList: tempTopics, yearList: tempYearList,loading:false });
            }.bind(this));
    }

    private initKendo() {
        const startDate = $(ReactDOM.findDOMNode(this.startDateRef.current)).kendoDatePicker({
            value: this.getKendoDateValue('fromDate'),
            change: this.handleKendoFromDateChange,
        }).data("kendoDatePicker");

        const endDate = $(ReactDOM.findDOMNode(this.endDateRef.current)).kendoDatePicker({
            value: this.getKendoDateValue('toDate'),
            change: this.handleKendoToDateChange,
        }).data("kendoDatePicker");

        // Store references to the date pickers
        this.startDatePicker = startDate;
        this.endDatePicker = endDate;
    }

    private getKendoDateValue(filterKey) {
        let storedDateValue = this.findLocallyStoredFilterValue(filterKey);
        return storedDateValue ? new Date(storedDateValue) : null;
    }
    private handleKendoFromDateChange(e) {
        var date = e.sender.value();
        this.setState({ fromDate: date });
        this.preserveFilter('fromDate', date);
    }
    private handleKendoToDateChange(e) {
        var date = e.sender.value();
        this.setState({ toDate: date });
        this.preserveFilter('toDate', date);
    }

    public filterYear(year) {
        this.setState({ activeYear: year });
        this.preserveFilter('activeYear', year);
    }
    public filterEntity(entity) {
        this.setState({ entity: entity });
        this.preserveFilter('entity', entity);
    }
    public filterTopic(topic) {
        this.setState({ topic: topic });
        this.preserveFilter('topic', topic);
    }
    public filterActive(active) {
        this.setState({ active: active });
        this.preserveFilter('active', active);
    }
    public clearFilters() {
        this.setState({
            entity: 'All',
            topic: 'All',
            fromDate: '',
            toDate: '',
            active: 'Active',
            activeYear: new Date().getFullYear().toString()
        });
        this.clearDatePickers();
        this.removeAllFiltersFromLocalStorage()
    }
    public clearDatePickers() {
        if (this.startDatePicker) {
            this.startDatePicker.value(null);
        }

        if (this.endDatePicker) {
            this.endDatePicker.value(null);
        }
    }
    public filtersAreDefault() {
        return this.state.entity === 'All' &&
            this.state.topic === 'All' &&
            this.state.fromDate === '' &&
            this.state.toDate === '' &&
            this.state.active === 'Active';
    }
    public getFilteredFeedbackItems() {
        return this.state.feedbacks.filter(feedback => {
            return (this.state.entity === 'All' || this.state.entity === '' || feedback.committeeNames.indexOf(this.state.entity) > -1)
                && (feedback.topics.indexOf(this.state.topic) > -1 || this.state.topic === 'All' || this.state.topic === '')
                && (new Date(feedback.dueDate) > this.state.fromDate || this.state.fromDate === '' || !this.state.fromDate)
                && (new Date(feedback.dueDate) < this.state.toDate || this.state.toDate === '' || !this.state.toDate)
                && (feedback.active === this.state.active || this.state.active === 'All' || this.state.active === '')
                && (new Date(feedback.dueDate).getFullYear().toString() === this.state.activeYear);
        });
    }

    public render() {
        return (
            <div id='stakeholdFeedback'>
                <h3 className='blue margin-top-0 margin-btm-20'>
                    Filter Feedback By
                    {!this.filtersAreDefault() &&
                        <button className="btn btn-small btn-secondary" onClick={this.clearFilters} type="button" style={{ float: "right", padding: "10px 20px" }}>
                            <i className="fa fa-remove"></i>&nbsp;&nbsp;Reset Filters
                        </button>
                    }
                </h3>
                <div className="row margin-top-10 margin-btm-30">
                    <div className='col-12 col-md-3'>
                        <label><h4>Entity</h4></label>
                        <div className="dropdown">

                            <button className="btn-secondary form-select text-start" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                {this.state.entity}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <button className="btn btn-link dropdown-item" onClick={() => { this.filterEntity('All'); } }>All</button>
                                {
                                    this.state.entityList.map((entity, i) => {
                                        return (
                                            <button key={i} value={entity} className="btn btn-link dropdown-item" onClick={() => { this.filterEntity(entity); } }>{entity}</button>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                        <div className="dropdown">
                            <label><h4>Topic</h4></label>
                            <div className="dropdown">

                                <button className="btn-secondary form-select text-start" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    {this.state.topic}
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <button className="btn btn-link dropdown-item" onClick={() => { this.filterTopic('All'); } }>All</button>
                                    {
                                        this.state.topicList.map((topic, i) => {
                                            return (
                                                <button key={i} value={topic} className="btn btn-link dropdown-item" onClick={() => { this.filterTopic(topic); } }>{topic}</button>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-2'>
                        <div className="dropdown">
                            <label><h4>From Date</h4></label>
                            <input ref={this.startDateRef} className="startDate form-control" />
                        </div>
                    </div>
                    <div className='col-12 col-md-2'>
                        <div className="dropdown">
                            <label><h4>To Date</h4></label>
                            <input ref={this.endDateRef} className="endDate form-control"/>
                        </div>
                    </div>
                    <div className='col-12 col-md-2'>
                        <div className="dropdown">
                            <label><h4>Active/Expired</h4></label>
                            <div className="dropdown">
                                <button className="btn-secondary form-select text-start" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    {this.state.active}
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <button className="btn btn-link dropdown-item" onClick={() => { this.filterActive('All'); } }>All</button>
                                    <button className="btn btn-link dropdown-item" onClick={() => { this.filterActive('Active'); } }>Active</button>
                                    <button className="btn btn-link dropdown-item" onClick={() => { this.filterActive('Expired'); } }>Expired</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-content-block margin-btm-20">
                    <ul className="nav nav-tabs">
                        {
                            this.state.yearList.map((year, i) => {
                                return (<li key={i} className="nav-item"><a className={year == this.state.activeYear ? "nav-link active" : "nav-link"}  data-bs-toggle="tab" href={'#' + year } onClick={ () => { this.filterYear(year); } }>{year}</a></li>)
                            })
                        }
                    </ul>
                </div>
                <div className="accordionblock">
                    {this.state.loading &&
                        <div className="processingIndicator row margin-top-30"><i className="fa fa-spin fa-refresh"></i></div>
                    }
                    <div className="accordion-content">
                        <div className="accordion panel-group labelBorderTop" id="accordion">

                            {
                                this.getFilteredFeedbackItems().map((feedback, i) => {  
                                    return (
                                        <StakeholderFeedbackItem key={i} Name={feedback.name} DueDate = { feedback.dueDate } Description = {feedback.description} Url={feedback.linkURL} Id={feedback.contentLink} />
                                    )                  
                                })

                            }
                            {
                                this.getFilteredFeedbackItems().length == 0 && !this.state.loading ? <div className='panel'><div className='panel-heading text-left noItems'>There are no items that match this criteria</div></div> : <div></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
