import React from 'react';
import { Modal, IModalButton, IModalInput } from '../modal';

interface IBlockBaseState {
}
export interface IBlockBaseProps {
    showIntegratedBorder?: boolean;
    integrateHeader?: boolean;
    headerColor?: string;
    backgroundColor?: string;
    backgroundImage?:string;
    backgroundImageString?: string;
    backgroundImagePosition?: string;
    showHorizontalDivider?:boolean;
    textAlignment?:string;
    integrateHeaderString?:string;
    integrateSubHeaderString?:string;
    integrateHeaderLink?:string;
    integrateHeaderText?: string;
    allowCompressedDownload?: boolean;
}
export interface IListOptions  {
    className?: string;
    dataSetName?: string;
    dataItemPropName?: string;
    dateFieldName?: string;
    selectionLabel?: string;
}

declare var ga: any;

export default function BlockBase<TOriginalItemProps extends IBlockBaseProps>(
        ItemComponent: (React.ComponentClass | React.StatelessComponent), 
        options:IListOptions) { 
    return class BlockBase extends React.Component<TOriginalItemProps, IBlockBaseState> {
        constructor(props) {
            super(props);
            //this.state = { };
        }

        private showDownloadAllModal() {
            const btns: Array<IModalButton> = [{
                name: 'Request Files',
                func: () => {
                    this.downloadCompressedFile(jQuery('#modalInput').val().toString());
                },
                params: null
            },
            {
                name: 'Cancel',
                func: () => { $('#myModal').modal('hide') },
                params: null,
                btnClass: 'btn-default'
            }];

            const modalInput: IModalInput = {
                label: 'Email Address: ',
                inputId: 'modalInput',
                inputType: 'text'
            };

            const modalText = "Enter your email address to receive a link to download the files.";

            Modal.Display(modalText, btns, modalInput)
        }

        private downloadCompressedFile(userEmail: string) {
            let serializedFileUrls = "";
            let fileUrls = [];

            this.props[options.dataSetName].map((dataItem, dataItemIndex: number) => {
                if (typeof dataItem.GetDocumentUrl === 'function') {
                    serializedFileUrls += dataItem.GetDocumentUrl() + "|";
                    fileUrls.push(dataItem.GetDocumentUrl());
                }
            });

            var pageTitle = $(document).attr('title');

            $.ajax({
                url: '/api/documents/RequestZipDocsByEmail',
                type: 'POST',
                data: JSON.stringify({ userEmail, fileUrls, pageTitle }),
                dataType: 'json',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                success: function (data) {
                    if (data === "Success") {
                        Modal.Display("Your request to zip the files has been accepted. Watch your email for the download link. This may take several minutes depending on the size of the files requested.",
                            [{
                                name: 'Ok',
                                func: () => { },
                                params: null
                            }]
                        )
                    } else {
                        Modal.Display("There was an error processing your request. Please try again, or reach out to <a href='mailto:stakeholderrelations@misoenergy.org'>Stakeholder Relations</a> for assistance.",
                            [{
                                name: 'Ok',
                                func: () => { },
                                params: null
                            }]
                        )
                    }
                },
                error: function (err) {
                    console.log("this is to be checked after epi12 upgrade");
                    Modal.Display("There was an error processing your request. Please try again, or reach out to <a href='mailto:stakeholderrelations@misoenergy.org'>Stakeholder Relations</a> for assistance.",
                        [{
                            name: 'Ok',
                            func: () => { },
                            params: null
                        }]
                    )
                }
            });

            ga('send', 'event', 'ZippedFiles', 'download', serializedFileUrls);
        }

        public render() {
            const backgroundImagePosition = (this.props.backgroundImagePosition ? this.props.backgroundImagePosition : "fullwidth");
            return (
                <div className={"hocblockbase" + " " + (this.props.showIntegratedBorder ? "withBorder" : "") + " " + (this.props.backgroundColor ? this.props.backgroundColor : '') + " " + (this.props.textAlignment ? this.props.textAlignment : "") + " " + backgroundImagePosition + " " + (this.props.showHorizontalDivider ? "horizontalDivider" : "")} style={{ backgroundImage: this.props.backgroundImageString && backgroundImagePosition == 'fullwidth' ? "url(" + this.props.backgroundImageString + ")" : 'none' }}>
                    {this.props.integrateHeader ? 
                        <div className={"integratedHeader " + (this.props.headerColor ? this.props.headerColor : "blue-bg")}>
                            <div className="leftSide">
                                <h4>{this.props.integrateHeaderString}</h4>
                                {this.props.integrateSubHeaderString ?
                                    <h5>{this.props.integrateSubHeaderString}</h5> 
                                :""}
                            </div>
                            <div className="rightSide">
                                {this.props.allowCompressedDownload === true ? <button className="btn btn-link dark-blue padding-0" onClick={() => this.showDownloadAllModal()}><i className="fa fa-download margin-right-10" aria-hidden="true"></i>Download All</button> : <span></span>}
                                {this.props.integrateHeaderText && this.props.integrateHeaderLink ?
                                     <h4><a className="btn btn-link" href={this.props.integrateHeaderLink} target="_blank">
                                        {this.props.integrateHeaderText}
                                    </a></h4>
                                : "" }
                            </div>
                        </div>
                    : <div></div>}
                    <div className="reactBlockBody">
                        <ItemComponent {...this.props}/>
                    </div>                
                    
                </div>);
        }
    }
}

