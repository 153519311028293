import {IChoiceItem} from "./ConfigurableChoice";

export class Api {

	private static issuesLegendListPromise: JQueryXHR;
	public static getIssuesLegendLists(choices: string, callback: JQueryPromiseCallback<any>) {
		if (Api.issuesLegendListPromise == null) {
            Api.issuesLegendListPromise = $.getJSON("/api/ConfigurableChoices/GetIssueLegendLists");
		}

		Api.issuesLegendListPromise.done(callback);
    }
    private static giQueueLegendListPromise: JQueryXHR;
    public static getGiQueueLegendLists(choices: string, callback: JQueryPromiseCallback<any>) {
        if (Api.giQueueLegendListPromise == null) {
            Api.giQueueLegendListPromise = $.getJSON("/api/ConfigurableChoices/GetGIQueueLegendLists");
        }

        Api.giQueueLegendListPromise.done(callback);

    }
    private static TTFQueueLegendListPromise: JQueryXHR;
    public static GetTTFQueueLegendLists(choices: string, callback: JQueryPromiseCallback<any>) {
        if (Api.TTFQueueLegendListPromise == null) {
            Api.TTFQueueLegendListPromise = $.getJSON("/api/TTFQueue/getTTFQueueLegendLists");
        }

        Api.TTFQueueLegendListPromise.done(callback);

    }

	private static legendListPromise: JQueryXHR;
	public static getLegendLists(choices: Array<IChoiceItem>, callback: JQueryPromiseCallback<any>){
		
		var choiceNames = choices.reduce<string>((choices, choice, idx) => idx == 0 ? choice.configurableChoice : choices + "," + choice.configurableChoice, "" );

		if (Api.legendListPromise == null){
			Api.legendListPromise = $.getJSON("/api/legend/GetConfigurableChoices?choices=" + choiceNames);
		}

		Api.legendListPromise.done(callback);
	}
}