import React from 'react';
import {HeroButtons} from "../HeroButtons/HeroButtons";
import { HeroButtonDefault } from "../HeroButtons/HeroButton";
import { BreadCrumbs } from "../BreadCrumbs/BreadCrumbs";
import BlockBase from '../../hocs/BlockBase';
import {IBlockBaseProps} from '../../hocs/BlockBase';

export interface IHeroButtonsProps { textAlignment: string, heroButtons: Array<HeroButtonDefault>}

export interface IHeroHeaderProps extends IBlockBaseProps {
    text?: string,
    textCSSClass?: string,
    secondaryText?: string,
    secondaryTextCSSClass?: string,
    tertiaryText?: string,
    tertiaryTextCSSClass?: string,
    textPosition?: string,
    heroButtons?: Array<HeroButtonDefault>,
    showBreadCrumbs?: boolean,
    breadCrumbID?: string,
    showFollowFlag?: boolean,
    followGuid?: string,
    followButtonClass?:string
}
interface State { }

export class HeroHeaderItem extends React.Component<IHeroHeaderProps, State> {
    //props: IHeroHeaderProps;
    //state: {};
    constructor(props: IHeroHeaderProps) {
        super(props);
        this.state = {};
    }

    public render() {
        const fullWidthImage = this.props["backgroundImagePosition"] == "fullwidth" || !this.props["backgroundImagePosition"] ? true : false;
        return (
            <div>

                <div className="heroHeader">
                    {this.props.text || this.props.secondaryText || this.props.tertiaryText || this.props.heroButtons ?
                        <div className={"container-fluid " +(this.props["backgroundImagePosition"] ? this.props["backgroundImagePosition"] : "") + " " + ((this.props["backgroundImageString"] && this.props["backgroundImagePosition"] == "fullwidth") || this.props["backgroundColor"] || this.props["showBorder"] ? "insetText" : "hugLeft")}>
                            {this.props.showBreadCrumbs || this.props.showFollowFlag ?
                                <BreadCrumbs showBreadCrumbs={this.props.showBreadCrumbs} breadCrumbID={this.props.breadCrumbID} showFollowFlag={this.props.showFollowFlag} followGuid={this.props.followGuid} followButtonClass={this.props.followButtonClass} />
                                : ""}
                            <div className={this.props["backgroundImageString"] ? "flex": ""} >
                                {this.props["backgroundImageString"] && this.props["backgroundImagePosition"] == "imageleft" ? <div className={"imageContainer imageleft"}><div className={"imageSection imageleft"} style={{ backgroundImage: "url(" + this.props.backgroundImageString + ")" }}></div></div> : ''}
                                <div className={"textSection " + (this.props["backgroundImagePosition"] ? this.props["backgroundImagePosition"] : '')}>

                                    {this.props.tertiaryText ?
                                        <h4 className={this.props.tertiaryTextCSSClass}>{this.props.tertiaryText}</h4>
                                        : ""}
                                    {this.props.text ?
                                        <h2 className={this.props.textCSSClass}>{this.props.text}</h2>
                                        : ""}
                                    {this.props.secondaryText ?
                                        <h3 className={this.props.secondaryTextCSSClass}>{this.props.secondaryText}</h3>
                                        : ""}
                                    {this.props.heroButtons ?
                                        <HeroButtons heroButtons={this.props.heroButtons} />
                                        : ""}

                                </div>
                                {this.props["backgroundImageString"] && this.props["backgroundImagePosition"] == "imageright" ? <div className={"imageContainer imageright"}><div className={"imageSection imageright"} style={{ backgroundImage: "url(" + this.props.backgroundImageString + ")" }}></div></div> : ''}
                            </div>
                        </div>: ""
                    }
                </div>
            </div>
        )
	}
}


export class HeroHeader extends React.Component<IHeroHeaderProps, IBlockBaseProps> {
    //props: IHeroHeaderProps;
    public render() {
        return (
            <HeroHeaderBlock {...this.props} />
        )
	}
}

export const HeroHeaderBlock = BlockBase<IHeroHeaderProps>(HeroHeaderItem,{});
