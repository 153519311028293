import React from 'react';
import * as ReactDOM from "react-dom";
import {IDocumentNavigationItem} from "./DocumentNavigatorItem";

interface IDocumentNavigatorMenuProps { 
    CurrentNavigationItem:IDocumentNavigationItem, 
    CurrentNavigationPath: Array<IDocumentNavigationItem>, 
    PathLength: number,
    UseParameterizedHash?:boolean,
    Loading:boolean,
    ForceLoading?:boolean
}
interface State { animateClass: string }

export default class DocumentNavigatorMenu extends React.Component<IDocumentNavigatorMenuProps, State>{
    constructor(props) {
        super(props);
        this.state = { animateClass: "slideOut" };
    }

    public componentDidMount() {
        var menuItems = $(ReactDOM.findDOMNode(this)).find(".docnav-menuitem");
        menuItems.addClass('active');
    }

    public componentWillReceiveProps(nextProps: IDocumentNavigatorMenuProps){
        if(this.props.CurrentNavigationItem.parentPropertyValue != nextProps.CurrentNavigationItem.parentPropertyValue){
            if(this.props.PathLength < nextProps.PathLength){
                this.setState({animateClass : "slideOut"});
            } else if(this.props.PathLength == nextProps.PathLength){
                this.setState({animateClass : "slideOut"});  
            } else {
                this.setState({animateClass : "slideIn"});
            }

            if(nextProps.CurrentNavigationItem.childDocumentNavigationItems && nextProps.CurrentNavigationItem.childDocumentNavigationItems.length > 0){
                $(ReactDOM.findDOMNode(this)).find(".docnav-menuitem").removeClass('active slideOut slideIn');
            }
        }
    }

    public componentDidUpdate(){        
        if(this.props.CurrentNavigationItem.childDocumentNavigationItems && this.props.CurrentNavigationItem.childDocumentNavigationItems.length > 0 ){
            var menuItems = $(ReactDOM.findDOMNode(this)).find(".docnav-menuitem");
            var animateClass = this.state.animateClass;
            
            var timeout = 0;
            menuItems.each(function(i){
                var menuItem = $(this);

                window.setTimeout( (j) => {
                    menuItem.addClass('animate ' + animateClass).bind("animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd", function(){$(this).removeClass('animate').addClass('active')});;
                }, timeout);

                timeout += 50;
            });
        }
    }

    private getMenuNavigationItems(): Array<IDocumentNavigationItem>{
        if(this.props.CurrentNavigationItem.childDocumentNavigationItems && this.props.CurrentNavigationItem.childDocumentNavigationItems.length === 1
            && this.props.CurrentNavigationItem.childDocumentNavigationItems[0].useDynamicChoiceMode === "DynamicChoices") {
                return null;
        } else if(this.props.CurrentNavigationItem.childDocumentNavigationItems && this.props.CurrentNavigationItem.childDocumentNavigationItems.length > 0) {
            return this.props.CurrentNavigationItem.childDocumentNavigationItems;
        } else {
            if( this.props.CurrentNavigationPath != null){
                var pathPos = this.props.CurrentNavigationPath.length > 1 ? this.props.CurrentNavigationPath.length - 1 : 1;
                var menuNavigationItems = this.props.CurrentNavigationPath[pathPos-1].childDocumentNavigationItems;
                return menuNavigationItems;
            } else {
                return null;
            }            
        }
    }

    public urisplitprotect(uricomponent:string):string {
        return uricomponent ? uricomponent.replace(/\//g,"//").replace(/:/g,"::") : uricomponent;
    }

    public renderMenu() {
        let menuNavigationItems = this.getMenuNavigationItems(); 
        if(!this.props.ForceLoading && menuNavigationItems != null && menuNavigationItems.length > 0) {
            let baseNavigationUrl = this.props.UseParameterizedHash ? "#nt=" : "#";
            let currentNavItem = this.props.CurrentNavigationItem;
            let menuKey = this.props.CurrentNavigationItem.childPropertySystemName;
    
            if (this.props.UseParameterizedHash) {
                let stopAtIndex = (currentNavItem.childDocumentNavigationItems && currentNavItem.childDocumentNavigationItems.length)
                    ?  this.props.CurrentNavigationPath.length - 1 : this.props.CurrentNavigationPath.length - 2;
                menuKey = this.props.CurrentNavigationPath[stopAtIndex].childPropertySystemName;
                for (let i = 0; i < stopAtIndex; i++) {
                    if (this.props.CurrentNavigationPath[i].childPropertySystemName && this.props.CurrentNavigationPath[i + 1].parentPropertyValue) {
                        baseNavigationUrl += "/" + this.urisplitprotect(this.props.CurrentNavigationPath[i].childPropertySystemName) + ":" + this.urisplitprotect(this.props.CurrentNavigationPath[i + 1].parentPropertyValue)
                    }
                }
            } else {
                this.props.CurrentNavigationPath.map((navItem, i) => {
                    if (navItem.parentPropertyValue && navItem.childPropertySystemName && 
                        (navItem.childDocumentNavigationItems && navItem.childDocumentNavigationItems.length)) {
                        baseNavigationUrl += "|" + navItem.parentPropertyValue + "/" + navItem.childPropertySystemName;
                    } else if (navItem.childPropertySystemName && baseNavigationUrl == "#") {
                        baseNavigationUrl += "/" + navItem.childPropertySystemName;
                    }           
                });
            }

            return (
                menuNavigationItems
                .filter(navItem => navItem.parentPropertyValue !== undefined && navItem.parentPropertyValue !== '')
                .map((navItem, i) => {
                    let isCurrent = false;
                    var menuItemClass = "docnav-menuitem active";
                    var menuItemUrl = this.props.UseParameterizedHash ? baseNavigationUrl + "/" + this.urisplitprotect(menuKey) + ":" + this.urisplitprotect(navItem.parentPropertyValue)
                        : baseNavigationUrl + "|" + navItem.parentPropertyValue + (navItem.childPropertySystemName ? "/" + navItem.childPropertySystemName : "");

                    if(menuNavigationItems[i].parentPropertyValue == this.props.CurrentNavigationItem.parentPropertyValue &&
                        (!this.props.CurrentNavigationItem.childDocumentNavigationItems || this.props.CurrentNavigationItem.childDocumentNavigationItems.length == 0)) {
                            isCurrent = true;
                            menuItemClass += " current";
                    }

                    return (
                        <div key={i} className={menuItemClass}>
                            <a href={menuItemUrl} >
                                {isCurrent ? <i className="fa fa-folder-open-o margin-right-5" /> : <i className="fa fa-folder-o margin-right-5" />}
                                {navItem.title || navItem.parentPropertyValue}
                                
                            </a>
                        </div>
                    )
                })
            )
        } else {
            return null;
            // return (
            //     <div className="docnav-menuitem empty-item"></div>
            // )
        }
    }

    public render() {
        return (
            <div>

                <div className="docnav-menuitems" >
                    {
                        this.renderMenu()
                    }
                </div>
            </div>
        )
    }
}