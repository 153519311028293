// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import EventItem, {IEventItem} from "./EventItem";
import BlockBase from '../../hocs/BlockBase';
import {IBlockBaseProps} from '../../hocs/BlockBase';

interface IEventListProps extends IBlockBaseProps { disableRegistrations: boolean, onlyMyEvents: boolean, numberOfEvents: number}

interface State {
    events: Array<IEventItem>,
    registrations: Array<any>,
    filteredEvents: Array<IEventItem>,
    view: string,
    loading:boolean
}
export class EventListBlock extends React.Component<IEventListProps, State>{
    constructor(props) {
        super(props);
        this.state = { events: [], registrations: [], filteredEvents: [], view: "all", loading: true };
        this.defaultNumberOfEvents = this.props.numberOfEvents > 0 && this.props.numberOfEvents;
    }
    public defaultNumberOfEvents = 5;
    public componentDidMount() {
        if (!this.props.onlyMyEvents) {
            this.getEvents();

        } else {
            this.tabClick("my");
        }
        this.getEventsForUser();
    }

    public shouldComponentUpdated() {
        this.getEventsForUser();
        this.getEvents();
    }

    private getEvents() {
	    if (this.props.disableRegistrations) {
            $.getJSON('/api/events/GetEvents?take=' + this.defaultNumberOfEvents,
					function (data) {
						this.setState({ events: data, filteredEvents: data, loading:false });
					}.bind(this));
	    } else {
            $.getJSON('/api/events/GetEventsWithRegistrations?take=' + this.defaultNumberOfEvents,
                function (data) {
				    var newEventState = data["events"];
                    var newRegistrationState = data["registrations"];
				    for (var i = 0; i < newEventState.length; i++) {
					    for (var j = 0; j < newRegistrationState.length; j++) {
                            if (newRegistrationState[j].formIdentifier.hostPageId == newEventState[i].Id) {
							    newEventState[i].isRegistered = true;
						    }
					    }
                    }

                    this.setState({ events: newEventState, registrations: newRegistrationState, loading:false });

			    }.bind(this));
	    }
    }

    private getEventsForUser() {
        $.getJSON('/api/events/GetEventsForUser?take=' + this.defaultNumberOfEvents,
            function (data) {
                this.setState({ filteredEvents: data, loading: false })
            }.bind(this));
    }

    public tabClick(e) {
        this.setState({ view: e });
    }

    public renderButtons() {
        if (!this.props.onlyMyEvents) {
            return (<ul className="nav nav-tabs"><li className="nav-item"><a className="nav-link active" data-bs-toggle="tab" href="#tab2" onClick={ () => { this.tabClick("all"); } }>All Events</a></li> <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab3" onClick={ () => { this.tabClick("my"); } }>My Events</a></li></ul>);
        }
    }

    public render() {
        return (
            <div className="eventBlock w-100">
                {this.state.loading ?
                (() => {
                    const defaults = [];

                        for (let i = 0; i < this.defaultNumberOfEvents; i++) {
                            defaults.push(<div className="card margin-btm-10" key={i}>
                            <div className="card-body">
                                <h4 className="card-title placeholder-glow">
                                    <span className="placeholder col-6"></span>
                                </h4>
                                <p className="card-text placeholder-glow d-flex justify-content-between">
                                    <span className="placeholder col-2"></span>
                                    <span className="placeholder col-2"></span>
                                </p>
                            </div>
                        </div>);
                    }

                    return defaults;
                })()
                :
                    <div className="linkList">
                        {
                            this.state.view == "my" ?
                                this.state.filteredEvents.length > 0 ?
                                    this.state.filteredEvents.map((event, i, array) => {

                                        var addDateHeader = false;

                                        if (array[i - 1]) {
                                            var eventDate = new Date(event.startDate);
                                            var lastDate = new Date(array[i - 1].startDate);
                                            if (eventDate.toDateString() != lastDate.toDateString()) {
                                                addDateHeader = true;
                                            }
                                        } else {
                                            addDateHeader = true;
                                        }
                                        return (
                                            <EventItem key={i} contentLink={event.contentLink} id={event.id} name={event.name} startDate={event.startDate} eventCanceled={event.eventCanceled} isRegistered={event.isRegistered} registrationOpenDate={event.registrationOpenDate} registrationCloseDate={event.registrationCloseDate} allowRegistration={event.allowRegistration} linkURL={event.linkURL} registrationStatus={event.registrationStatus} registrationStatusMessage={event.registrationStatusMessage} addDateHeader={addDateHeader} disableRegistrations={this.props.disableRegistrations} onlyMyEvents={this.props.onlyMyEvents} registrationFormIdentifier={event.registrationFormIdentifier} />
                                        )
                                    })
                                    :"There are no events to be displayed"
                                    :
                                this.state.events.map((event, i, array) => {

                                    var addDateHeader = false;

                                    if (array[i - 1]) {
                                        var eventDate = new Date(event.startDate);
                                        var lastDate = new Date(array[i - 1].startDate);
                                        if (eventDate.toDateString() != lastDate.toDateString()) {
                                            addDateHeader = true;
                                        }
                                    } else {
                                        addDateHeader = true;
                                    }
                                    return (
                                        <EventItem key={i} contentLink={event.contentLink} id={event.id} name={event.name} startDate={event.startDate} eventCanceled={event.eventCanceled} isRegistered={event.isRegistered} registrationOpenDate={event.registrationOpenDate} registrationCloseDate={event.registrationCloseDate} allowRegistration={event.allowRegistration} linkURL={event.linkURL} registrationStatus={event.registrationStatus} registrationStatusMessage={event.registrationStatusMessage} addDateHeader={addDateHeader} disableRegistrations={this.props.disableRegistrations} onlyMyEvents={this.props.onlyMyEvents} registrationFormIdentifier={event.registrationFormIdentifier} />
                                    )
                                })
                        }
                    </div>
                }
            </div>
        )
    }
}

export class EventList extends React.Component<IEventListProps, IBlockBaseProps> {
    public render() {
        return (
            <div className="eventlistblock">
                <EventListHoc {...this.props} />
            </div>
            
        )
	}
}

export const EventListHoc = BlockBase<IEventListProps>(EventListBlock, {});

export default EventList;