import React from 'react';
import * as Follow from '../../follow';

export interface IBreadCrumbsProps {
    showBreadCrumbs?: boolean,
    breadCrumbID?: string,
    showFollowFlag?: boolean,
    followGuid?: string,
    followButtonClass?: string
}

interface State { Crumbs: Array<Crumb>, IsFollowing: boolean, Loading:boolean }

interface Crumb {
    pageName: string,
    link?:string,
}
export class BreadCrumbs extends React.Component<IBreadCrumbsProps, State> {
    constructor(props) {
        super(props);
        this.state = { Crumbs: [], IsFollowing:false, Loading:true };
    }
    public componentDidMount() {
        this.getCrumbs();
        this.getFollowing();
    }
    private getCrumbs() {
        if (this.props.showBreadCrumbs) {
            $.getJSON('/api/breadcrumbs/GetCrumbs?page=' + this.props.breadCrumbID,
                function (data) {
                    this.setState({ Crumbs: data.crumbs, Loading:false });
                }.bind(this));
        }
    }
    private getFollowing() {
        if (this.props.showFollowFlag && this.props.followGuid.length > 0) {
            Follow.Follow.GetIsFollowing(this.props.followGuid).then(function (isFollowing) {
                this.setState({ IsFollowing: isFollowing });
            }.bind(this))
        }
    }
    public render() {
        if (!this.props.showBreadCrumbs) {
            return (<div></div>);
        }
        return (
            <div className="alloyBreadcrumb miso-breadcrumbs">
                <div className="top-bar-row">
                    <div className="crumbs">
                        <a className="breadCrumbLink" href="/"><i className="fa fa-home"></i></a>
                        { this.state.Loading &&
                            <>
                            <span className="divider"><i className="fa fa-chevron-right"></i></span>
                            <span className="divider"><i className="fa fa-spin fa-refresh"></i></span>
                            </>
                        }
                        { 
                            this.state.Crumbs.map((crumb, i) => {
                                return (
                                    < >
                                        <span key={i} className="divider"><i className="fa fa-chevron-right"></i></span>
                                        {
                                            crumb.link ?
                                                <a className="breadCrumbLink" href={crumb.link}>{crumb.pageName}</a>
                                                : <span className="breadCrumbItem">{crumb.pageName}</span>
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                    {this.props.showFollowFlag ?
                        <div id="FollowFlag" dangerouslySetInnerHTML={{ __html: Follow.Follow.Display(this.props.followGuid, this.state.IsFollowing, true, true) }} />
                        : ""}
                </div>
            </div>
        )
	}
}
