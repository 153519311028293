import React from 'react';

export interface IInfoBoxWithThumbnailProps {
    thumbnailImage?: string,
    imageString?: string,
    linkUrl: string,
    linkText?: string
    headerText: string,
    description: string,
}


export class InfoBoxWithThumbnail extends React.Component<IInfoBoxWithThumbnailProps> {
    public render() {
        return (
            <div className="infoBoxWithThumbnail">
                <div className="imageContainer">
                    <a className="imageLink" href={this.props.linkUrl["uri"] ? this.props.linkUrl["uri"] : this.props.linkUrl}><img src={this.props.imageString} alt={this.props.headerText} /></a>
                </div>

                <div className="infoContainer">
                    <h4>{this.props.headerText}</h4>
                    <div className="infoDescription">
                        <div dangerouslySetInnerHTML={{ __html: this.props.description }}></div>
                    </div>
                    
                    <div className="infoLinkArea">
                        <a href={this.props.linkUrl["uri"] ? this.props.linkUrl["uri"] : this.props.linkUrl}>
                            {this.props.linkText ? this.props.linkText : 'Read More'} <i className="margin-left-5 fs14 fa fa-regular fa-chevron-right"></i>
                        </a>
                    </div>
                </div>
            </div>           
        )
	}
}

