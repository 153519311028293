import React from 'react';
import RelatedDocumentsBase, { RelatedDocumentItem, IRelatedDocumentsProps, IRelatedDocumentsState } from './RelatedDocumentsBase'
import RelatedDocumentsList from './RelatedDocumentsList';


interface IRelatedCorrelatedDocumentsProps extends IRelatedDocumentsProps<RelatedDocumentItem> {
    contentGuid:string;
    isInEditMode:Boolean;
}

export class RelatedCorrelatedDocuments extends RelatedDocumentsBase<RelatedDocumentItem, IRelatedCorrelatedDocumentsProps, IRelatedDocumentsState<RelatedDocumentItem>> {
    constructor(props) {
        super(props);
        this.state = { results: [], totalAvailableResults: 0,  query: null, loading: true };
    }
    
    constructResultItem(): RelatedDocumentItem {
        return new RelatedDocumentItem();
    } 

    protected getRelatedPageGuid():string {
        return this.props.contentGuid || "{00000000-0000-0000-0000-000000000000}";
    }

    public render() {
        return (
            <div>
                {this.props.isInEditMode && 
                <div className="editBar">
                    <i className="fa fa-file" aria-hidden="true"></i>
                    Correlation Content Guid: { this.props.contentGuid }
                </div> }
                {this.renderResults()}
            </div>
        )
    }

    public renderResults() {
        if (this.state.loading) {
            return (<div></div>)
        } else if (!this.state.results || !this.state.results.length) {
            return (<div className="linkList"><div className='emptyList'>{ this.props.noResultsText || "There are currently no documents that match this criteria."}</div></div>);
        } else {
            return (
                <RelatedDocumentsList RelatedDocumentItems={ this.state.results } disableFollowing={this.props.disableFollowing} allowCompressedDownload={true} />
            );
        }
    }
}
