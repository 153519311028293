import React from 'react';
import { RelatedDocumentItem } from './RelatedDocumentsBase'
import { InfoBox } from '../InfoBox/InfoBox'
import * as awesome from '../../awesome';
import * as dateFormatter from '../../dateFormatter';
import * as Follow from '../../follow';

interface IRelatedDocumentsListItemProps {
    RelatedDocumentItem: RelatedDocumentItem;
    disableFollowing:boolean;
    PublishDatePrefixText?:string;
}

interface IRelatedDocumentsListItemState {
    
}

export default class RelatedDocumentsListItem extends React.Component<IRelatedDocumentsListItemProps, IRelatedDocumentsListItemState>{
    constructor(props) {
        super(props);
        this.state = { };
    }

    public render() {
        let prefix = this.props.PublishDatePrefixText ? this.props.PublishDatePrefixText + " " : "" ;
        let subString = prefix + dateFormatter.DateFormatter.Format(this.props.RelatedDocumentItem.SearchPublishDate, "MM/DD/YYYY").toString();

        let followString = this.props.RelatedDocumentItem.IsSecuredDocument || this.props.disableFollowing ? null : Follow.Follow.Display(this.props.RelatedDocumentItem.DocumentGuid, this.props.RelatedDocumentItem.isFollowing, true);
        return (
            <InfoBox titleString={this.props.RelatedDocumentItem.Name} titleIcon={awesome.Awesome.getUnformattedDocumentIcon(this.props.RelatedDocumentItem.FileName, this.props.RelatedDocumentItem.ContentType, null)} titleLink={this.props.RelatedDocumentItem.GetDocumentUrl()} subTitleString={subString} dangerousActionLinkList={[followString]} />
        );
    }
}