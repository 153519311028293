import React from 'react';
import {IDocumentNavigationItem} from './DocumentNavigatorItem';

interface IDocumentNavigatorTagCrumbs { CurrentNavigationPath:Array<IDocumentNavigationItem>,
    UseParameterizedHash?:boolean, OnClickTabCrumb: (index: number) => void }
interface State {}

export default class DocumentNavigationTagCrumbs extends React.Component<IDocumentNavigatorTagCrumbs, State>{
    constructor(props) {
        super(props);
        this.state = {};
    }

    public componentDidMount() {
    }

    private selectTabCrumb(propertyValue) {
        this.props.OnClickTabCrumb(propertyValue);
    }

    public urisplitprotect(uricomponent:string):string {
        return uricomponent ? uricomponent.replace(/\//g,"//").replace(/:/g,"::") : uricomponent;
    }

    public render() {
        var baseNavigationUrl = this.props.UseParameterizedHash ? "#nt=" : "#";
        return (
            <div className="miso-breadcrumbs docnav-breadcrumbs mb-4 mt-0">
                <div className="crumbs">
                    {
                        this.props.CurrentNavigationPath.map((navItem, i) => {
                            if(i < this.props.CurrentNavigationPath.length-1){
                                if (this.props.UseParameterizedHash) {
                                    if (i > 0 && this.props.CurrentNavigationPath[i - 1].childPropertySystemName && this.props.CurrentNavigationPath[i].parentPropertyValue) {
                                        baseNavigationUrl += "/" + this.urisplitprotect(this.props.CurrentNavigationPath[i-1].childPropertySystemName) + ":" + this.urisplitprotect(this.props.CurrentNavigationPath[i].parentPropertyValue)
                                    }
                                } else {
                                    if (navItem.parentPropertyValue && navItem.childPropertySystemName && 
                                        (navItem.childDocumentNavigationItems && navItem.childDocumentNavigationItems.length)) {
                                        baseNavigationUrl += "|" + navItem.parentPropertyValue + "/" + navItem.childPropertySystemName;
                                    } else if (navItem.childPropertySystemName && baseNavigationUrl == "#") {
                                        baseNavigationUrl += "/" + navItem.childPropertySystemName;
                                    }        
                                }

                                return (
                                    <span key={i}>
                                        <a href={baseNavigationUrl} className="breadCrumbLink fadeIn" onClick={() => { this.selectTabCrumb(i) }}>
                                            {i > 0 ? <>{navItem.title || navItem.parentPropertyValue}</> : <><i className="fa fa-home margin-right-5"></i>{navItem.title || navItem.parentPropertyValue}</> }
                                        </a>
                                        <span className="divider"><i className="fa fa-chevron-right"></i></span>
                                    </span>


                                )
                            }
                        })
                    }
                </div>
            </div>
        )
    }
}