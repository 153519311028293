
/*/// <reference path="../../node_modules/@types/jquery/index.d.ts" />*/

import * as alerts from './alerts';
import * as follow from './follow';
import * as modal from './modal';
import * as repeat from './repeat';
import * as secured from './secured';
import * as toast from './toast';
import * as dateFormatter from './dateFormatter';
import * as apiHelper from './apiHelper';
import * as awesome from './awesome';
import * as PageGuid from './pageguid';
import * as reactHelper from './reactHelpers';
//import * as sessionTimeout from './sessionTimeout';
import { sessionTimeoutInstance } from './sessionTimeout';

/*export class Miso {
	static Alerts = alerts.Alerts;
	static Follow = follow.Follow;
	static Modal = modal.Modal;
	static Repeat = repeat.Repeat;
	static Secured = secured.Secured;
	static Toast = toast.Toast;
	static DateFormatter = dateFormatter.DateFormatter;
	static Api = apiHelper.Api;
	static Awesome = awesome.Awesome;
	static PageGuid = PageGuid.PageGuid;
	static ReactHelper = reactHelper.ReactHelper;
	static SessionTimeout = sessionTimeoutInstance;
}*/
export const Miso ={
	Alerts:alerts.Alerts,
	Follow: follow,
	Modal: modal.Modal,
	Repeat: repeat.Repeat,
	Secured: secured.Secured,
	Toast: toast.Toast,
	DateFormatter: dateFormatter.DateFormatter,
	Api: apiHelper.Api,
	Awesome: awesome.Awesome,
	PageGuid: PageGuid.PageGuid,
	ReactHelper: reactHelper.ReactHelper,
	SessionTimeout: sessionTimeoutInstance
}
export default Miso;
global.Miso = Miso;