import React from 'react';
import Moment from 'moment';
import * as Follow from '../../follow';
import * as awesome from '../../awesome';
import * as dateFormatter from '../../dateFormatter';

export interface IDocumentNavigationItem {
    parentPropertyValue: string;
    title: string;
    summary: string;
    childPropertySystemName: string;
    childDocumentNavigationItems: Array<IDocumentNavigationItem>;
    displayResultInGrid: boolean;
    resultSortField: string;
	resultSortDirection: string;
    gridMetadataColumns: Array<IDocumentGridColumnDefinitionItem>;
    tableStyle: string;
    dynamicChoiceSortDirection: string;
    useDynamicChoiceMode: string;
    useDynamicYearNavigation: boolean;
    useDynamicQuarterNavigation: boolean;
    useDynamicMonthNavigation: boolean;
    useDynamicDayNavigation: boolean;
    loadedNavigableDocuments: Array<INavigableDocument>;
    useDisplayTitle: boolean;
    totalAvailableResults:number;
}

export interface INavigableDocument {
    guid:string;
    fileName:string;
    url:string;
    isFollowing:Boolean;
    metadata:{[key:string]: any};
}

export interface IDocumentNavigatorFilter {
    propertyName:string;
    operator:string;
    value:string;
}

export interface IDocumentGridColumnDefinitionItem {
    propertyName:string;
    title:string;
    alignment?:"start"|"left"|"center"|"right"|"end";
}

interface IDocumentNavigationItemProps { 
    documentNavigationItem: IDocumentNavigationItem,
    navigationQueryValues: {[key:string]: string},
    filters: Array<IDocumentNavigatorFilter>,
    enableFollowDocuments: boolean,
    openDocumentsInNewTarget: boolean
}

interface State {
    navigableDocuments:Array<INavigableDocument>;
    loading: boolean;
    pageSize: number;
    page: number;
    totalAvailableResults: number;
}

const defaultPageSize = 10;

export default class documentNavigationItem extends React.Component<IDocumentNavigationItemProps, State>{
    constructor(props){
        super(props);
        this.state = {navigableDocuments: null, loading: true, pageSize: defaultPageSize, page: 0, totalAvailableResults: 0};
    }

    public componentDidMount() {
        this.getNavigableDocuments();
    }

    public componentDidUpdate(prevProps, prevState) {
        if(this.props.documentNavigationItem.parentPropertyValue != prevProps.documentNavigationItem.parentPropertyValue
            || this.props.documentNavigationItem.childPropertySystemName != prevProps.documentNavigationItem.childPropertySystemName){
                this.setState({navigableDocuments: null, loading: true, pageSize: defaultPageSize, page: 0, totalAvailableResults: this.props.documentNavigationItem.totalAvailableResults || 0},
                    () => this.getNavigableDocuments());
        } else if (prevState.pageSize != this.state.pageSize || prevState.page != this.state.page) {
            this.getNavigableDocuments();
        }
    }

    public setSort(propertyName) {
        if (this.props.documentNavigationItem.resultSortField === propertyName) {
            if (this.props.documentNavigationItem.resultSortDirection === "desc") {
                this.props.documentNavigationItem.resultSortDirection = "asc";
            } else {
                this.props.documentNavigationItem.resultSortDirection = "desc";
            }
        } else {
            this.props.documentNavigationItem.resultSortField = propertyName;
            this.props.documentNavigationItem.resultSortDirection = "asc";
        }

        let sortReturnValue = (this.props.documentNavigationItem.resultSortDirection === "desc") ? -1 : 1;

        // if we already have all the results, we can sort them locally, but if we only have partially paged results,
        // we will only have accurate data if we wipe out the pre-loaded documents and let the service give us sorted pages
        if (this.state.totalAvailableResults && this.props.documentNavigationItem.loadedNavigableDocuments 
            && (this.props.documentNavigationItem.loadedNavigableDocuments.length >= this.state.totalAvailableResults)) {
                this.props.documentNavigationItem.loadedNavigableDocuments = this.props.documentNavigationItem.loadedNavigableDocuments.sort((navDoc1, navDoc2) => {
                let val1 = navDoc1[propertyName] || navDoc1.metadata[propertyName];
                let val2 = navDoc2[propertyName] || navDoc2.metadata[propertyName];
                if (!val1 && !val2) {
                    return 0;
                } else if (!val1 && val2) {
                    return 1;// -sortReturnValue; these two null checks Should use -sortReturnValue and sortReturnValue respectively, but qbank always sorts empty values at the bottom
                } else if (val1 && !val2) {
                    return -1;// sortReturnValue;
                } else if (val1 < val2) {
                    return -sortReturnValue;
                } else if (val1 > val2) {
                    return sortReturnValue;
                } else {
                    return 0;
                }
            });
        } else {
            this.props.documentNavigationItem.loadedNavigableDocuments = [];
        }

        this.getNavigableDocuments();
    }

    private onPageSizeChanged (event) {
        if (event.target.value != this.state.pageSize) {
            this.setState({page: 0, pageSize: event.target.value});
        }
    }

    private selectResultsPage (pageIndex) {
        var numPages = Math.ceil(this.state.totalAvailableResults / this.state.pageSize);

        if (pageIndex >= 0 && pageIndex < numPages && pageIndex != this.state.page) {
            this.setState({page: pageIndex});
        }
    }

    private setCurrentNavigableDocumentsFromLoaded(resolve:(value?: void | PromiseLike<void>) => void, props = this.props, totalAvailableResults:number) {
        if (!props.documentNavigationItem.totalAvailableResults || props.documentNavigationItem.totalAvailableResults != totalAvailableResults) {
            props.documentNavigationItem.totalAvailableResults = totalAvailableResults;
        }

        if (props === this.props) {
            this.setState( {                     
                navigableDocuments: this.state.pageSize > 0 ? props.documentNavigationItem.loadedNavigableDocuments.slice((this.state.page * this.state.pageSize), ((this.state.page + 1) * this.state.pageSize)) : props.documentNavigationItem.loadedNavigableDocuments, 
                totalAvailableResults: totalAvailableResults,
                loading: false
            }, () => resolve());
        } else {
            resolve();
        }
    }

    private getNavigableDocumentsPromises:Promise<void>[] = [];

    private getNavigableDocuments(props = this.props) {
        if (this.getNavigableDocumentsPromises.length > 0) {
            this.getNavigableDocumentsPromises[this.getNavigableDocumentsPromises.length - 1].then(() => 
            this.getNavigableDocumentsPromises.push(this.getNavigableDocumentsPromised(props)));
        } else {
            this.getNavigableDocumentsPromises.push(this.getNavigableDocumentsPromised(props));
        }
    }
    
    private getNavigableDocumentsPromised(props = this.props):Promise<void>{
        return new Promise<void>((resolve, reject) => {
            this.executeGetNavigableDocuments(resolve, reject, props);
        });
    }

    private executeGetNavigableDocuments(resolve:(value?: void | PromiseLike<void>) => void, reject:(reason?:any) => void, props = this.props) {        
        if (!this.state.loading) {
            this.setState({loading: true}, () => this.executeGetNavigableDocuments(resolve, reject, props));
        } else if (props.documentNavigationItem.loadedNavigableDocuments 
            && (props.documentNavigationItem.loadedNavigableDocuments.length >= this.state.totalAvailableResults
                || (this.state.pageSize > 0 && props.documentNavigationItem.loadedNavigableDocuments.length >= ((this.state.page + 1) * this.state.pageSize))) ) {
            this.setCurrentNavigableDocumentsFromLoaded(resolve, props, this.state.totalAvailableResults);
        } else {
            var body = {
                QueryValues: props.navigationQueryValues,
                NavigationItem: props.documentNavigationItem,
                Filters: props.filters,
                enableFollowDocuments: props.enableFollowDocuments,
                Top: null,
                Skip: null
            };

            if (props.documentNavigationItem.loadedNavigableDocuments && props.documentNavigationItem.loadedNavigableDocuments.length && props.documentNavigationItem.loadedNavigableDocuments.length > 0) {
                body.Skip = props.documentNavigationItem.loadedNavigableDocuments.length;
            }

            if (this.state.pageSize && this.state.pageSize > 0) {
                if (body.Skip) {
                    body.Top = ((this.state.page + 1) * this.state.pageSize) - body.Skip
                } else {
                    body.Top = this.state.pageSize;
                }
            }

            $.ajax({
                url: '/api/documents/getnavigabledocuments',
                type: 'POST',
                data: JSON.stringify(body),
                dataType: 'json',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                success: function (data) {
                    if (data.documents) {
                        if (!props.documentNavigationItem.loadedNavigableDocuments) {
                            props.documentNavigationItem.loadedNavigableDocuments = [];
                        }
                        data.documents.map((doc, i) => {
                            props.documentNavigationItem.loadedNavigableDocuments.push(doc);
                        });    
                        
                        this.setCurrentNavigableDocumentsFromLoaded(resolve, props, data.totalAvailableResults);
                    }
                }.bind(this),
                error: function (data) {
                    this.setState( {                     
                        navigableDocuments: null,
                        totalAvailableResults: 0,
                        loading: false
                    }, () => resolve());
                }.bind(this)
            });
        }
    }

    public setLoadingState(loadingState:boolean = true) {
        this.setState( { loading: loadingState });
    }

    public renderDocuments() {
        if(this.state.loading){
            return (<div className="processingIndicator row"><i className="fa fa-spin fa-refresh"></i></div>)
        } else {
            if(this.state.navigableDocuments != null && this.state.navigableDocuments.length > 0){
                if (this.props.documentNavigationItem.displayResultInGrid === true
                    && this.props.documentNavigationItem.gridMetadataColumns && this.props.documentNavigationItem.gridMetadataColumns.length > 0) {
                    return (
                        <table className="table table-bordered docnav-metadata dataTable no-footer ">
                            <thead>
                                <tr className="col-header-row" role="row">
                                    {
                                        this.props.documentNavigationItem.gridMetadataColumns.map((col, colindex) => {
                                            if (col.propertyName === "ObjectId" || col.propertyName === "CreatedBy" || col.propertyName === "UpdatedBy") {
                                                return (
                                                    <th key={colindex} className="colheader-unsortable" id={col.propertyName}>
                                                        {col.title || col.propertyName}
                                                    </th>
                                                )
                                            } else {
                                                return (
                                                    <th key={colindex} onClick={() => this.setSort(col.propertyName) } id={col.propertyName} className={ col.propertyName === this.props.documentNavigationItem.resultSortField ? "colheader-sortable colheader-sorted-" + (this.props.documentNavigationItem.resultSortDirection || "asc") : "colheader-sortable colheader-unsorted"  } >
                                                        {col.title || col.propertyName}
                                                    </th>
                                                )
                                            }
                                        })
                                    }
                                </tr>
                                <tr className="col-header-row mobile-row">
                                    <th>{this.props.documentNavigationItem.gridMetadataColumns[0].title || this.props.documentNavigationItem.gridMetadataColumns[0].propertyName }</th>
                                </tr>
                            </thead>
                            {this.renderMetadataBody()}                        
                        </table>
                    )
                } else {
                    return (
                        this.state.navigableDocuments.map((doc, i) => {
                            return (
                                <div key={i} className="docnav-document">
                                    <a className="docnav-link" href={doc.url} rel="noopener noreferrer" target={ this.props.openDocumentsInNewTarget && this.props.openDocumentsInNewTarget === true ? "_blank" : "_self" }>
                                        <span className="docnav-icon" dangerouslySetInnerHTML={{__html: awesome.Awesome.getDocumentIcon(doc.fileName, null, "margin-right-10")}} />
                                        <span className="docnav-filename">{doc.fileName}</span>                                
                                    </a>                                                            
                                    {this.renderFollow(doc)}
                                </div>
                            )    
                        })
                    )
                }
            } else {
                return (
                    <div>In order to view documents related to {this.props.documentNavigationItem.title || this.props.documentNavigationItem.parentPropertyValue}, continue using the navigation on the left.</div>
                )
            }
        }        
    }

    public renderPagingDescription() {
        if (this.state.pageSize > 0 && this.state.navigableDocuments.length < this.state.totalAvailableResults) {
            return (<div className="pagination-count">Showing { (this.state.page * this.state.pageSize) + 1 }-{ Math.min((this.state.page + 1) * this.state.pageSize, this.state.totalAvailableResults) } of { this.state.totalAvailableResults } Entries </div>)
        } else {
            return (<div className="pagination-count">Showing {this.state.navigableDocuments.length} Entries</div>)
        }
        
    }

    public renderPaging() {
        if (this.state.totalAvailableResults > defaultPageSize
            && this.state.navigableDocuments != null && this.state.navigableDocuments.length > 0) {
                var numPages = Math.ceil(this.state.totalAvailableResults / this.state.pageSize);
                var pageIndexes = [];
                var pageSizeValues = [5, 10, 25, 50, 100, 500];    // Set possible values for page size selector
                var pageSizeOptions = [];

                var first = (this.state.page < 2 ? 0 : this.state.page - 2);
                var last = (this.state.page < 2 ? 4 : this.state.page + 2);

                if(last > numPages - 1) {
                    last = numPages - 1; 
                    first = last - 4 < 0 ? 0 : last -4;
                }

                for (var pageIndex = first; pageIndex <= last; pageIndex++){
                    pageIndexes.push({index: pageIndex, label: pageIndex + 1, disabled: false});
                }

                for (var pageSizeIndex = 0; pageSizeIndex < pageSizeValues.length; pageSizeIndex++){
                    if(pageSizeIndex > 0){
                        if(this.state.totalAvailableResults / pageSizeValues[pageSizeIndex] > 2){
                            pageSizeOptions.push({pageSizeValue: pageSizeValues[pageSizeIndex], pageSizeText: pageSizeValues[pageSizeIndex] + " per page"});
                        }
                    } else {
                        pageSizeOptions.push({pageSizeValue: pageSizeValues[pageSizeIndex], pageSizeText: pageSizeValues[pageSizeIndex] + " per page"});
                    }
                }

                if(this.state.totalAvailableResults < 1000){
                    pageSizeOptions.push({pageSizeValue: -1, pageSizeText: "All Results"});
                }
            return (
                <div className="pagination-controls">
                    { this.renderPagingDescription() }
                    <ul className="pagination pagination-sm" style={ pageIndexes.length == 0 ? { display: "none" } : null }>
                        <li className={this.state.page <= 0 ? "disabled page-item" : "page-item"} onClick={() => this.selectResultsPage(0)}>
                            <a className="page-link" href="javascript:void(0);" aria-label="First">
                            <span aria-hidden="true"><i className="fa fa-angle-double-left" /></span>
                            <span className="sr-only">First</span>
                            </a>
                        </li>
                        <li className={this.state.page <= 0 ? "disabled page-item" : "page-item"} onClick={() => this.selectResultsPage(this.state.page - 1)}>
                            <a className="page-link" href="javascript:void(0);" aria-label="Previous">
                            <span aria-hidden="true"><i className="fa fa-angle-left" /></span>
                            <span className="sr-only">Previous</span>
                            </a>
                        </li>
                        {
                            pageIndexes.map((pageIndex, i) => {
                                    return (<li key={pageIndex.index} className={pageIndex.disabled ? "disabled page-item" : this.state.page == pageIndex.index ? "active page-item" : "page-item"} onClick={() => this.selectResultsPage(pageIndex.index)}><a href="javascript:void(0);" className="page-link">{pageIndex.label}</a></li>)
                            })
                        }
                        <li className={this.state.page >= (numPages - 1) ? "disabled page-item" : "page-item"} onClick={() => this.selectResultsPage(this.state.page + 1)}>
                            <a className="page-link" href="javascript:void(0);" aria-label="Next">
                                <span aria-hidden="true"><i className="fa fa-angle-right" /></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </li>
                        <li className={this.state.page >= (numPages - 1) ? "disabled page-item" : "page-item"} onClick={() => this.selectResultsPage(numPages - 1)}>
                            <a className="page-link" href="javascript:void(0);" aria-label="Last">
                                <span aria-hidden="true"><i className="fa fa-angle-double-right" /></span>
                                <span className="sr-only">Last</span>
                            </a>
                        </li>
                    </ul>
                    <select className="pager" onChange={this.onPageSizeChanged.bind(this)} value={this.state.pageSize}>
                        {   
                            pageSizeOptions.map((pageSizeOption,pageSizeIndex) => {
                                return(
                                    <option key={pageSizeIndex} value={pageSizeOption.pageSizeValue}>{pageSizeOption.pageSizeText}</option>
                                )
                            })
                        }
                    </select>                
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }

    public renderMetadataBody(){        
        return(                                                        
            this.state.navigableDocuments.map((doc, i) => {                
                return (
                    <tbody key={i + "desktop"} >
                        <tr className="desktop-row" role="row">
                        {
                            this.props.documentNavigationItem.gridMetadataColumns.map((col, colindex) => {
                                return (
                                    <td key={"doc" + i + "col" + colindex}>
                                        {this.renderMetadataColumn(doc, col, colindex)}
                                    </td>
                                )
                            })
                        }
                        </tr>
                        <tr key={i + "mobileHead"} className="mobile-row" role="row">
                            <td><a href={doc.url}>{doc[this.props.documentNavigationItem.gridMetadataColumns[0].propertyName] || doc.metadata[this.props.documentNavigationItem.gridMetadataColumns[0].propertyName] || ""}</a></td>
                        </tr>
                        <tr key={i + "mobileBody"} className="mobile-row" role="row">
                            <td>
                                <ul className="dtr-details">
                                {
                                    this.props.documentNavigationItem.gridMetadataColumns.map((col, colindex) => {
                                        return (
                                            <li key={i + "-" + colindex}>
                                                {this.renderMobileMetadataContent(doc, col, colindex)}
                                            </li>
                                        )
                                    })
                                }
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                )
            })                            
        )
    }

    public renderMetadataColumn(doc: INavigableDocument, col: IDocumentGridColumnDefinitionItem, colindex: number){
        var property = doc[col.propertyName] || doc.metadata[col.propertyName] || "";
        var propertyDate = null;

        var hasTime = property.indexOf("T00:00:00") == -1 ? true : false;
        var isUTC = property.indexOf("Z") != -1 ? true : false;

        if(isUTC){
            propertyDate = Moment.utc(property, Moment.ISO_8601, true);
        } else {
            propertyDate = Moment(property, Moment.ISO_8601, true);
        }
                
        if(propertyDate.isValid()){
            if(hasTime) {
                property = dateFormatter.DateFormatter.Format(propertyDate, "M/D/YYYY h:mm A");
            } else {
                property = dateFormatter.DateFormatter.Format(propertyDate, "M/D/YYYY");
            }            
        } //else {
        //     property = <span dangerouslySetInnerHTML={{ __html: property }}></span>;
        // }       

        if(colindex == 0){
            return (
                <a href={doc.url} rel="noopener noreferrer" target={ this.props.openDocumentsInNewTarget && this.props.openDocumentsInNewTarget === true ? "_blank" : "_self" }>{property}</a>
            )
        } else {
            return (
                property
            )
        }
    }

    public renderMobileMetadataContent(doc: INavigableDocument, col: IDocumentGridColumnDefinitionItem, colindex: number){
        var property = doc[col.propertyName] || doc.metadata[col.propertyName] || "";
        
        var propertyDate = Moment(property, Moment.ISO_8601, true);
        var hasTime = property.indexOf("T00:00:00") == -1 ? true : false;

        if(propertyDate.isValid()){
            if(hasTime) {
                property = dateFormatter.DateFormatter.Format(property, "M/D/YYYY h:mm A");
            } else {
                property = dateFormatter.DateFormatter.Format(propertyDate, "M/D/YYYY");
            }            
        }   

        if(colindex > 0){
            return(
                <div>
                    <span className="dtr-title">{col.title}</span>
                    <span className="dtr-data">{property}</span>
                </div>
            )
        }
    }

    public renderFollow(doc){
        if(this.props.enableFollowDocuments){
            return (
                <span className="follow" dangerouslySetInnerHTML={{ __html: Follow.Follow.Display(doc.metadata.guid, doc.IsFollowing.valueOf(), true) }} />                            
            )
        }
    }

    public render() {
        return (
            <div>
                <h3 className="margin-top-0 blue">{this.props.documentNavigationItem.title || this.props.documentNavigationItem.parentPropertyValue}</h3>

                <div className="docnav-summary" dangerouslySetInnerHTML={{__html: this.props.documentNavigationItem.summary }} />

                <div className="docnav-documents">
                    { this.renderPaging() }
                    { this.renderDocuments() }
                    { !this.state.loading ? this.renderPaging() : <div /> }
                </div>
            </div>
        )
    }
}