import Toastr from 'toastr';
import $ from "jquery";

export class Toast {
    public static Display(message: string, title: string = null, type: string = 'success', options?: { closeButton: boolean, progressBar: boolean, showDuration: number, hideDuration: number, timeOut: number, extendedTimeOut: number, showMethod: string, hideMethod: string }) {
        // Set defaults
        var defaultOpts = {
            closeButton: true,
            progressBar: true,
            showDuration: 300,
            hideDuration: 1000,
            timeOut: 5000,
            extendedTimeOut: 1000,
            showMethod: "slideLeft",
            hideMethod: "fadeOut"
        }
        // Apply defaults to any options not provided
        if (typeof options != "undefined") {
            options.closeButton = options.closeButton != null ? options.closeButton : defaultOpts.closeButton;
            options.progressBar = options.progressBar != null ? options.progressBar : defaultOpts.progressBar;
            options.showDuration = options.showDuration || defaultOpts.showDuration;
            options.hideDuration = options.hideDuration || defaultOpts.hideDuration;
            options.timeOut = options.timeOut || defaultOpts.timeOut;
            options.extendedTimeOut = options.extendedTimeOut || defaultOpts.extendedTimeOut;
            options.showMethod = options.showMethod || defaultOpts.showMethod;
            options.hideMethod = options.hideMethod || defaultOpts.hideMethod;
        } else {
            options = defaultOpts;
        }

        // Add slideLeft function to jQuery if not defined
            $.fn.extend({
                slideLeft: function () {
                    return this.each(function () {
                        $(this).show().animate({ 'right': '30px' });
                    });
                }
            });
        // Display Toast. Yay Toast!
        Toastr[type](message, title, options);
    }

    // Clear toasts
    public static Clear() {
        Toastr.clear();
    }
}