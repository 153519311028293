import React from 'react';
import { OutlookBox } from "./OutlookBox";

interface ISnapshotDashboardProps {
    service_url: string,
    current_demand_service_url: string,
    title: string,
}

interface State {
    snapshots: Array<any>;
    currentDemand: string;
    hasError: boolean;
    isLoaded: boolean;
}

export class SnapshotDashboard extends React.Component<ISnapshotDashboardProps, State> {
    constructor(props) {
        super(props);
        this.state = { snapshots: [], currentDemand: null, hasError: false, isLoaded: false };
    }

    componentDidMount() {
        this.getSnapshots();

        // Periodically fetch current demand from DataBroker to keep snapshot consistent with fuel mix on homepage.
        setInterval(this.getCurrentDemand.bind(this), 1000 * 60 * 5); // 5 minute interval
    }

    componentDidCatch() {
        this.setState({ hasError: true });
    }

    getSnapshots() {
        var serviceUrl = this.props.service_url;

        $.ajax({
            url: serviceUrl,
            type: "GET", 
            headers: {
                'Accept': "application/json",
                'Content-Type': "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Headers": "content-type"

            },
            success: function (data) {
                this.setState({ snapshots: data, isLoaded: true });
                this.getCurrentDemand();
            }.bind(this)
        });
    }

    // The demand delivered from the snapshot and from the Data Broker API are usually slightly
    // different. We default to use the current demand from the Data Broker to keep the
    // snapshot box consistent with the charts. If there is a failure when polling the Data Broker
    // API, then the demand will default to the value provided by the snapshot. This may display
    // a discrepancy between the values showed in the fuel mix and in the snapshot box, but it is
    // probably better than displaying nothing.
    getCurrentDemand() {
        var currentDemandServiceUrl = this.props.current_demand_service_url;
        $.ajax({
            url: currentDemandServiceUrl,
            type: "GET",
            success: function (data) {
                let updatedDemand = data["TotalMW"];
                let copyOfSnapshots = [...this.state.snapshots];
                copyOfSnapshots.forEach((snapshot, _) => {
                    if (snapshot['id'] === "1" && updatedDemand !== undefined) {
                        snapshot['v'] = (Number(updatedDemand)).toLocaleString(); // Adds commas
                    }
                });
                this.setState({ snapshots: copyOfSnapshots });
            }.bind(this),
        });
    }

    private getAsOfDate() {
        var latestDate = this.state.snapshots[0]["d"];

        this.state.snapshots.map((snapshot, _) => {
            if (Date.parse(snapshot["d"]) > Date.parse(latestDate)) {
                latestDate = snapshot["d"];
            }
        });

        return latestDate;
    }

    render() {
        if (!this.state.isLoaded) {
            return <div></div>
        }

        if (this.state.isLoaded && !this.state.hasError && this.state.snapshots.length > 0) {
            const asOf = this.getAsOfDate();

            return (
                <div className="outlook-container">
                    <div className="outlook-container-title col-12">
                        <div className="outlook-title">{this.props.title}</div>
                        <div className="update-time"> {asOf}</div>
                    </div>
                    <div className="snapShots">
                        {
                            this.state.snapshots.map((snapshot, i) => {
                                return <OutlookBox key={i} box={this.state.isLoaded ? snapshot : null}/>;
                            })
                        }
                    </div>
                </div>
            );
        } else {
            const today = new Date().toUTCString();
            var b = new OutlookBox({"t":"--"});

            return (
                <div className="outlook-container">
                    <div className="outlook-container-title col-12">
                        {this.props.title}
                        <div className="update-time"> as of {today}</div>
                    </div>

                    <OutlookBox box={b}/>
                </div>
            );
        }
    }
}
