// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';

interface IGlobalAlertItem { title: string, message: any, start: Date, stop: Date }

interface IGlobalAlertProps {
    globalAlertListItems: Array<IGlobalAlertItem>
}

interface State {
    activeAlerts: Array<IGlobalAlertItem>,
    currentAlertNumber: number,
    currentAlert: IGlobalAlertItem,
    hide: boolean,
    collapsed: boolean,
}

export class GlobalAlert extends React.Component<IGlobalAlertProps, State>{
    constructor(props) {
        super(props);

        this.state = {
            currentAlert: {
                title: null,
                message: null,
                start: new Date(),
                stop: new Date()
            },
            currentAlertNumber: 0,
            activeAlerts: [],
            hide: false,
            collapsed: true
        };
    }

    public componentDidMount() {
        var activeAlerts = this.props.globalAlertListItems.filter((x) => !sessionStorage.getItem(x.title))
        this.setState({
            currentAlert: activeAlerts[0],
            currentAlertNumber: 0,
            activeAlerts: activeAlerts,
            hide: false,
            collapsed: true
        });
    }

    public prevAlert() {
        var i = this.state.currentAlertNumber - 1;

        if (i < 0)
            i = this.state.activeAlerts.length - 1;

        this.setState({
            currentAlert: this.state.activeAlerts[i],
            currentAlertNumber: i
        });
    }

    public nextAlert() {
        var i = this.state.currentAlertNumber + 1;

        if (i > this.state.activeAlerts.length - 1)
            i = 0;

        this.setState({
            currentAlert: this.state.activeAlerts[i],
            currentAlertNumber: i
        });
    }

    public hideAlerts() {

        sessionStorage.setItem(this.state.currentAlert.title, "true");
        var activeAlerts = this.props.globalAlertListItems.filter((x) => !sessionStorage.getItem(x.title))
        this.setState({
            activeAlerts: activeAlerts,
            currentAlert: activeAlerts[0],
            currentAlertNumber: 0,
        });
    }

    public toggleCollapse() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    public render() {
        return (
            (this.state.activeAlerts && this.state.activeAlerts.length >= 1) && (
                <div className={"navbar-alert " + (this.state.hide ? "d-none" : "")}>
                    <div id="global-alert" className="container-fluid" role="alert" >
                        {/* Medium to large view */}
                        <div className="d-none d-md-flex justify-content-between">
                            <div className="flex-grow-1 p-3 ps-0">
                                <div className="d-flex">
                                <h4 className="m-0 flex-grow-1">
                                    <a className="text-white d-flex justify-content-between text-decoration-none text-uppercase" href="#desktopGlobalAlertCollapse" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="desktopGlobalAlertCollapse" onClick={() => { this.toggleCollapse() }}>
                                        <span><i className="fa fa-info-circle me-2"></i>{this.state.currentAlert.title}</span>
                                        <i className={"fs-4 fa pe-2 " + (this.state.collapsed ? "fa-angle-down" : "fa-angle-up")} aria-hidden="true"></i>
                                    </a>
                                </h4>
                                    <div className="flex-shrink-0 d-flex flex-row">
                                        {(this.state.activeAlerts.length > 1) && (
                                            <div className="border-start border-1 border-white col-auto px-2">
                                                <a href="#" onClick={() => { this.prevAlert() }}>
                                                    <i className="fa fa-angle-left text-white fs-4 me-4" aria-hidden="true"></i>
                                                </a>
                                                <span className="align-top">{this.state.currentAlertNumber + 1} / {this.state.activeAlerts.length}</span>
                                                <a href="#" onClick={() => { this.nextAlert() }}>
                                                    <i className="fa fa-angle-right text-white  fs-4 ms-4" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        )}
                                        <div className="col-auto ps-2 border-start border-1 border-white">
                                            <a href="#" className="" onClick={() => { this.hideAlerts() }}>
                                                <i className="fa fa-times text-white"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 collapse" id="desktopGlobalAlertCollapse">
                                    <div className="global-alert-body" dangerouslySetInnerHTML={{ __html: this.state.currentAlert.message }} />
                                </div>
                            </div>
                        </div>
                        {/* Small view */}
                        <div className="d-block d-md-none">
                            {(this.state.activeAlerts.length > 1) && (
                                <div className="d-flex justify-content-between border-bottom border-white border-1 p-2">
                                    <a href="#" onClick={() => { this.prevAlert() }}>
                                        <i className="fa fa-angle-left text-white align-middle fs-4" aria-hidden="true"></i>
                                    </a>
                                    <span className="align-middle">{this.state.currentAlertNumber + 1} / {this.state.activeAlerts.length}</span>
                                    <a href="#" onClick={() => { this.nextAlert() }}>
                                        <i className="fa fa-angle-right text-white align-middle fs-4" aria-hidden="true"></i>
                                    </a>
                                </div>
                            )}
                            <div className="d-flex p-2">
                                <h4 className="m-0 flex-grow-1">
                                    <a className="text-white align-middle d-flex justify-content-between text-decoration-none text-uppercase" href="#globalAlertCollapse" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="globalAlertCollapse" onClick={() => { this.toggleCollapse() }}>
                                        <span><i className="fa fa-info-circle me-2"></i>{this.state.currentAlert.title}</span>
                                        <i className={"fs-4 fa pe-2 border-end border-1 border-white " + (this.state.collapsed ? "fa-angle-down" : "fa-angle-up")} aria-hidden="true"></i>
                                    </a>
                                </h4>
                                <div className="flex-shrink-0 ps-2">
                                    <a href="#" onClick={() => { this.hideAlerts() }}>
                                        <i className="fa fa-times text-white"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="collapse p-2" id="globalAlertCollapse">
                                <div className="global-alert-body" dangerouslySetInnerHTML={{ __html: this.state.currentAlert.message }} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    }
}