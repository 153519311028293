import React from 'react';
import * as DocumentsBase from '../Find/DocumentsBase';
import GIQueueDocumentModal from './GIQueueDocumentModal';

export class GIQueueItem extends DocumentsBase.DocumentItem {
    public entityname: string = null;
    public entityurl: string = null;
    public sortorder: string = null;
    public year: number = null;
    public month: string = null;
}

export interface IGIQueueProps extends DocumentsBase.IDocumentsProps<GIQueueItem> {
    FindServiceUrl: string;
    projectNumber: string;
}

export interface IGIQueueState extends DocumentsBase.IDocumentsState<GIQueueItem> {
    query: GIQueueQuery;
    showModal: boolean;
}

export class GIQueueQuery {
    public partial_fields: {
        source: {
            exclude: Array<string>
        }
    } = {
        source: {
            exclude: ["documentText"]
        }
    };
    script_fields: {
        documentTextExcerpt: {
            params: { field: string, length: number },
            script: string
        }
    } = {
        documentTextExcerpt: {
            params: { field: "documentText", length: 150 },
            script: "ascropped"
        }
    };
    sort: Array<any> =
    [
        { "Updated": "desc" },
        { "Name": "asc" }

    ];
    query: {
        filtered: {
            filter: {
                and: Array<any>
            }
        }
    } = {
        filtered: {
            filter: {
                and: []
            }
        }
    };

    public constructor(projectNumber: string = null) {
        if (projectNumber && projectNumber.trim().length > 0) {
            this.query.filtered.filter.and.push({ query: { term: { "ProjectNumber": projectNumber } } });
        }
    }

}

export class GIQueueDocumentApp extends DocumentsBase.DocumentsBase<GIQueueItem, IGIQueueProps, IGIQueueState> {

    constructor(props) {
        super(props);
        this.state = { results: [], totalAvailableResults: 0, query: null, loading: true, showModal: true };
    }

    constructResultItem(): GIQueueItem {
        return new GIQueueItem();
    }

    componentDidMount() {
        super.componentDidMount();
        if (!this.state.query) {
            let newquery = new GIQueueQuery(this.props.projectNumber);
            this.setState({ query: newquery });
        }
    }

    public render() {
        return (
            <div>
                <GIQueueDocumentModal documents={this.state.results} showModal={true} loading={this.state.loading} projectNumber={this.props.projectNumber} />
            </div>
        );
    }
}
