import React from 'react';
import Moment from "moment";
import * as dateFormatter from '../../dateFormatter';

export interface IInfoBoxWithImageHeaderProps {
    image?: string,
    imageString:string,
    headerText: string,
    description: string,
    linkUrl?: string,
    linkText?: string,
    personName?: string,
    date?: string,
    category?: string
}



export class InfoBoxWithImageHeader extends React.Component<IInfoBoxWithImageHeaderProps> {

    public cleanDate(date) {
        if (Moment(date, Moment.ISO_8601, true).isValid()) {
            return dateFormatter.DateFormatter.Format(date, "M/D/YYYY");
        }
        return date.toString();
    }
    public render() {
        return (
            
            <div className="infoBoxWithImageHeader">
                    {this.props.imageString && 
                        <a href={this.props.linkUrl["uri"] ? this.props.linkUrl["uri"] : this.props.linkUrl}>
                            <img src={this.props.imageString} alt={this.props.headerText} />
                        </a>
                    }
                    <div className='contentBody'>
                        <h3>
                        <a className="HeaderText" href={this.props.linkUrl["uri"] ? this.props.linkUrl["uri"] : this.props.linkUrl}>
                                {this.props.headerText}
                            </a>
                        </h3>
                        <div className="description"> 
                            <div dangerouslySetInnerHTML={{ __html: this.props.description }}></div>  
                        </div>

                        <div className="contentFooterFlexContainer">
                            <div className="contentFooter">
                                <div className="footerArea">
                                        <div className="personName">
                                            {this.props.personName}
                                        </div>

                                        <div className="date">
                                            {this.props.date && this.cleanDate(this.props.date)}
                                        </div>
                                </div>

                                {this.props.category ?
                                    <div className="buttonArea">
                                        <div className="category">
                                            {this.props.category}
                                        </div>
                                    </div>
                                    : ""
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            
           
        )
	}
}

