// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';

interface IClickableImageBlocksProps {
    imageString: string;
    image: string;
    linkUrl?: string;
    buttonText?: string;
    showBorder?: boolean;
}

interface State {}

export class ClickableImageBlocks extends React.Component<IClickableImageBlocksProps, State>{

    constructor(props) {
        super(props);
        this.state = {};
    }

    public render() {
        return (
            <div className={"clickableImageContainer" + (this.props.linkUrl ? "" : " noHover")}>
                {this.props.linkUrl ?
                    <a className="d-block imageLink" href={this.props.linkUrl["uri"] ? this.props.linkUrl["uri"] : this.props.linkUrl }>
                        <img className="clickableImage" src={this.props.imageString}/>
                        <button className="btn btn-primary text-center imageButton">{this.props.buttonText ? this.props.buttonText : "Learn More" }</button>
                    </a>
                    :  
                    <img className="clickableImage" src={this.props.imageString} />
                    
                }
            </div>
        )
    }
}
