import React from 'react';
import MailingListUpdate from "./MailingListUpdate";
import MailingListCategory from "./MailingListCategory";
import {IMailingList} from "./MailingListItem";
import {IListCategory} from "./MailingListCategory";
interface IMailingListProps {  }

interface State {
    listCategories: Array<IListCategory>,
    mailingLists: Array<IMailingList>,
    subOnly: boolean
}


export class MailingList extends React.Component<IMailingListProps, State>{    

    constructor(props) {
        super(props);
        this.state = {listCategories: [], mailingLists: [], subOnly: true};
        this.SubscribeList = this.SubscribeList.bind(this);
        this.UpdateSubscribedLists = this.UpdateSubscribedLists.bind(this);
    }

    public componentDidMount(){
        this.GetListCategories();
        this.GetMailingLists();
    }    
    
    private GetListCategories(){
			return $.getJSON("/api/MailingLists/GetMailingListCategories", 
            function(data){                
                this.setState({listCategories: data});
            }.bind(this));
    }

    private GetMailingLists(){
        var deferred = $.Deferred();

        $.getJSON("/api/MailingLists/GetMailingLists", 
            function(mailingListData){

                for(var i=0; i<mailingListData.length; i++){
                    // Add default subscribed = false to mailing lists
                    mailingListData.map((list: IMailingList, i) => {
                        list.subscribed = false;
                        list.subscribe = false;
                        list.subscriptionDate = new Date(list.subscriptionDate);
                        return list;
                    });                
                }

                $.ajax({
					url: "/api/MailingLists/GetMailingListsForCurrentUser", 
                    type: "GET",
                    dataType: "json",
                    success: function (data) {                    
                        for(var i=0; i<data.length; i++){
                            var mailingList = mailingListData.map((list: IMailingList,j) => {
                                if(list.listName == data[i].listName){
                                    list.subscribed = true;
                                    list.subscribe = true;
                                    list.subscriptionDate = new Date(data[i].subscriptionDate);
                                }
                                return list;
                            });                                                                                      
                        }
                        this.setState({mailingLists: mailingListData});
                        deferred.resolve();
                    }.bind(this), 
                    error: function(err){
                        this.setState({mailingLists: mailingListData});
                        deferred.resolve();
                    }.bind(this)}                    
                )                            
            }.bind(this));

        return deferred.promise();
    }

    private UpdateSubscribedLists(lists) : void{
        var updatedMailingLists = this.state.mailingLists.map((_list, i) => {                                    
             var list = lists.filter((list: IMailingList, i) => {
                return list.listName == _list.listName;
             }) as Array<IMailingList>;

             if(list.length > 0){
                _list.subscribed = true;
                _list.subscribe = true;
                _list.subscriptionDate = new Date(list[0].subscriptionDate);
             } else {
                _list.subscribed = false;
                _list.subscribe = false;
             }
                         
            return _list;
        });

        this.setState({mailingLists: updatedMailingLists});
    }

    private GetCategoryLists(category, showAvailable = false) {
        var categoryLists = new Array<IMailingList>();

        for(var i=0; i<this.state.mailingLists.length; i++){
            if (this.state.mailingLists[i].listCategory == category) {
                if (!showAvailable) {
                    if(this.state.mailingLists[i].subscribed){
                        categoryLists.push(this.state.mailingLists[i]);    
                    }
                } else {
                    categoryLists.push(this.state.mailingLists[i]);
                }                
            }
        }        

        return categoryLists;
    }    

    public SubscribeList(list) : void{
        
        var updatedMailingLists = this.state.mailingLists.map((_list, i) => {
            if(_list.listName == list.listName){
                if(_list.subscribe){
                    _list.subscribe = false;  
                    
                } else {                    
                    _list.subscribe = true;
                }  
            }

            return _list;
        })
        
        this.setState({mailingLists: updatedMailingLists});
    }

    public ToggleListView(subOnly: boolean) : void{            
        this.setState({subOnly: subOnly});
    }

    public render() {
   //     if (!secured.Secured.LoggedIn)
			//	return (
			//		<text>You must be logged in to manage your mailing list subscriptions.</text>
		 //   );
			//else
        return (
            <div id="mailing-list-subscriptions" className="mailing-list-subscriptions">
                <div className="row padding-btm-20">
                    <h3 className="col-12 blue">Mailing Lists</h3>
                </div>

                <div className="row padding-btm-20">
                    <div className="tab-content-block col-12">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" data-bs-target="#myMailingListsTab" href="#myMailingListsTab">My Mailing Lists</a>
                                {/*<a className={this.state.subOnly? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="javascript:void(0)" onClick={() => this.ToggleListView(true)}>My Mailing Lists</a>*/}
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#availableMailingListsTab" href="#availableMailingListsTab">All Available Mailing Lists</a>
                                {/*<a className={this.state.subOnly? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="javascript:void(0)" onClick={() => this.ToggleListView(false) }>All Available Mailing Lists</a>*/}
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="tab-content">
                    <div id="myMailingListsTab" className="tab-pane fade show active in">
                        <div className="row">
                            <div className="col-12">
                                <div>
                                    {this.state.listCategories.map((category, i) => {
                                        return (
                                            <MailingListCategory key={category.id} Category={category} Index={i} MailingLists={this.GetCategoryLists(category.name)} SubscribeList={this.SubscribeList} />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="availableMailingListsTab" className="tab-pane fade">
                        <div className="row">
                            <div className="col-12">
                                <div>
                                    {this.state.listCategories.map((category, i) => {
                                        return (
                                            <MailingListCategory key={category.id} Category={category} Index={i} MailingLists={this.GetCategoryLists(category.name, true)} SubscribeList={this.SubscribeList} />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <MailingListUpdate MailingLists={this.state.mailingLists} UpdateSubscribedLists={this.UpdateSubscribedLists} />
                            {/*<button className="btn btn-primary" style={{ 'float': 'right' }} onClick={() => this.SaveChanges(group.displayTitle)} disabled={!this.state.unsubscribeLists.find(x => x.displayTitle === group.displayTitle) || this.state.unsubscribeLists.find(x => x.displayTitle === group.displayTitle).items.length < 1}>Save Changes</button>*/}
                        </div>
                    </div>
                </div>
            </div>            
        )
    }
}