import React from 'react';
import * as dateFormatter from '../../dateFormatter';

export interface IStakeholderFeedbackItem { Name: string, DueDate: Date, Description: string, Url:string, Id:string }
interface state {}
export default class StakeholderFeedbackItem extends React.Component<IStakeholderFeedbackItem, state>{
    constructor(props) {
        super(props);
        this.state = {};
    }
    public getDisplayDate() {
        var tempDate = this.props.DueDate;
        return dateFormatter.DateFormatter.Format(tempDate, "MM/DD/YYYY");
    }

    public render() {
        return (
            <div className="panel panel-default">
                <div className="panel-heading" role="tab">
                    <div className="leftside">
                        <h4 className="panel-title blue">
                            <a data-toggle="collapse" data-bs-toggle="collapse" data-bs-target={"#accordion" + this.props.Id} className="accordion-link collapsed" data-parent="#accordion" href={'#accordion'+this.props.Id}>
                                {this.props.Name}
                            </a>
                        </h4>
                        <h3 className="subTitle">{this.getDisplayDate()}</h3>
                    </div>
                    <div className="rightside">
                        <a data-toggle="collapse" data-bs-toggle="collapse" data-bs-target={"#accordion"+this.props.Id } className="accordion-link collapsed" data-parent="#accordion" href={'#accordion' + this.props.Id}>
                            <i className="fa fa-plus pull-right blue"></i>
                        </a>
                        
                    </div>
                </div>
                <div id={"accordion"+this.props.Id} className="panel-collapse collapse" role="tabpanel">
                    <div className="panel-body">
                        <div dangerouslySetInnerHTML={{ __html: this.props.Description }}></div>
                        <div className="margin-top-20 margin-btm-30"> <a href={this.props.Url} className="btn btn-primary">More Details<i className="fa fa-arrow-circle-right margin-left-20"></i></a> </div>
                    </div>
                </div>
            </div>
        )
    }
}