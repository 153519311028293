import React from 'react';
import { SearchFacet } from './SearchFacetMenuSection';
import transformTagToReadableString from './TagTransformer';

interface ISearchFacetItemTagProps { Facets:Array<SearchFacet>, OnFacetItemClicked: Function, Loading:boolean}
interface State { }

export default class SearchFacetItemTags extends React.Component<ISearchFacetItemTagProps, State>{
    constructor(props) {
        super(props);
        this.state = { };
    }

    public componentDidMount() {

    }

    public render() {
        return (
            <div className="docnav-tagcrumbs margin-top-10">
                {
                    this.props.Facets.filter(facet => facet.facetItems && facet.facetItems.some(facetItem => facetItem.selected === true)).map((facet, facetIndex) => {
                        return (
                            <div className={ "miso-breadcrumbs search-breadcrumbs d-block margin-btm-10 margin-top-5" + (this.props.Loading ? " disabledElement" : "")} key={facetIndex}>
                                
                                <div className="crumbs float-none">
                                    <label className="margin-right-15">
                                        {facet.title}:
                                    </label>
                                {
                                    facet.facetItems.filter(facetItem => facetItem.selected === true).map((facetItem, facetItemIndex) => {
                                        return (
                                            <span className="docnav-tagcrumb margin-right-10" key={facetIndex + "_" + facetItemIndex}>
                                                
                                                <a className="breadCrumbLink fadeIn">
                                                    {transformTagToReadableString(facetItem.itemValue)}
                                                    <i className="fa fa-times margin-left-5" onClick={ () => { this.props.OnFacetItemClicked( facetItem ); } }></i>
                                                </a>
                                            </span>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        )                        
                    })
                }
            </div>
        )
    }
}