// A '.tsx' file enables JSX support in the TypeScript compiler, for more
// information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import Moment from "moment";
import { RegistrationStatusCalculated } from "../RegistrationStatus/RegistrationStatus";
import {EventAgenda} from "./EventAgenda";

declare var Miso : any;

export interface IEvent { id: number, title: string, link: string, start: Date, end: Date, eventType: string, eventFormat: string, hostLocation: string, hostRoom: string, locations: Array<string>, details: string, agenda: string, registrationOpenDate: Date, registrationCloseDate: Date, isRegistered: boolean, isCancelled: boolean, allowRegistration: boolean, registrationStatus: string, form: any, pageGuid: string, entityReferenceMobile: string, entities:Array<any> }

interface IEventProps { index: number, event: IEvent, activeEvent: boolean, hash:string }
interface State { /*registered: boolean*/ }

export default class Event extends React.Component < IEventProps, State > {
    constructor(props: IEventProps){
        super(props);
        this.state = { /*registered: false*/ };                
    }

    //public componentDidMount(){
    //    if(this.props.event.isRegistered){
    //        this.setState({registered: true});
    //    }
    //} 
    
    private getActionLink(action) {
        
        var splitLink = this.props.event.link.split("?");
        var globalLink = splitLink[0];
        return globalLink + action;
    }

    private renderDate(){
        if(this.props.event.start.getDate() == this.props.event.end.getDate()){
            return (
                <div className="row borderRow dateRow">
                    <div className="eventDetailsHeaderText">
                        Date:
                    </div>

                    <div className="eventDetailsText">
                        {Moment(this.props.event.start).format("dddd, MMMM Do, YYYY")}
                        <br/>

                        {Miso.DateFormatter.Format(this.props.event.start, "LT")} 
                        &nbsp; - &nbsp;
                        {Miso.DateFormatter.Format(this.props.event.end, "LT")} Eastern Time 
                        <div className="central-time">({Miso.DateFormatter.Format(Moment(this.props.event.start).add(-1,"hour").toDate(), "LT")} 
                        &nbsp; - &nbsp;
                        {Miso.DateFormatter.Format(Moment(this.props.event.end).add(-1, "hour").toDate(), "LT")} Central Time)</div>
                    </div>                                      
                </div>
            );                                      
        } else {
            return (
                <div>
                    <div className="row">
                        <div className="eventDetailsHeaderText">
                            <strong>Start Time:</strong>
                        </div>
                        <div className="eventDetailsText">
                            {Miso.DateFormatter.Format(this.props.event.start, "l LT")} ET <br />
                            <span className="central-time"><i>({Miso.DateFormatter.Format(Moment(this.props.event.start).add(-1, "hour").toDate(), "l LT")} CT)</i></span>
                        </div> 
                    </div>
                    <div className="row">
                        <div className="eventDetailsHeaderText">
                            <strong>End Time:</strong>
                        </div>
                        <div className="eventDetailsText">
                            {Miso.DateFormatter.Format(this.props.event.end, "l LT")} ET <br />
                            <span className="central-time"><i>({Miso.DateFormatter.Format(Moment(this.props.event.end).add(-1,"hour").toDate(), "l LT")} CT)</i></span>
                        </div>               
                    </div>                                                                                      
                </div>
            );
        }
    }

    private renderLocation() {
        if(this.props.event.hostLocation != null){
            return(
                <div className="row borderRow locationRow">
                    <div className="eventDetailsHeaderText">
                        <strong>Location:</strong>
                    </div>
                    <div className="eventDetailsText">
                        <div>{this.props.event.hostLocation + " - " + this.props.event.hostRoom + " (host)"}</div>
                        {this.props.event.locations.map((loc, i) => {
                            return (
                                <div key={i}>{loc}</div>
                            )                                        
                        })}                                    
                    </div>

                </div>
            );
        } else {
            return (
                <div className="row">
                    <div className="eventDetailsHeaderText">
                        <strong>Location:</strong>
                    </div>
                    <div className="eventDetailsText">
                        No meeting space available.
                    </div>
                </div>
            )
        }
    }
    
    public render() {
       return (
            <div className="panel panel-default">
                <div className="panel-heading" role="tab" id={"heading" + this.props.event.id}>
                    <h4 className="panel-title margin-btm-0">
                       <a data-toggle="collapse" href={"#collapse" + this.props.event.id} className={this.props.activeEvent ? "" : "collapsed"} data-bs-toggle="collapse" data-bs-target={"#collapse" + this.props.event.id} data-parent={"#eventsAccordion"} role="button" aria-expanded={this.props.activeEvent ? "true" : "false"} aria-controls={"collapse" + this.props.event.id}>
                           <div>                                                
                           {!this.props.event.isCancelled && (<span style={{ textDecoration: 'none' }}>{this.props.event.title}</span>)} 
                           {this.props.event.isCancelled && (<span style={{ textDecoration: 'line-through' }}>{this.props.event.title}</span>)} 
                           {this.props.event.isCancelled && (<span style={{ color: 'red' }}>&nbsp;(canceled)</span>)}  </div>
                            <i className="fa pull-right"/>
                        </a>
                    </h4>
                </div>

                <div id={"collapse" + this.props.event.id} className={this.props.activeEvent ? "panel-collapse collapse show" : "panel-collapse collapse"} role="tabpanel" aria-expanded={this.props.activeEvent ? "true" : "false"} aria-labelledby={"heading" + this.props.event.id}>
                    <div className="panel-body">
                        
                        {this.renderDate()}

                        {this.renderLocation()}

                        <div className="row borderRow eventTypeRow">
                            <div className="eventDetailsHeaderText">
                                <strong>Entity:</strong>
                            </div>

                           <div className="eventDetailsText">
                               {this.props.event.entities ?
                                   this.props.event.entities.map((ent, i) => {
                                       return (
                                           <div key={i} >
                                               <a href={ent["url"]}>{ent["text"]}</a>
                                           </div>
                                        )
                                   })
                                   : <div>No Related Entity</div>
                               }
                            </div>
                        </div>


                        <div className="row borderRow eventTypeRow">
                            <div className="eventDetailsHeaderText">
                                <strong>Event Type:</strong>
                            </div>
                            <div className="eventDetailsText">
                                {this.props.event.eventType}
                            </div>
                        </div>

                        <div className="row borderRow eventFormatRow">
                            <div className="eventDetailsHeaderText">
                              <strong>Event Format:</strong>
                            </div>
                            <div className="eventDetailsText">
                                {this.props.event.eventFormat}
                            </div>
                        </div>

                        

                        <div className="row borderRow eventDetailsRow">
                            <div className="eventDetailsHeaderText">
                                <strong>Details:</strong>
                            </div>
                            <div className="eventDetailsText">
                                <span dangerouslySetInnerHTML={{__html: this.props.event.details}} />
                            </div>
                        </div>
                        {/*<div className="row">
                            <div className="eventDetailsText col-offset-4">
                                <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                                    <li>
                                        <a href={this.props.event.agenda}>
                                          View Agenda     
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>*/}
                       
                        <div className="row borderRow fullDetailsRow">
                           <a href={this.props.event.link} className="text-center btn btn-default details-btn">
                                    View Full Details
                            </a>
                        </div>
                        <div className="row borderRow buttonRow">
                            {/*{<EventAgenda pageGuid={this.props.event.pageGuid} findServiceUrl="/api/find/Optics_Models_Find_RemoteHostedContentItem" />}*/}

                                <a href={this.getActionLink("/AddToICalendar")} className="btn btn-primary-light-blue calendarButton">
                                    <span className="pull-left">
                                        Save to Calendar
                                    </span>
                                    <span className="pull-right">
                                        <i className="fa fa-calendar"/>
                                    </span>
                                </a>

                           {<RegistrationStatusCalculated target={this.props.event.id.toString()} isCancelled={this.props.event.isCancelled} allowRegistration={this.props.event.allowRegistration} registrationOpenDate={this.props.event.registrationOpenDate} registrationCloseDate={this.props.event.registrationCloseDate} eventStatus={this.props.event.registrationStatus} form={this.props.event.form} view={"Buttons"} linkUrl={this.getActionLink("/")} disableRegistrations={false} loggedIn={true} hash={this.props.hash} />}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
