import React from 'react';
import { GIQueueItem } from './GIQueueDocumentApp';
import GIQueueDocumentListItem from './GIQueueDocumentListItem';

interface GIQueueDocumentListProps {
    documents: GIQueueItem[];
}

const GIQueueDocumentList: React.SFC<GIQueueDocumentListProps> = (props) => {

    let { documents } = props;

    return (
        <div className="flex-container" >
            { documents.length == 0 ? <span>There are no documents available for this GI Queue Item.</span>:documents.map((document: GIQueueItem, index: number) => <GIQueueDocumentListItem key={index} document={document}  />)}
        </div>
    );

};

export default GIQueueDocumentList;