export class FindResultItem {
    public _id: string = null;
    public ExtranetID: string = null;
	public MediaId: number = null;
	public _score: number = null;
	public _type: string = null;
	public source: any = null;
	public $type: string = null;
	public ___types: Array<string> = null;
	public title: string = null;
	public description: string = null;
	public Description: string = null;
	public DocumentGuid: string = null;
	public Files: any = null;
	public isFollowing: boolean = false;
    public RolesWithReadAccess: string = null;
	public SearchTitle: string = null;
	public SearchSummary: string = null;
	public SearchText: string = null;
	public SearchHitUrl: string = null;
	public SearchTypeName: string = null;
	public SearchHitTypeName: string = null;
	public SearchSection: string = null;
	public SearchSubsection: string = null;
	public SearchAuthors: Array<string> = null;
	public SearchGeoLocation: any = null;
	public SearchPublishDate: Date = null;
	public SearchUpdateDate: Date = null;
	public SearchAttachment: any = null;
	public SearchCategories: Array<string> = null;
	public SearchFilename: string = null;
	public SearchFileExtension: string = null;
    public SearchMetaData: any = null;

	public get IsSecuredDocument(): boolean {
		let url = this.SearchHitUrl ? this.SearchHitUrl : (this.Files && this.Files.original) ? this.Files.original : "#";
		if (this.RolesWithReadAccess && this.RolesWithReadAccess.length && this.RolesWithReadAccess.indexOf("Everyone") < 0 && this.SearchTypeName !== "Page") {
			return true;
		}
		return (url.indexOf('://powered-by.qbank.se') > 0);
	}

	public GetDocumentUrl():string {
		return this.GetCalculatedDocumentUrl().replace(" ", "%20");
	}
	
	public GetCalculatedDocumentUrl():string {
		if (!this.IsSecuredDocument) {
            return this.SearchHitUrl ? this.SearchHitUrl : (this.Files && this.Files.original) ? this.Files.original : "#";
        } else if (this.ExtranetID) {
            return "/api/extranetdocuments/getEdcDocument/" + this.ExtranetID;
		} else if (this.SearchFilename) {
			return "/api/documents/getbyname/" + this.SearchFilename;
		} else if (this.MediaId) {
			return "/api/documents/getbymediaId/" + this.MediaId;
		} else if (this.DocumentGuid) {
			return "/api/documents/getbyguid/" + this.DocumentGuid;
		} else {
			return "#";
		}
	}
}