interface IRepeatEvent { name: string, onResize: boolean, onEvent: (any?) => void, params?: any };

export class Repeat {
    private resizeEvents: Array<IRepeatEvent> = [];
    private repeatEvents: Array<IRepeatEvent> = [];
    private lastUpdate: number;
    private delay: number;
    private timeout: number;

    constructor(delay: number) {
        this.lastUpdate = 0;
        this.timeout = null;
        this.delay = delay;

        window.onresize = this.WatchResize.bind(this);   
        window.setInterval(this.WatchRepeat.bind(this), delay);
    }

    public RegisterEvent(rev: IRepeatEvent, isResize: boolean) {
        this.timeout = null;

        if (isResize) {
            // Add resize events to watch
            this.resizeEvents.push(rev);
            this.WatchResize(null);
        } else {
            // Add resize events to watch
            this.repeatEvents.push(rev);
        }
    }    

    // Watch repeat events
    private WatchRepeat(ev: UIEvent): void {
        // Handle repeat events
        for (var i = 0; i < this.repeatEvents.length; i++) {
            this.repeatEvents[i].onEvent(this.repeatEvents[i].params);
        }
    }

    // Watch resize events
    private WatchResize(ev: UIEvent): void {
        if (this.timeout == null) {
            var elapsed = (new Date().getTime() - this.lastUpdate);

            if (elapsed >= this.delay) {
                this.lastUpdate = new Date().getTime();

                // Handle resize events
                for (var i = 0; i < this.resizeEvents.length; i++) {
                    this.resizeEvents[i].onEvent(this.resizeEvents[i].params);
                }
            } else {            
                this.timeout = window.setTimeout(function () {
                    this.timeout = null;
                    this.WatchResize(ev);                    
                }.bind(this), this.delay - elapsed);
            }
        }
    }

}