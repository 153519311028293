// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import {IConfigurableChoice} from "../../ConfigurableChoice";

// export interface ILegendItem { Value: string, Text: string, Description: string }
export interface ILegendItemList { name: string, showLabel: boolean, displayName: string, showValue: boolean, isColorKey: boolean, items: Array<IConfigurableChoice>, index: number }

export default class LegendItemList extends React.Component<ILegendItemList, any>{
    private renderLegendListItem(legendListItem: IConfigurableChoice, i: number){
        if(this.props.isColorKey){
            var backColor = {backgroundColor: legendListItem.value};
            return (
                
                <div key={i} className="color-key">
                    <span className="color-key-icon" style={backColor}></span>

                    <div className="color-key-text"><b>{(this.props.showValue ? "(" + legendListItem.value + ") " : "") + legendListItem.text}</b> - {legendListItem.description}</div>
                </div>
            )
        } else {
            return (
                <div key={i} className={this.props.showValue ? ("legend-row " + legendListItem.value.toLowerCase() ) : ""} >
                    <b>{this.props.showValue ? (<div className={"legend-icon " + (legendListItem.value.toLowerCase()) }>{legendListItem.value}</div>) : ""} {legendListItem.text}</b> - {legendListItem.description}<br />
                </div>
            )
        }
    }

    public render() {
        return (
            <div className="gray-border-1 padding-btm-20 margin-btm-10">
                
                {this.props.showLabel ? <h4 className={this.props.index == 0 ? "margin-top-0" : ""}>{this.props.displayName}</h4>     : ""}
                
                {
                    this.props.items.map((legendListItem, i) => {
                        return (this.renderLegendListItem(legendListItem, i))
                    })
                }
            </div>
        )
    }
}