export class Alerts {
	
	public static registerManagedAlerts(selector: string) {
		$(selector).each(function (index: number, elem: Element) {
			$(elem).on('closed.bs.alert', function () {
				localStorage.setItem(this.id, "dismissed");
			});

			if (localStorage[elem.id] == null) {
				$('#' + elem.id).show();
			}
		});
	}
}
