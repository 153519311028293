import React from 'react';
import * as toast from '../../toast';
import * as dateFormatter from '../../dateFormatter';
import { IModalButton } from "../../modal";
import * as modal from '../../modal';

interface IAlertSubscriptionListProps { endpoint: string }
interface IAlertSubscriptionGroup { displayTitle: string, subscriptions:Array<IAlertSubscriptionListItemProps>}
interface IAlertSubscriptionListItemProps { contentId: string, contentName: string, contentUrl: string, creationDate: Date }
interface IAlertUnsubscribeGroup { displayTitle: string, items: Array<IAlertSubscriptionListItemProps> }

interface State {
    alertSubscriptionList: Array<IAlertSubscriptionGroup>,
    showAll: boolean,
    unsubscribeLists: Array<IAlertUnsubscribeGroup>
}

export class AlertSubscriptionList extends React.Component<IAlertSubscriptionListProps, State> {

	constructor(props) {
		super(props);
		this.state = { alertSubscriptionList: [], showAll:false, unsubscribeLists: [] };
		this.Unsubscribe = this.Unsubscribe.bind(this);
	}

	public componentDidMount() {
		this.GetMySubscriptions();
	}

	private GetMySubscriptions() {
		return $.getJSON(this.props.endpoint + "/GetGroupedSubscriptions",
			function (data) {
                this.setState({ alertSubscriptionList: data });
			}.bind(this));
	}

    private Unsubscribe(params: any): void {
        var unsubscribeGroup = this.state.unsubscribeLists.find(x => x.displayTitle === params.tabKey);
        var unsubscribeList = unsubscribeGroup ? unsubscribeGroup.items : [];

		$.ajax({
            type: 'post',
            data: JSON.stringify(unsubscribeList.map(x => x.contentId)),
			url: this.props.endpoint + '/Unsubscribe',
			contentType: 'application/json',
			dataType: 'json'
		}).then(
			function (data) {
                toast.Toast.Display('Your subscriptions have been updated');
                this.state.unsubscribeLists.find(x => x.displayTitle === params.tabKey).items = [];
                this.setState({ unsubscribeLists: this.state.unsubscribeLists });
                this.GetMySubscriptions();
			}.bind(this)
		);
    }

    public buildList(items) {
        var listString = '<ul style="margin-top:10px; list-style: none;">';
        items.map(item => {
            listString += '<li key="item-' + item.contentId + '">' + item.contentName + '</li>';
        })
        listString += '</ul>';
        return listString;
    }

    public SaveChanges(tabKey) {
        let unfollowBtn: IModalButton = { name: "Unfollow", func: this.Unsubscribe, params: { tabKey } };
        let cancelBtn: IModalButton = { name: "Cancel", func: null, params: null, btnClass: 'btn-default' };
        var items = this.state.unsubscribeLists.find(x => x.displayTitle === tabKey).items;

        // Prompt to unfollow
        modal.Modal.Display("Are you sure you want to unfollow the following items:<br />" + this.buildList(items), [unfollowBtn, cancelBtn]);
    }

    public showAllClick() {
        this.setState({ showAll: true });
    }
    public showLessClick() {
        this.setState({ showAll: false });
    }

    public toggleUnsubscribe(displayTitle, id) {
        var unsubscribeGroup = this.state.unsubscribeLists.find(x => x.displayTitle === displayTitle);
        if (unsubscribeGroup) {
            if (unsubscribeGroup.items.find(x => x.contentId === id)) {
                this.state.unsubscribeLists.find(x => x.displayTitle === displayTitle).items = unsubscribeGroup.items.filter(x => x.contentId !== id);
            }
            else {
                this.state.unsubscribeLists.find(x => x.displayTitle === displayTitle).items.push(this.state.alertSubscriptionList.find(x => x.displayTitle === displayTitle).subscriptions.find(x => x.contentId === id));
            }

            this.setState({ unsubscribeLists: this.state.unsubscribeLists });
        }
        else {
            this.state.unsubscribeLists.push({ displayTitle: displayTitle, items: [this.state.alertSubscriptionList.find(x => x.displayTitle === displayTitle).subscriptions.find(x => x.contentId === id)] });
            this.setState({ unsubscribeLists: this.state.unsubscribeLists });
        }
    }

    public render() {
		if (this.state.alertSubscriptionList.length > 0) {
            return (
                <div>
                    <div className="row padding-btm-20">
                        <h3 className="col-12 blue">My Alerts</h3>
                    </div>

                    <div className="row padding-btm-20">
                        <div className="tab-content-block col-12">
                            <ul className="nav nav-tabs">
                            {
                                this.state.alertSubscriptionList.map((group, gi) => {
                                    var className = gi == 0 ? "nav-link active" : "nav-link";
                                    var target = "#myAlerts" + group.displayTitle + "Tab";
                                    return (
                                        <li key={target} className="nav-item">
                                            <a className={className} data-bs-toggle="tab" data-bs-target={target} href={target}>{group.displayTitle}</a>
                                        </li>
                                    )
                                })
                            }
                            </ul>
                        </div>
                    </div>

                    <div className="tab-content">
                    {
                        this.state.alertSubscriptionList.map((group, gi) => {
                            var className = gi == 0 ? "tab-pane fade show active in" : "tab-pane fade";
                            var id = "myAlerts" + group.displayTitle + "Tab";
                            return (
                                <div key={id} id={id} className={className}>
                                    <div className="row">
                                        <div className="col-12">
                                            {group.subscriptions.length > 0
                                                ?
                                                    <div>
                                                        <p>By unchecking an item below and clicking the "Save Changes" button, you will no longer get notified of changes made to that particular {group.displayTitle.toLowerCase().substring(0, group.displayTitle.length - 1)}.</p>
                                                        <ul style={{ 'listStyle': 'none', 'padding': 0 }}>
                                                            {
                                                                !this.state.showAll ?
                                                                    group.subscriptions.slice(0, 10).map((s, i) => {
                                                                        return (
                                                                            <li key={i} className="margin-top-5 margin-btm-10" style={{ 'borderRadius': '4px', 'border': '1px solid #c2c2c3' }}>
                                                                                <div style={{ padding: '10px 15px' }} className="info row justify-content-between">
                                                                                    <div className="col-auto d-flex align-items-center form-check padding-left-10">
                                                                                        <input type="checkbox" className="form-check-input miso-checkbox margin-right-10 margin-top-0" id={s.contentId} checked={!this.state.unsubscribeLists.find(x => x.displayTitle === group.displayTitle) || !this.state.unsubscribeLists.find(x => x.displayTitle === group.displayTitle).items.find(x => x.contentId === s.contentId)} onChange={() => this.toggleUnsubscribe(group.displayTitle, s.contentId)} />
                                                                                        <label className="form-check-label" htmlFor={s.contentId}>{s.contentName}</label>
                                                                                    </div>
                                                                                    <div className="col-auto dark-grey">Created {dateFormatter.DateFormatter.Format(s.creationDate)}</div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })

                                                                    :
                                                                    group.subscriptions.map((s, i) => {
                                                                        return (
                                                                            <li key={i} className="margin-top-5 margin-btm-10" style={{ 'borderRadius': '4px', 'border': '1px solid #c2c2c3' }}>
                                                                                <div style={{ padding: '10px 15px' }} className="info row justify-content-between">
                                                                                    <div className="col-auto d-flex align-items-center form-check padding-left-10">
                                                                                        <input type="checkbox" className="form-check-input miso-checkbox margin-right-10 margin-top-0" id={s.contentId} checked={!this.state.unsubscribeLists.find(x => x.displayTitle === group.displayTitle) || !this.state.unsubscribeLists.find(x => x.displayTitle === group.displayTitle).items.find(x => x.contentId === s.contentId)} onChange={() => this.toggleUnsubscribe(group.displayTitle, s.contentId)} />
                                                                                        <label className="form-check-label" htmlFor={s.contentId}>{s.contentName}</label>
                                                                                    </div>
                                                                                    <div className="col-auto dark-grey">Created {dateFormatter.DateFormatter.Format(s.creationDate)}</div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                            }
                                                        </ul>
                                                        {
                                                            group.subscriptions.length > 10 && (
                                                                <div>
                                                                    <div style={{ textAlign: 'center' }}>
                                                                        <button className="btn btn-link btn-small blue" onClick={() => { if (this.state.showAll) { this.showLessClick(); } else { this.showAllClick(); } }}>
                                                                            {this.state.showAll ? "Show Less" : "Show More"}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        <hr />
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <button className="btn btn-primary" style={{ 'float': 'right' }} onClick={() => this.SaveChanges(group.displayTitle)} disabled={!this.state.unsubscribeLists.find(x => x.displayTitle === group.displayTitle) || this.state.unsubscribeLists.find(x => x.displayTitle === group.displayTitle).items.length < 1}>Save Changes</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                :
                                                    <p>You currently have no {group.displayTitle} subscriptions</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            );
		}
		else {
			return (
				<p>You currently have no subscriptions</p>
			)
		}
	}

}