import React from 'react';
import {HeroButton} from "./HeroButton";
import {HeroButtonDefault} from "../HeroButtons/HeroButton";

export interface IHeroButtonProps {
    buttonText: string,
    buttonLink: string,
    buttonClass: string, 
    fAIconPosition: string, 
    fAIcon: string,
    secureLink: boolean,
    newWindow:boolean
}
export interface IHeroButtonsProps {
    heroButtons: Array<HeroButtonDefault>
}


export class HeroButtons extends React.Component<IHeroButtonsProps> {
    public render() {
        return (
            <div className="heroButtons margin-btm-0 margin-top-20">
                {this.props.heroButtons.map((buttonn, i) => {
                    return (<HeroButton key={i} buttonText={buttonn.buttonText} buttonLink={buttonn.buttonLink} buttonClass={buttonn.buttonClass} fAIconPosition={buttonn.fAIconPosition} fAIcon={buttonn.fAIcon} secureLink={buttonn.secureLink} newWindow={buttonn.newWindow} />)
                    
                })}
            </div>
        )
	}
}