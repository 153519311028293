import React from 'react';
import {IMailingList} from "./MailingListItem";
import * as toast from '../../toast';

interface IMailingListUpdateProps { MailingLists: Array<IMailingList>, UpdateSubscribedLists: (lists: Array<IMailingList>) => void }

export default class MailingListUpdate extends React.Component<IMailingListUpdateProps, any>{
    private SaveUpdates(subscribedLists){
        var updateLists = subscribedLists.map((list) => {
            return {
                active: list.active,
                listCategory:list.listCategory,
                listDescription: list.listDescription,
                listName: list.listName,
                listTitle: list.listTitle
            }
        });

       $.ajax( {
			url: "/api/MailingLists/UpdateMailingLists", 
            type: 'POST',
            data: JSON.stringify(updateLists),
            dataType: 'json',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            success: function(updatedLists){
                toast.Toast.Display('Your subscriptions have been updated');                    
            }, 
            error: function(data){
                toast.Toast.Display('There was an error updating your subscriptions', null , 'error');                
            }
        }).then((updatedLists) => {
            this.props.UpdateSubscribedLists(updatedLists);
        });
    }

    public OnUpdateLists(event: React.FormEvent<HTMLButtonElement>){        
        var subscribedLists = this.props.MailingLists.filter((list, i) => {
            return (list.subscribe);
        });

        this.SaveUpdates(subscribedLists);
    }

    public render(){
        return (
            <div className="mailing-list-update">
                <button className="btn btn-primary pull-right" onClick={e => this.OnUpdateLists(e)}>Save Changes</button>
            </div>            
        )
    }
}