import React from 'react';
import TariffTabSearch from "./TariffTabSearch";
import * as TariffDocList from "./TariffDocuments";

export interface ITariffPanel { tariffDocumentType: string }
export interface ITariffPanelConfiguration { value: string, text: string, description: string }
interface ITariffPanelProps { findServiceUrl:string, tariffTabId: string, tabId: number, activeTab: number, tariffDocTypeName: string, tariffDocTypeTitle:string, tariffDocTypeDescription:string, updateTab: (tabId:number) => void, disableFollowing:boolean }
interface State { }

export default class TariffPanel extends React.Component<ITariffPanelProps, State>{

    constructor(props){
        super(props);    
        this.state = { };
        this.search = this.search.bind(this);
    }

    private TariffDocList:TariffDocList.TariffDocuments;

    private search(query?: string): void {
        if (this.TariffDocList) {
            this.TariffDocList.SetAdditionalQueryText(query);
        }
    }

    render() {
        return(
            <div>
                <div className={"tab-pane " + (this.props.tabId==this.props.activeTab ? " show active" : "")}> 
                    <div className="panel panel-default mb-lg-0 border-lg-0">
                        <div className="panel-heading d-lg-none" role="tab">
                            <div className="leftside">
                                <h4 className="panel-title margin-btm-0">
                                    <a data-bs-toggle="collapse" data-bs-target={"#Accordion" + "Collapse" + this.props.tabId} role="button" aria-expanded={this.props.tabId == this.props.activeTab ? "true" : "false"} aria-controls={this.props.tariffTabId + "Collapse" + this.props.tabId} className={"accordion-link" + (this.props.tabId == this.props.activeTab ? "" : " collapsed")} onClick={() => { this.props.updateTab(this.props.tabId) }}>
                                        {this.props.tariffDocTypeTitle}
                                    </a>
                                </h4>
                            </div>
                            <div className="rightside">
                                <a data-bs-toggle="collapse" data-bs-target={"#Accordion" + "Collapse" + this.props.tabId} role="button" aria-expanded={this.props.tabId == this.props.activeTab ? "true" : "false"} aria-controls={this.props.tariffTabId + "Collapse" + this.props.tabId} className={"accordion-link" + (this.props.tabId == this.props.activeTab ? "" : " collapsed")} onClick={() => { this.props.updateTab(this.props.tabId) }}>
                                    <i className="fa fa-plus pull-right"></i>
                                </a>
                            </div>

                        </div>
                        <div id={"Accordion" + "Collapse" + this.props.tabId} className={"panel-collapse collapse" + (this.props.tabId==this.props.activeTab ? " show" : "")} role="tabpanel" aria-labelledby={this.props.tariffTabId + "Heading" + this.props.tabId} aria-expanded={this.props.tabId==this.props.activeTab ? "true" : "false"}>
                            <div className="panel-body">                            
                                <TariffTabSearch Search={this.search} />                                
                                <TariffDocList.TariffDocuments findServiceUrl={this.props.findServiceUrl} tariffGroup={this.props.tariffDocTypeName} ref={ instance => { this.TariffDocList = instance; } } disableFollowing={this.props.disableFollowing} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}