import { DateFormatter } from '../../dateFormatter';
import RelatedDocumentsList, {DateRefinedRelatedDocumentsList} from './RelatedDocumentsList';
import React from 'react';
import {IBlockBaseProps} from '../../hocs/BlockBase';
import RelatedDocumentsBase, { RelatedDocumentItem, RelatedDocumentsQuery, IRelatedDocumentsProps, IRelatedDocumentsState } from './RelatedDocumentsBase';
import * as dateFormatter from '../../dateFormatter';

interface IRelatedMeetingMaterialDocumentsProps extends IRelatedDocumentsProps<RelatedDocumentItem>, IBlockBaseProps {
    committeePageGuid:string;
    meetingdate?:string;
}

interface IRelatedMeetingMaterialDocumentsState extends IRelatedDocumentsState<RelatedDocumentItem> {
    meetingDates?:Array<Date>;
}

export class RelatedMeetingMaterialDocuments extends RelatedDocumentsBase<RelatedDocumentItem, IRelatedMeetingMaterialDocumentsProps, IRelatedMeetingMaterialDocumentsState>{
    constructor(props) { 
        super(props);
        this.state = { results: [], totalAvailableResults: 0, query: null, loading: true };
    }

    constructResultItem(): RelatedDocumentItem {
        return new RelatedDocumentItem();
    }

    protected getRelatedPageGuid():string {
        return this.props.committeePageGuid || "{00000000-0000-0000-0000-000000000000}";
    }

    protected extendRelatedDocumentsQuery(newquery:RelatedDocumentsQuery) : RelatedDocumentsQuery {
        let newQuery = super.extendRelatedDocumentsQuery(newquery);
                
        const meetingMaterialTerms = {or: [
            {
                query: {
                    term: {
                        "Properties.committeedoctype": "Meeting Material"
                    }
                }
            },
            {
                query: {
                    term: {
                        "Properties.committeedoctype": "Both"
                    }
                }
            }
        ]}

        newQuery.query.filtered.filter.and.push(meetingMaterialTerms);
        newQuery.query.filtered.filter.and.push({ exists: {"field":"Properties.meetingdate"}});

        if (this.props.meetingdate) {

            const meetingDate = DateFormatter.DateCast(this.props.meetingdate);
            const aDifferentMeetingDate = DateFormatter.Format(meetingDate, "YYYY-MM-DDTHH:mm:ss");
            const fromDate = meetingDate.clone().add(1, 'days');           
            const fromDateWithoutZ = DateFormatter.Format(fromDate, "YYYY-MM-DDTHH:mm:ss");
            newQuery.query.filtered.filter.and.push( { 
                range: { "Properties.meetingdate": { "gte": aDifferentMeetingDate, "lt": fromDateWithoutZ } } 
            });
        } //else { // TODO: finish implementing dynamic loading in the daterefiner so that results can be loaded per selected date, rather than dates parsed from all results
        //     newQuery["size"] = 0;
        //     newQuery["facets"] = { "MeetingDate": {
        //         "terms": { "field": "MeetingDate", "order": "term"}
        //     } }
        // }

        return newquery;
    }

    public performAdditionnalSearchResultParsing(results: Array<RelatedDocumentItem>, data:any) : any {
        if (!this.state.meetingDates && data.facets && data.facets["Properties.meetingdate"] && data.facets["Properties.meetingdate"].terms) {
            let meetingDates = [];

            data.facets["Properties.meetingdate"].terms.map((meetingDateFacet) => {
                let time = meetingDateFacet.term + 18000000;
                meetingDates.push(dateFormatter.DateFormatter.DateCast(time));
            });

            this.setState({ meetingDates: meetingDates });
        }
    }

    public render() {
        if (this.props.meetingdate) {
            return (<RelatedDocumentsList RelatedDocumentItems={this.state.results} PublishDatePrefixText="Published" disableFollowing={this.props.disableFollowing} allowCompressedDownload={this.props.allowCompressedDownload} {...this.props} loading={this.state.loading} />);
        } else {
            return (<DateRefinedRelatedDocumentsList RelatedDocumentItems={this.state.results} DateSelections={this.state.meetingDates} PublishDatePrefixText="Published" disableFollowing={this.props.disableFollowing} allowCompressedDownload={this.props.allowCompressedDownload} {...this.props} loading={this.state.loading} />);
        }
    }

}