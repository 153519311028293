// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import { Accordion } from "../Accordion/Accordion";
import * as dateFormatter from '../../dateFormatter';

interface INewsOverviewProps { Topic: string, SiteURL: string }

interface State {
    Articles: Array<any>;
    FilterYear: string;
    YearList: Array<number>;
    Loading: boolean;
}

export class NewsOverview extends React.Component<INewsOverviewProps, any>{
    constructor(props) {
        super(props);
        this.state = { Articles: [], FilterYear: "All", YearList:[],Loading:true};

    }

    public componentDidMount() {
        this.getArticles();
    }

    private getArticles() {
        return $.getJSON("/api/News/GetNews",
            function (data) {
                this.setState({ Articles: data.articles, YearList: data.yearList, FilterYear: "All", Loading:false });
            }.bind(this));
    }

    private getArticlesByYear(year) {
        return $.getJSON("/api/News/GetNewsByYear?year="+year,
            function (data) {
                this.setState({ Articles: data.articles, Loading:false});
            }.bind(this));
    }

    public yearFilter(year) {
        this.setState({ FilterYear: year, Loading: true });
        if (year == "All") {
            this.getArticles();
        } else {
            this.getArticlesByYear(year.toString());
        }
        
    }

    public getDisplayDate(date) {
        return dateFormatter.DateFormatter.Format(date, "MM/DD/YYYY");
    }
    public renderDateSelector() {
        return (<div className="miso-dropdown  pull-right"><div className="dropdown">
            <button className="btn-secondary form-select" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {this.state.FilterYear}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button className="btn btn-link dropdown-item" onClick={() => { this.yearFilter("All"); }}>All</button>
                {
                    this.state.YearList.map((year, i) => {
                        return (<button key={i} className="btn btn-link dropdown-item" onClick={ () => { this.yearFilter(year); } }>{year}</button>)
                    })
                }
            </div>
        </div></div>);
    }
    public render() {
        return (
            <div id="NewsOverview">
                <div className="headerSection">
                    <span className="dropdownTitle">MISO News Releases</span> {this.renderDateSelector()}
                </div>
                {this.state.Loading ? 
                    <h2><i className="fa fa-spinner text-center w-100 margin-top-10" aria-hidden="true"></i></h2>
                    :
                    <div className="accordionblock">
                        <Accordion accordionId={"News"} paging={true} useSinglePanelStyle={false} displayAccordion={true} openFirstPanel={false} panels={Object.keys(this.state.Articles).map((key) => this.state.Articles[key])} titleOverride={"name"} contentOverride={"text"} secondaryTitle={"releaseDate"} />
                    </div>
                }
            </div>
        )
    }
}