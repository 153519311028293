import {Modal, IModalButton} from "./modal";
import Miso from "./global";



export class SessionTimeout {

    private readonly loginRoot: string = '/account/login?ReturnUrl=';
    private _loggedIn: boolean = false;
    private _timeout: number = 0;
    private timerId: number = 0;
    
    constructor() {
    }

    public get loggedIn(): boolean {
        return this._loggedIn;
    }

    public set loggedIn(newLoggedIn: boolean) {
        this._loggedIn = newLoggedIn;
    }

    public get timeout(): number {
        return this._timeout;
    }

    public set timeout(val: number) {
        this._timeout = val * 60 * 1000;           
    }

    public startSession() {

        if (this.loggedIn) {
            this.startTimer();
        }
    }


    public updateSession() {

        if (this.loggedIn) {
            this.clearTimer();
            this.startTimer();
        }

    }

    private startTimer() {
        if (this.timerId === 0) {
            //TO DO
            const timeout2 = setTimeout(() => {

                this.clearTimer();

                let callbackUrl = this.getCallbackUrl();
                let loginCallback = () => this.loginModalCallback(callbackUrl);
                let homepageCallback = () => this.homepageModalCallback();

                let loginButton: IModalButton = { name: "Login and stay", func: loginCallback, params: callbackUrl }
                let homepageButton: IModalButton = { name: "Continue as Guest", func: homepageCallback, params: null }

                // Call Modal
                Modal.Display("<strong>Whoops!</strong> It looks like your session has timed out. Please login again or continue as guest.<br/><br/>If you are on a secure page and choose to continue as guest, you will be taken back to the homepage.", [loginButton, homepageButton]);
            },
                this.timeout);
        }
    }

    private clearTimer() {
        if (this.timerId !== 0) {
            clearTimeout(this.timerId);
            this.timerId = 0;
        }
    }

    private getRootSiteUrl() {
        return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/';
    }

    private getCallbackUrl() {
        return this.loginRoot + (window.location.pathname !== '/' ? window.location.pathname : this.getRootSiteUrl());
    }

    private loginModalCallback(loginUrl: string) {
        this.navigateTo(loginUrl);
    }

    private homepageModalCallback() {
        this.navigateTo(this.getRootSiteUrl());
    }

    private navigateTo(url: string) {
        window.location.href = url;
    }


}

export let sessionTimeoutInstance = new SessionTimeout(); 