import {IModalButton} from "./modal";
import Miso from "./global";

export class Secured {
    public static LoggedIn: boolean = false;
    public static LoginUrl: string = "#";        

    public static SecureLinks() {
        var loggedIn = this.LoggedIn;

        $('.secured-link').each(function () {        
            if ($(this).data('secured') == null) {

                if (!loggedIn) {
                    $(this).addClass('logged-out');
                }

                // Cache event
                var eventArray = new Array();
                var events = $["_data"](this, 'events');

                if (this.onclick != null) {
                    eventArray.push(this.onclick);
                }

                if (typeof events != "undefined" && typeof events.click != "undefined") {
                    for (var i = 0; i < events.click.length; i++) {
                        eventArray.push(events.click[i].handler);
                    }
                }

                // Add navigation event to event array for links
                if (this.hasAttribute('href')) {
                    var href = this.attributes['href'].value;
                    $(this).data('href', href);
                    this.removeAttribute("href");                    
                    eventArray.push((data) => {
                        window.location.href = data.href;
                    });    
                }

                // Remove the event from the link
                this.onclick = null;
                $(this).unbind('click');

                this.onclick = function (e) {
                    e.stopImmediatePropagation();
                    e.preventDefault();

                    Miso.Secured.Action(eventArray, $(e.currentTarget).data());
                }

                $(this).data('secured', true);
            }
        });
    }

    public static SetLoginStatus(loggedIn: boolean, loginUrl: string){
        this.LoggedIn = loggedIn;
        this.LoginUrl = loginUrl;
    }

    public static Action(callbacks: Array<(any) => any>, parameters: any) {
        if (this.LoggedIn) {  
            if (callbacks.length >= 0) {
                for (var i = 0; i < callbacks.length; i++) {
                    callbacks[i](parameters);
                }
            }
        } else {
            if (parameters.href) {
                var callbackUrl = '/account/login?ReturnUrl=' + parameters.href;
                Secured.SetLoginStatus(this.LoggedIn, callbackUrl);
            }
            var loginCallback = () => this.LoginModalCallback();
            let loginButton: IModalButton = {name: "Login", func: loginCallback, params: null}

            // Call Modal
            Miso.Modal.Display("<strong>Whoops!</strong> You need to log in to perform this action.", [loginButton]);
        }
    }

    private static LoginModalCallback(){
        window.location.href = this.LoginUrl;
    }  
}
