// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';

import { Accordion } from "../Accordion/Accordion";
import { CallToActionSideBar, epiLinks } from '../CallToActionSideBar/CallToActionSideBar';
interface INotification { notificationTopic: string, subject: string, body: string, publishDate: string, permanentLinkUrl: string }
export interface INotificationTopic { text: string, value: string, description: string }
interface INotificationTopicProps { topic: INotificationTopic, notifications: Array<INotification>, siteURL: string, overviewAdditionalText:string, callToActionText:string }
interface state { }

export default class NotificationTopic extends React.Component<INotificationTopicProps, state>{
    constructor(props) {
        super(props);
    }
    public render() {
        let links: epiLinks[] = [{
            openInNewTab: false,
            text: "Get Started",
            url: this.props.siteURL + "manage/profile"
        }]
        return (
            <div className="row margin-top-20">
                <div className="col-12 col-md-3">
                    <div className="block withBorder editorialblock editorialblock">
                        <div className="withBorder">
                            <div className="headerBlock blue-bg">
                                <div className="leftSide">
                                    <div className="primaryText">
                                        Overview
                                    </div>

                                </div>
                            </div>
                            <div className="blockBody">
                                {this.props.topic.description}
                                <p className="margin-top-20 margin-btm-0" dangerouslySetInnerHTML={{ __html: this.props.overviewAdditionalText }} ></p>
                            </div>

                        </div>
                    </div>
                    <div className="block">
                        <CallToActionSideBar 
                            primaryText={"Subscribe to Notifications"}
                            secondaryText={this.props.callToActionText}
                            buttonLinks={links}
                            alignRightButtons={false}
                            ctaClass={"blue"}
                            secondaryTextDangerous={true}
                        />
                    </div>
                </div>

                <div className="col-12 col-md-9">
                    <div className="accordionblock">
                        <Accordion accordionId={"Notifications"} search={true} paging={true} defaultPageSize={10} useSinglePanelStyle={false} displayAccordion={true} openFirstPanel={false} panels={Object.keys(this.props.notifications).map((key) => this.props.notifications[key])} titleOverride={"subject"} contentOverride={"body"} secondaryTitle={"publishDate"} />
                    </div>
                </div>
            </div>
        )
    }
}
