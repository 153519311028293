import * as FindResultsBase from "../Find/FindResultsBase";
import * as FindResultItemBase from "../Find/FindResultItemBase";


export class DocumentItem extends FindResultItemBase.FindResultItem {
	public ContentType: string = null;
	public Created: Date = null;
	public CreatedBy: string = null;
	public DisplayDate: Date = null;
	public FileName: string = null;
	public Name: string = null;
	public Description: string = null;
	public DocumentGuid: string = null;
	public properties: any = null;
	public Updated: Date = null;
	public UpdatedBy: string = null;
	public DocumentOwner: string = null;
	public DocumentOwnerEmail: string = null;
	public DocumentType: string = null;
	public Keywords: any = null;
	public expiration: Date = null;
	public Legaldoctype: string = null;
	public Legaldocsubtype: string = null;
    public RelatedPages: Array<string> = null;
    public CommitteeDocType: Array<string> = null;
    //public MeetingDate: Date = null;
    public StudyGroup: string = null;
    public StudyCycle: string = null;
    public DisplayTitle: string = null;
    public effectivedate: Date = null;
    public meetingdate: Date = null;
}

export interface IDocumentsProps<T extends DocumentItem> extends FindResultsBase.IResultsProps<T> {
	disableFollowing?:boolean;	
}

export interface IDocumentsState<T extends DocumentItem> extends FindResultsBase.IResultsState<T> {
} 

export abstract class DocumentsBase<TDocumentItem extends DocumentItem, TDocumentsProps extends IDocumentsProps<TDocumentItem>, TDocumentsState extends IDocumentsState<TDocumentItem>> extends FindResultsBase.FindResultsBase<TDocumentItem, TDocumentsProps, TDocumentsState> {
	constructor(props) {
		super(props);
	}
}