import React from 'react';
import { RegistrationStatusCalculated } from "../RegistrationStatus/RegistrationStatus";
import * as dateFormatter from '../../dateFormatter';

export interface IEventItem {
    id: string,
    name: string,
    startDate: Date,
    eventCanceled: boolean,
    isRegistered: boolean,
    linkURL: string,
    registrationStatus: string,
    registrationStatusMessage: string,
    addDateHeader: boolean,
    disableRegistrations: boolean,
    onlyMyEvents: boolean,
    registrationFormIdentifier: any,
    contentLink: string,
    registrationOpenDate: Date,
    registrationCloseDate: Date,
    allowRegistration: boolean
}
//interface EventItemProps { Subject: string, PublishDate: Date  }

interface State {
}
export default class EventItem extends React.Component<IEventItem, State>{
    constructor(props) {
        super(props);
        this.state ={};
    }
 
    public getTime() {
       
        return dateFormatter.DateFormatter.Format(this.props.startDate, "h:mm a");
    }
    public getHeaderText() {
        var tempDate = new Date(this.props.startDate);
        if (this.props.addDateHeader) {
            var today = new Date();
            if (today.toDateString() == tempDate.toDateString()) {
                return "Today";
               
            } else {
                var newDate = dateFormatter.DateFormatter.Format(tempDate, "dddd, MMMM D, Y");
                return newDate;
            }
        } else {
            return "";
        }
    }

    private getEventName() {
        if (this.props.eventCanceled) {
            return <span><span className='strikeThrough'>{this.props.name}</span> <span className='red nonStrikeThrough'> (canceled) </span></span>;
        } else {
            return <span>{this.props.name}</span>;
        }
    }

    private renderCalButton() {
        if (this.props.onlyMyEvents) {
            if (this.props.eventCanceled) {
                return (<span className="calButton pull-right"><i className="opacity fa fa-calendar" aria-hidden="true"></i></span>);
            } else {
                return (<a className="calButton pull-right" href={this.props.linkURL + "AddToICalendar"}><i className="fa fa-calendar margin-left-30" aria-hidden="true"></i></a>);
            }
        }
    }
    private getActionLink(action) {
        var splitLink = this.props.linkURL.split("?");
        var globalLink = splitLink[0];
        return globalLink + action;
    }

    public render() {
        return (
            <>
                <h5 className="eventDateHeader margin-btm-8">{this.getHeaderText() }</h5>
                <div className='infoBox'>
                    <div className='info'>
                        <div className={this.props.onlyMyEvents ? 'wordBreak wb65' : 'wordBreak'}>
                        
                            <a href={this.props.linkURL} className="meetingItemURL">
                                {this.getEventName() }

                            </a>
                             
                            <div className="inlineArea">
                                <div className="meetingDate">{this.getTime() }</div>
                                <div className='follow pull-right'>
                                    {<RegistrationStatusCalculated target={this.props.id} isCancelled={this.props.eventCanceled} allowRegistration={this.props.allowRegistration} registrationOpenDate={new Date(this.props.registrationOpenDate)} registrationCloseDate={new Date(this.props.registrationCloseDate)} eventStatus={this.props.registrationStatus} form={this.props.registrationFormIdentifier} view={"Icons"} linkUrl={this.getActionLink("/")} disableRegistrations={this.props.disableRegistrations} loggedIn={true}/>}
                                    {this.renderCalButton() }
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </>
        )
    }
}
