import React from 'react';
import { GIQueueItem } from './GIQueueDocumentApp';
import * as moment from 'moment';
import * as dateFormatter from '../../dateFormatter';
import * as awesome from '../../awesome';
interface GIQueueDocumentListItemProps {
    document: GIQueueItem;
}

const GIQueueDocumentListItem: React.SFC<GIQueueDocumentListItemProps> = (props) => {

    let { document } = props;

    const getDisplayDate = (document: GIQueueItem) => {
        var displayDate = null;

        if (document.DisplayDate != null) {
            displayDate = moment.utc(document.DisplayDate, moment.ISO_8601, true);
        } else {
            displayDate = moment.utc(document.Updated, moment.ISO_8601, true);
        }

        return (
            <div className="col-3 text-end">{dateFormatter.DateFormatter.Format(displayDate, "MM/DD/YYYY")}</div>
        )
    };

    return (
        <h5 className='border-bottom border-1 row fs-6 p-3'>
            <div className='col-9'>
                <a target='_blank' href={document.GetDocumentUrl()}>
                    <span className="pe-3" dangerouslySetInnerHTML={{ __html: awesome.Awesome.getDocumentIcon(document.FileName, document.ContentType, null) }} />
                    <span>{document.Name}</span>
                </a>
            </div>
            {getDisplayDate(document)}
        </h5>
    );
};

export default GIQueueDocumentListItem;