import React from 'react';
import SearchFacetMenuSection, { SearchFacet } from './SearchFacetMenuSection';

interface ISearchFacetMenuProps { Facets:Array<SearchFacet>, OnFacetItemClicked:Function, Loading:boolean}
interface ISearchFacetMenuState { animateClass: string, facetList:Array<any> }

export default class SearchFacetMenu extends React.Component<ISearchFacetMenuProps, ISearchFacetMenuState>{
    constructor(props) {
        super(props);
        this.state = { animateClass: "slideOut", facetList: [] };
    }

    public render() {

        return (
            <div className="Facets">
                {
                    this.props.Facets.filter(facet => facet.facetItems && facet.facetItems.length > 0).map((facet, i) => {
                        return (
                            <SearchFacetMenuSection key={i} Facet={facet} OnFacetItemClicked={this.props.OnFacetItemClicked} Loading={this.props.Loading} />
                        )
                    })
                }
            </div>
        )
    }
}