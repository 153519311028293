import React from 'react';
import * as FindResultItemBase from '../Find/FindResultItemBase';
import Moment from "moment";
import * as awesome from '../../awesome';
import * as dateFormatter from '../../dateFormatter';

export class SearchResultItem extends FindResultItemBase.FindResultItem {
}

interface ISearchResultPaneItemProps {
    Result:SearchResultItem;
    Loading: boolean;
}

interface ISearchResultPaneItemState {
}

export default class SearchResultPaneItem extends React.Component<ISearchResultPaneItemProps, ISearchResultPaneItemState>{
    constructor(props){
        super(props);
        this.state = { };
    }

    private getDisplayDate(date) {
        if (!Moment.utc(date, Moment.ISO_8601, true).isValid()) {
            return "";
        }
        var tempDate = Moment.utc(date, Moment.ISO_8601, true);
        return dateFormatter.DateFormatter.Format(tempDate, "MM/DD/YYYY");
    }

    public render() {
        return (
            <div className="infoBox clearfix">
                <div className="info">
                    <div className="infoItem d-flex justify-content-between align-items-center flex-wrap margin-btm-0">

                        <a href={this.props.Result.GetDocumentUrl()} className="text-wrap fs18" rel="noopener noreferrer" target='_blank'>
                            <span className="docnav-icon" dangerouslySetInnerHTML={{ __html: awesome.Awesome.getDocumentIcon(this.props.Result.SearchFileExtension, null, "margin-right-0") }} />
                            <span className="docnav-filename">{this.props.Result.SearchFilename || this.props.Result.SearchTitle}</span>
                        </a>
                        <span className="fs12"> {this.getDisplayDate(this.props.Result.SearchPublishDate)}</span>
                    </div>
                    <div className="inlineArea fs16">
                        <div>{this.props.Result.SearchSummary == '<?xml version="1.0" encoding="UTF-8"?>, <!DOCTYPE pdf2xml SYSTEM "pdf2xml.dtd">, , <pdf2xml producer' ? '' : this.props.Result.SearchSummary && this.props.Result.SearchSummary.indexOf('<a name=1></a>') !== -1 ? '' : this.props.Result.Description || this.props.Result.description || this.props.Result.SearchText || this.props.Result.SearchSummary}
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}