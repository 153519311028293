import * as DocumentsBase from '../Find/DocumentsBase';
import Miso from "../../global";

export class RelatedDocumentItem extends DocumentsBase.DocumentItem {
}

export interface IRelatedDocumentsProps<T extends RelatedDocumentItem> extends DocumentsBase.IDocumentsProps<T> {
    noResultsText?: string;
    includeQueryTerms?: Array<{ termName: string, termValue: string }>;
    includeMultipleQueryTerms?: { termName: string, terms: Array<{string}> };
    excludeQueryTerms?: Array<{ termName: string, termValue: string }>;
    sortQuery?: Array<{ sortBy: string, sortDirection: string }>;
    allowCompressedDownload?: boolean;
}

export interface IRelatedDocumentsState<T extends RelatedDocumentItem> extends DocumentsBase.IDocumentsState<T> {
    query: RelatedDocumentsQuery;
}

export class RelatedDocumentsQuery {
    public partial_fields: {
        source: {
            exclude: Array<string>
        }
    } = {
        source: {
            exclude: ["documentText"]
        }
    };
    script_fields: {
        documentTextExcerpt: {
            params: { field: string, length: number },
            script: string
        }
    } = {
        documentTextExcerpt: {
            params: { field: "documentText", length: 150 },
            script: "ascropped"
        }
    };
    sort: Array<any> =
    [

    ];
    query: {
        filtered: {
            filter: {
                and: Array<any>
            }
        }
    } = {
        filtered: {
            filter: {
                and: []
            }
        }
    };

    public constructor(pageGuid: string = null) {
        if (pageGuid && pageGuid.trim().length > 0) {
            this.query.filtered.filter.and.push({ query: { term: { "RelatedPages": pageGuid } } });
        }
    }

    public includeTerm(termName: string, termValue: string) {
        let queryTerm = { query: { term: {} } };
        queryTerm.query.term[termName] = termValue;

        this.query.filtered.filter.and.push(queryTerm);
    }
    public includeTerms(queryTermName: string, termValues: Array<{ string }>) {
        let queryzTerm = { query: { terms: {} } };
        queryzTerm.query.terms = {
            "minimum_should_match": 1
        };
        queryzTerm.query.terms[queryTermName] = termValues;

        this.query.filtered.filter.and.push(queryzTerm);
    }

    public excludeTerm(termName: string, termValue: string) {
        let queryTerm = { not: { query: { term: {} } } };
        queryTerm.not.query.term[termName] = termValue;

        this.query.filtered.filter.and.push(queryTerm);
    }
    public sortQuery(sortByname: string, sortDirection: string) {
        let querySort = {};
        let sortDirectionObject = {
            "order": sortDirection,
            "ignore_unmapped": true
        };
        var sortByNameWithType = sortByname === "SearchPublishDate" ? `${sortByname}$$date` : (sortByname === "Name" ? `${sortByname}$$string` : sortByname);
        querySort[sortByNameWithType] = sortDirectionObject;
        this.sort.push(querySort);
    }
}

export default abstract class RelatedDocumentsBase<TRelatedDocumentItem extends RelatedDocumentItem, TRelatedDocumentsProps extends IRelatedDocumentsProps<TRelatedDocumentItem>, TRelatedDocumentsState extends IRelatedDocumentsState<TRelatedDocumentItem>> extends DocumentsBase.DocumentsBase<TRelatedDocumentItem, TRelatedDocumentsProps, TRelatedDocumentsState> {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        super.componentDidMount();

        if (!this.state.query) {
            let newquery = new RelatedDocumentsQuery(this.getRelatedPageGuid());
            newquery = this.extendRelatedDocumentsQuery(newquery);

            this.setState({ query: newquery });
        }
    }

    protected getRelatedPageGuid(): string {
        return Miso.PageGuid.CurrentPageGuid;
    }

    protected extendRelatedDocumentsQuery(newquery: RelatedDocumentsQuery): RelatedDocumentsQuery {
        if (this.props.includeQueryTerms && this.props.includeQueryTerms.length) {
            this.props.includeQueryTerms.map((term, i) => {
                newquery.includeTerm(term.termName, term.termValue);
            });
        } 
        if (this.props.includeMultipleQueryTerms) {
            newquery.includeTerms(this.props.includeMultipleQueryTerms.termName, this.props.includeMultipleQueryTerms.terms);
        } 
        if (this.props.excludeQueryTerms && this.props.excludeQueryTerms.length) {
            this.props.excludeQueryTerms.map((term, i) => {
                newquery.excludeTerm(term.termName, term.termValue);
            });
        }
        if (this.props.sortQuery && this.props.sortQuery.length) {
            this.props.sortQuery.map((term, i) => {
                newquery.sortQuery(term.sortBy, term.sortDirection);
            });
        }
        else {
            newquery.sortQuery("Name$$string", "asc");
            newquery.sortQuery("SearchPublishDate$$date", "desc");
        }

        return newquery;
    }
}
