// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import { InfoBox } from "../InfoBox/InfoBox";

interface RecentNotificationItem { subject: string, publishDate: Date, linkURL: string }
interface IRecentNotificationListProps { topics: string, take: number  }
interface State {
    notifications: Array<RecentNotificationItem>,
    loading: boolean
}
export class RecentNotificationList extends React.Component<IRecentNotificationListProps, any>{
    constructor(props) {
        super(props);
        this.state = { notifications: [], loading:true};

    }
    public componentDidMount() {
        this.getNotifications();
    }  

    private buildPayload() {
        var topics = [];
        if (this.props.topics != null || this.props.topics != '') {
            topics = this.props.topics.split(',');
        }
        return JSON.stringify({
            topics: topics,
            take: this.props.take
        }) 
    }

    private getNotifications() {
        return $.ajax({
            url: '/api/topicnotifications/getrecentnotifications',
            type: 'POST',
            dataType: 'json',
            data: this.buildPayload(),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            success: function (data) {
                    
                this.setState({ notifications: data, loading:false });
            }.bind(this)
        });
    }

    public render() {
        if (this.state.loading) {
            return (<div className="processingIndicator margin-top-10"><i className="fa fa-spin fa-refresh"></i></div>);
        }
        return (
            <div className="recentnotificationsblock2">
                <div className="notificationList">
                    {
                        this.state.notifications.map((notification, i) => {
                            return (
                                <InfoBox key={i} titleString={notification.subject} titleLink={notification.permanentLinkUrl} subTitleString={notification.publishDate}/>
                            )
                        }) 
                    }
                </div>
            </div>
        )
    }
}