import { RelatedDocumentItem } from './RelatedDocumentsBase'
import RelatedDocumentsListItem from './RelatedDocumentsListItem'
import ListDateRefiner from '../../hocs/ListDateRefiner';
import List from '../../hocs/List';
import BlockBase, { IBlockBaseProps } from '../../hocs/BlockBase';

interface IRelatedDocumentsListProps extends IBlockBaseProps {
    RelatedDocumentItems: Array<RelatedDocumentItem>;
    disableFollowing:boolean;
    PublishDatePrefixText?: string;
    DateSelections?: Array<Date>;
    allowCompressedDownload: boolean;
    loading?: boolean;
}

export const RelatedDocumentsList = BlockBase<IRelatedDocumentsListProps>(List<IRelatedDocumentsListProps>(RelatedDocumentsListItem, {
    className: "linkList",
    dataSetName: "RelatedDocumentItems",
    dataItemPropName: "RelatedDocumentItem"
}), {
    className: "linkList",
    dataSetName: "RelatedDocumentItems",
    dataItemPropName: "RelatedDocumentItem"
});

export default RelatedDocumentsList;

export const DateRefinedRelatedDocumentsList = BlockBase<IRelatedDocumentsListProps>(ListDateRefiner(List<IRelatedDocumentsListProps>(RelatedDocumentsListItem, {
    className: "linkList",
    dataSetName: "RelatedDocumentItems",
    dataItemPropName: "RelatedDocumentItem"
}), { 
    dataSetName: "RelatedDocumentItems", 
    dateFieldName: "meetingdate",
    selectionLabel: "All meeting dates"
}), {
    className: "linkList",
    dataSetName: "RelatedDocumentItems",
    dataItemPropName: "RelatedDocumentItem"
});
