import React from 'react';
import * as toast from '../../toast';
export interface IRegistrationIconItem {
    target:string,Form: any, Registrations: Array<any>, Status: string, LinkUrl: string, DisableRegistrations: boolean, ChangeRegistration: Function, HideModal: Function, DisplayModal: Function, showModal: boolean
}
//interface EventItemProps { Subject: string, PublishDate: Date  }

interface State {

}
export default class RegistrationIcon extends React.Component<IRegistrationIconItem, any>{
    constructor(props) {
        super(props);
    }
    open = () => {
        this.props.DisplayModal();
    }
    close = () => {
        this.props.HideModal();
    }
    public unregisterButton(submission, displayName) {
        var tempSubmission = submission.toString();
        $.ajax({
            url: "/api/events/Unregister",
            type: 'POST',
            data: JSON.stringify({ formIdentifier: this.props.Form, submission: tempSubmission }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            success: function (data) {
                toast.Toast.Display(displayName + data);
                this.props.ChangeRegistration();
                return data;

            }.bind(this)
        });
    }
    private renderRegistrationIcon() {
        if (this.props.Registrations.length > 0 && this.props.Status == "cancelled") {
            return (<span className="eventIcon pull-right"><i className="cancelled fa fa-user-times" aria-hidden="true"></i></span>);
        } else if (this.props.Registrations.length > 0) {
            return (<button className="btn btn-link padding-0 fs12 eventIcon pull-right unregister green" onClick={() => { this.props.DisplayModal(); }} data-bs-toggle="modal" data-bs-target={"#unRegistrationModal" + this.props.target}><i className="registered fa fa-user padding-right-3" aria-hidden="true"></i> Registered</button>);
        } else if (this.props.Status == "cancelled") {
            return (<a className="eventIcon pull-right"><i className="cancelled fa fa-user-times" aria-hidden="true"></i></a>);
        } else if (this.props.Status == "open") {
            var registerURL = this.props.LinkUrl.replace(/\/+$/, '') + '/register';
            return (<a href={registerURL} className="eventIcon pull-right"><i className="open fa fa-user-plus padding-right-3" aria-hidden="true"></i>Register</a>);
        }
        else {
            return (<a className="eventIcon greyOut pull-right"><i className="opacity fa fa-user-plus" aria-hidden="true"></i> Register</a>);
        }
    }

    public getLinkUrl() {
        if (this.props.LinkUrl && this.props.Status == 'open') {
            var link = this.props.LinkUrl.replace(/\/+$/, '') + '/register';
            return link;
        }
        else {
            return '';
        }
    }

    public render() {
        return (
            <div className="registrationIcon">
                
                {this.props.Registrations.length > 0 || this.props.showModal ?
                    <div className="misoModal modal" id={"unRegistrationModal" + this.props.target} aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Who would you like to unregister from this event?</h5>
                                </div>
                                <div className="modal-body">
                                    <div className="modalBody">
                                        {
                                            this.props.Registrations.length == 0
                                                ? <span> You have no submissions for this event</span>

                                                : this.props.Registrations.map((reg, i) => {
                                                    var displayName = reg.GuestEmail ? reg.GuestEmail : reg.SYSTEMCOLUMN_SubmitUser;
                                                    return (
                                                        <div key={i} className="regItem"><span className="name pull-left">{displayName}</span> <button className='btn btn-success btn-sm pull-right' onClick={() => { this.unregisterButton(reg.id, displayName); }}> Unregister</button></div>
                                                    )
                                                })
                                        }

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn" data-bs-dismiss="modal" onClick={this.close} aria-label="Close">Close</button>
                                </div>
                            </div>
                        </div>
                    </div> : ''}
                {this.renderRegistrationIcon()}
            </div>
            
        )
    }
}
``