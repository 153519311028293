// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import MailingListItem, {IMailingList} from "./MailingListItem";

export interface IListCategory { id: number; type: string; name: string; categoryDescription: string; pageOrderIndex: number; }

interface IMailingListCategoryProps { Category: IListCategory, Index: number, MailingLists: Array<IMailingList>, SubscribeList: (list: IMailingList) => void }

interface State {
    useShowAll: boolean,
    showAll: boolean
}

export default class MailingListCategory extends React.Component<IMailingListCategoryProps, State>{
    constructor(props) {
        super(props);
        this.state = { useShowAll: true, showAll: false };
    }

    public showAllClick() {
        this.setState({ showAll: true });
    }
    public showLessClick() {
        this.setState({ showAll: false });
    }

    public handleTabChange() {
        $('#mailing-list-subscriptions a[href="#availableMailingListsTab"]').tab('show');
    }

    public render(){
        return (
            <div className="row">
                <div className="col-12">                
                    <h4 className="blue">{this.props.Category.name}</h4>
                </div>
                <div className="col-12">
                    <div dangerouslySetInnerHTML={{ __html: this.props.Category.categoryDescription }}></div>

                    <div className="accordion-content row">
                        <div id={`ListCategory${this.props.Category.id.toString()}`} className="panel-group" role="tablist" aria-multiselectable="true">
                            {this.props.MailingLists.length > 0 ?
                                <div className="row">
                                    {
                                        (this.state.useShowAll && !this.state.showAll) ?
                                            this.props.MailingLists.slice(0, 10).map((list, i) => {
                                                return (
                                                    <MailingListItem
                                                        key={list.listName}
                                                        Index={i}
                                                        ID={`ListCategory${this.props.Category.id.toString()}`}
                                                        List={list}
                                                        ListType={this.props.Category.type}
                                                        CategoryDescription={this.props.Category.categoryDescription}
                                                        SubscribeList={this.props.SubscribeList} />
                                                )
                                            })

                                            :
                                            this.props.MailingLists.map((list, i) => {
                                                return (
                                                    <MailingListItem
                                                        key={list.listName}
                                                        Index={i}
                                                        ID={`ListCategory${this.props.Category.id.toString()}`}
                                                        List={list}
                                                        ListType={this.props.Category.type}
                                                        CategoryDescription={this.props.Category.categoryDescription}
                                                        SubscribeList={this.props.SubscribeList} />
                                                )
                                            })
                                    }
                                    {
                                        this.props.MailingLists.length > 10 && (
                                            <div>
                                                <div style={{ textAlign: 'center' }}>
                                                    <button className="btn btn-link btn-small blue" onClick={() => { if (this.state.showAll) { this.showLessClick(); } else { this.showAllClick(); } }}>
                                                        {this.state.showAll ? "Show Less" : "Show More"}
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                : 
                                    <div className="col-12 panel panel-default mailing-list-panel justify-content-between d-block d-sm-flex">
                                        <div className="d-flex align-items-center">
                                            <i className="fa fa-info-circle margin-right-10"></i> You are currently not subscribed to any lists for this section
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <button className="bold btn btn-link" onClick={e => { e.preventDefault(); this.handleTabChange(); }}>View Mailing Lists <i className="fa fa-chevron-right"></i></button>
                                        </div>
                                    </div>

                            }
                            
                        </div>
                    </div>
                <hr />
                </div>
            </div>
        )
    }
}

