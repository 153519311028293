import React from 'react';
import * as dateFormatter from '../dateFormatter';
import Moment from 'moment';



interface IListDateRefinerOptions  {
    dateFieldName:string;
    dataSetName:string;
    selectionLabel:string;
    sortByFieldName?: string;
}

interface IListDateRefinerState  {
    filterYear: number,
    filterDay: string,
    yearList: Array<number>,
    daySelections: Array<Moment.Moment>,
    overridingProps: any,
    filteredResults?: {[key:string]: Array<any>}
} 

export default function ListDateRefiner<TOriginalProps extends {}>(
        WrappedList: (React.ComponentClass<TOriginalProps> | React.StatelessComponent<TOriginalProps>), 
        options:IListDateRefinerOptions) { 
    return class DateRefiner extends React.Component<TOriginalProps & { DateSelections:Array<Moment.Moment> }, IListDateRefinerState> {
        constructor(props) {
            super(props);
            this.state = { filterYear: 0, filterDay: null, yearList: [], daySelections: [], overridingProps:{} };
        }     
        
        public componentDidMount() {
            this.createRefinement();
        }

        public componentDidUpdate(prevProps, prevState) {
            if (this.props[options.dataSetName] != prevProps[options.dataSetName] || this.props.DateSelections != prevProps.DateSelections) {
                this.createRefinement();
            } else if (prevState.filterYear != this.state.filterYear || prevState.filterDay != this.state.filterDay) {
                let filterItems = [];
                
                if (this.state.filterDay) {
                    this.props[options.dataSetName].map((dataItem, i) => {        
                        const dataItemDate = dataItem[options.dateFieldName] ? dateFormatter.DateFormatter.DateCast(dataItem[options.dateFieldName]) : null;                    
                        if (dataItemDate && this.state.filterYear == dataItemDate.year() && (this.state.filterDay == options.selectionLabel || this.state.filterDay == dateFormatter.DateFormatter.Format(dataItemDate, "MM/DD/YYYY"))) {
                            filterItems.push(dataItem);
                        }        
                    })
                }

                let overridingProps = { };
                overridingProps[options.dataSetName] = filterItems;
    
                this.setState( { overridingProps: overridingProps });
            }
        }

        private createRefinement() {
            if (this.props.DateSelections && this.props.DateSelections.length && !(this.state.yearList && this.state.yearList.length)) {
                let tempYearList:Array<number> = [];

                this.props.DateSelections.map((dataItemDate, i) => {
                    if (!tempYearList.some(x => dataItemDate && x === dataItemDate.year())) {
                        tempYearList.push(dataItemDate.year());                    
                    }
                });

                this.setState({ yearList: tempYearList, daySelections: this.props.DateSelections, filterYear: 2017, filterDay: options.selectionLabel });
                
            } else if (this.props[options.dataSetName] && this.props[options.dataSetName].length && !(this.state.yearList && this.state.yearList.length)) {
                const d = Moment.utc();
                const sortByFieldName = options.sortByFieldName || "Name";

                let tempYearList:Array<number> = [];
                let tempDaySelections:Array<Moment.Moment> = [];                

                this.props[options.dataSetName].sort(function (d1, d2) {
                    if (!d1[sortByFieldName] && !d2[sortByFieldName]) {
                        return 0;
                    } else if (!d1[sortByFieldName] && d2[sortByFieldName]) {
                        return 1;
                    } else if (d1[sortByFieldName] && !d2[sortByFieldName]) {
                        return -1;
                    } else if (d1[sortByFieldName] < d2[sortByFieldName]) {
                        return -1;
                    } else if (d1[sortByFieldName] > d2[sortByFieldName]) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                
                this.props[options.dataSetName].map((dataItem, i) => {
                    const dataItemDate = dataItem[options.dateFieldName] ? dateFormatter.DateFormatter.DateCast(dataItem[options.dateFieldName]).startOf('day') : null;

									if (dataItemDate) {
										if (!tempYearList.some(x => dataItemDate && x === dataItemDate.year())) {
											tempYearList.push(dataItemDate.year());
										}

										if (!tempDaySelections.some(x => dataItemDate && dataItemDate.isSame(x, 'day'))) {
											tempDaySelections.push(dataItemDate);
										}
									}

                })

                tempYearList.sort();
                tempDaySelections.sort(function (d1, d2) {
                    if (!d1 && !d2) {
                        return 0;
                    } else if (!d1 && d2) {
                        return 1;
                    } else if (d1 && !d2) {
                        return -1;
                    } else if (d1 < d2) {
                        return -1;
                    } else if (d1 > d2){
                        return 1;
                    } else {
                        return 0;
                    }
                });

                let initDay = tempDaySelections[tempDaySelections.length - 1];
                for (let i = 0; i < tempDaySelections.length; i++) {
                    if (tempDaySelections[i] > d) {
                        initDay = tempDaySelections[i];
                        break;
                    }
                }

                const initYear = initDay.year();
                this.setState({ yearList: tempYearList, daySelections: tempDaySelections, filterYear: initYear, filterDay: dateFormatter.DateFormatter.Format(initDay, "MM/DD/YYYY") });
            }
        }

        public yearFilter(year) {
            this.setState({ filterYear: year, filterDay: null });
        }
        public dayFilter(day) {
            this.setState({ filterDay: day });
        }
        public renderDateSelector() {

            return (<div className="dropdown">
                <button className="btn-secondary form-select w-auto" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {this.state.filterDay ? this.state.filterDay : "- Select Date -" }
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <button className="btn btn-link dropdown-item" onClick={() => { this.dayFilter(options.selectionLabel); }}>{options.selectionLabel}</button>
                    {
                        this.state.daySelections.map((day, i) => {
                            if (day.year() == this.state.filterYear) {
                                const formattedDay = dateFormatter.DateFormatter.Format(day, "MM/DD/YYYY");
                                return (<button key={i} className="btn btn-link dropdown-item" onClick={ () => { this.dayFilter(formattedDay); } }>{formattedDay}</button>)
                            }
                        })
                    }
                </div>
            </div>);
        }

        public render() {
            //  1/3/18: Because this block is typically nested inside an accordian, it does not render an accordain
            // at mobile viewports like other tabbed components 
            return (
                <div className="relateddocumentsblock">
                    <div className="eventBlock">
                        <div className="tab-content-block">
                            <ul className="nav nav-tabs">
                                {
                                    this.state.yearList.map((year, i) => {
                                        return (<li key={i} className="nav-item"><a className={year == this.state.filterYear ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href={'#LDR' + year } onClick={ () => { this.yearFilter(year); } }>{year}</a></li>)
    
                                    })
                                }  
                            </ul>
                        </div>
                        <div className="padding10">
                            <div className='margin-top-10 linkList margin-btm-10'>{this.renderDateSelector() }</div>
                            <WrappedList  {...this.props} {...this.state.overridingProps} />
                        </div>
                    </div>
                </div>
    
            )
    
        }
    }
}
