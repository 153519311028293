// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';

interface ICallToActionSideBarProps {
    primaryText: string;
    secondaryText?: string;
    image?: string;
    imageString?: string;
    imageString2?: string;
    ctaClass?: string;
    buttonLinks?: Array<epiLinks>;
    alignRightButtons: boolean;
    secondaryTextDangerous?: boolean;
}
export interface epiLinks {
    subHeader?: string;
    text?: string;
    url?: any;
    icon?: string;
    openInNewTab: boolean;
}

interface State {
}

export class CallToActionSideBar extends React.Component<ICallToActionSideBarProps, State>{
    constructor(props) {
        super(props);
        this.state = {};
    }

    public render() {
        //console.log(this.props);
        return (
            <div className={"ctaSideBar cta-" + (this.props.ctaClass ? this.props.ctaClass : "cta-blue") + (this.props.alignRightButtons ? " alignRightButtons" : "")}>
                {this.props.imageString ? <div className="ctaImageArea"><img data-epi-edit="ImageString" className="ctaImage" src={this.props.imageString} /></div> : ""}
                <div className="cta-body">
                    <div className="ctaTextDiv">
                        <h3 data-epi-edit="PrimaryText">{this.props.primaryText}</h3>
                        {(this.props.secondaryText ?
                            this.props.secondaryTextDangerous ? <h4 data-epi-edit="SecondaryText" className="ctaSubtext" dangerouslySetInnerHTML={{__html: this.props.secondaryText}}></h4>
                                : <h4 data-epi-edit="SecondaryText" className="ctaSubtext">{this.props.secondaryText}</h4> : "")}
                    </div>

                    <div data-epi-edit="ButtonLinks" className="ctaButtonDiv">
                        {this.props.buttonLinks ? this.props.buttonLinks.map(function (item, index) {
                            return (
                                <a key={index} href={item.url["uri"] ? item.url["uri"] : item.url} target={item.openInNewTab ? "_blank" : "_self"} className={"btn " + (index == 0 ? "btn-primary" : "btn-default")}><span>{item.text}</span> {item.icon ? <i className={"ctaSidebarButtonIcon " + item.icon}></i> : ""}</a>                           
                                )
                            
                        }) : ""}
                    </div>
                </div>
            </div>
        )
    }
}