// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';

export class TwitterFeed extends React.Component<any, any>{
    constructor(props) {
        super(props);
    }

    public render() {
        return (
            <div className="twitterBlock">
                {/*<h2 className="blue-bg">Latest Tweets</h2>*/}
                    <div className="tweetArea">
                        <div>
                            <a className="twitter-timeline" href="https://twitter.com/MISO_energy?ref_src=twsrc%5Etfw">Tweets by @MISO_energy</a>
                            {/*TODO: This should probably be downloaded, but we need to verify our X account first, so I'm not going to figure out how to link it just yet*/}
                            <script async src="https://platform.twitter.com/widgets.js"></script>
                        </div>
                    </div>
            </div>
            
        )
    }
}