import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';

export class DateFormatter {

	public static Format(date: moment.MomentInput, format: string = "M/D/YYYY") {
		if (date === "undefined" || date === undefined || date === "Invalid Date" || date === "") {
			return "Never";
		}
        return DateFormatter.DateCast(date).format(format);
	}

	public static DateCast(date: moment.MomentInput): moment.Moment {
		return momentTimezone.tz(date, "America/New_York");
	}

}