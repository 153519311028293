import React from 'react';
import { MeetingList } from "./MeetingList";
import {RelatedMeetingMaterialDocuments} from "../RelatedDocuments/RelatedMeetingMaterialDocuments"

interface ICommittee {
    name:string,
    id:string,
    committeeAcronym:string,
    contentGuid:string
}

class CommitteePanelSetting {
    Committee:ICommittee;
    PanelTitle:string;
    Loaded:Boolean = false;
}

interface ISubcommitteeMeetingsProps { 
    parentCommittee:ICommittee;
    subcommittees:Array<ICommittee>
}

interface ISubcommitteeMeetingsState {
    committeePanelSettings:Array<CommitteePanelSetting>;
}

export class SubcommitteeMeetings extends React.Component<ISubcommitteeMeetingsProps, ISubcommitteeMeetingsState>{
    constructor(props) {
        super(props);
        this.state = { committeePanelSettings: [] };
    }

    public componentDidMount() {
        var committees = [];

        committees.push({
            Committee: this.props.parentCommittee,
            PanelTitle: this.props.parentCommittee.name + "(" + this.props.parentCommittee.committeeAcronym  + ")"
        });

        this.props.subcommittees.map((subcommittee) => {
            committees.push({
                Committee: subcommittee,
                PanelTitle: subcommittee.name + "(" + subcommittee.committeeAcronym  + ")"
            });
        })

        this.setState({committeePanelSettings: committees});
    }

    public render() {
        return (
            <div className="accordion-content">
                <div id="subcommitteeMeetingsGroup" className="panel-group" role="tablist" aria-multiselectable="true">
                {
                    this.state.committeePanelSettings.map((committeePanel, i) => {
                        return (
                            <div className="panel panel-default" key={i} onClick={ () => { if (!committeePanel.Loaded) { committeePanel.Loaded = true; this.setState( { committeePanelSettings: this.state.committeePanelSettings }); }  }  }>
                                <div className="panel-heading" role="tab" id={"subcommitteeMeetingsHeader" + i}>
                                    
                                    <div className="leftside">
                                        <a data-bs-toggle="collapse" href={"#subcommitteeMeetingsCollapse" + i} data-parent="#subcommitteeMeetings" role="button" aria-expanded="false" aria-controls={"subcommitteeMeetingsCollapse" + i} className="accordion-link collapsed blue bold">
                                           {committeePanel.PanelTitle}
                                        </a>
                                    </div>
                                    <div className="rightside">
                                        <a data-bs-toggle="collapse" href={"#subcommitteeMeetingsCollapse" + i} data-parent="#subcommitteeMeetings" role="button" aria-expanded="false" aria-controls={"subcommitteeMeetingsCollapse" + i} className="accordion-link collapsed">
                                            <i className="fa pull-right"></i>
                                        </a>
                                    </div>
                                    
                                </div>
                                <div id={"subcommitteeMeetingsCollapse" + i} className="panel-collapse collapse"
                                    role="tabpanel" aria-labelledby={"subcommitteeMeetingsHeading" + i} aria-expanded="false">
                                    <div className="panel-body">
                                        <div className="margin-btm-20 content">
                                            {committeePanel.Loaded && <MeetingList integrateHeader={true} showIntegratedBorder={true} integrateHeaderString={"Meeting List"} integrateSubHeaderString={ "All Meeting times are displayed in Eastern Time"} disableRegistrations={false} committeeReference={committeePanel.Committee.id} />}
                                        </div>
                                        <div className="margin-btm-20 content">
                                            {committeePanel.Loaded && <RelatedMeetingMaterialDocuments integrateHeader={true} showIntegratedBorder={true} integrateHeaderString={"Meeting Materials"} committeePageGuid={committeePanel.Committee.contentGuid} findServiceUrl="/api/find/Optics_Models_Find_RemoteHostedContentItem" allowCompressedDownload={true}  /> }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        );
    }
}