import React from 'react';
import { GIQueueItem } from './GIQueueDocumentApp';
import GIQueueDocumentList from './GIQueueDocumentList';

interface GIQueueDocumentModalProps extends React.Props<GIQueueDocumentModal> {
    documents: GIQueueItem[];
    showModal: boolean;
    loading: boolean;
    projectNumber: string;
}

interface GIQueueDocumentModalState {
    showModal: boolean;
}

class GIQueueDocumentModal extends React.Component<GIQueueDocumentModalProps, GIQueueDocumentModalState> {

    constructor(props: GIQueueDocumentModalProps) {
        super(props);
        this.state = {
            showModal: props.showModal
        }
    }

    open = () => {
        this.setState({
            showModal: true
        });
    }

    close = () => {
        this.setState({
            showModal: false
        });
    }

    render() {
        return (
            <div>
                <div className={`modal fade ${this.state.showModal ? "show" : ""}`} id={`GIQueueModal_${this.props.projectNumber}`} role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Documents for Project: {this.props.projectNumber}</h4>
                                <button type="button" className="btn-close" id="closeModal" onClick={this.close} aria-label="Close"></button>
                            </div>
                            <div className="modal-body message row">
                                <div className="col-12">
                                    {this.props.loading == true ? <i className="fa fa-spin fa-refresh"></i> :
                                        <GIQueueDocumentList documents={this.props.documents} />}
                                </div>
                            </div>
                            <div className="modal-footer"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default GIQueueDocumentModal;
