import {IDocumentNavigationItem} from '../DocumentNavigator/DocumentNavigatorItem';

export function GenerateMarketReportNavigationItem(
        parentPropertyValue:string, 
        title:string,
        summary:string,
        childPropertySystemName:string
    ):IDocumentNavigationItem {
        return {
            parentPropertyValue: parentPropertyValue,
            title: title,
            summary: summary,
            childPropertySystemName: childPropertySystemName,
            childDocumentNavigationItems: [],
            displayResultInGrid: true,
            resultSortField: "MarketReportPublished",
            resultSortDirection: "desc",
            gridMetadataColumns: [{ propertyName: "FileName", title: "File" }, { propertyName: "MarketReportPublished", title: "Published", alignment: "end" }],
            tableStyle: "none",
            useDynamicChoiceMode: "DynamicChoices",
            dynamicChoiceSortDirection: "asc",
            useDisplayTitle: false,
            useDynamicYearNavigation: false,
            useDynamicQuarterNavigation: false,
            useDynamicMonthNavigation: false,
            useDynamicDayNavigation: false,
            loadedNavigableDocuments: null,
            totalAvailableResults:null
        };
}

export function GenerateMarketReportTopLevelNavigationItem():IDocumentNavigationItem {
    let navItem = GenerateMarketReportNavigationItem(null, "Market Reports", null, "MarketReportType");
    navItem.childDocumentNavigationItems.push(
        GenerateMarketReportNavigationItem(null, null, null, "MarketReportName")
    );

    return navItem;
}